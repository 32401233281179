.cp-block{
	margin-top: vSize(2);
	&:first-of-type {
		margin-top:0;
	}
	.g-button.alt{
		display: inline-block;
		width: auto;
		padding-top: vSize(5);
		padding-left: 0;
		>span {
			margin-left: 0;
		}
	}
	h1{
		margin-bottom: vSize(2);
	}
	p{
		margin-bottom:vSize(1);
	}

	&.open{
		form {
			display: block;
		}
	}

	.collapsible {
		display: block;
		padding-top: vSize(3);
	}

	a{
		text-decoration:underline;
	}

}