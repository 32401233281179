@import "vars";
@import "helpers/fbGrid";
@import "helpers/fbHelper";

@include fbGrid($gutterWidth,$columnWidth,$numberColumns,g,$breakpoints);
@include fbHelper($breakpoints);




body,html {
	height:100%;
	width:100%;
	//overflow: hidden;

}


.scrollable{
	//overflow-y: scroll;
	//-webkit-overflow-scrolling: touch;
	//position: absolute;
	//top:95px;
	//bottom: 0;
	//left:0;
	//right: 0;
	margin-top: 94px;
	backface-visibility: visible;
	background-color: #eaeae3;
	transition: padding-top 0.5s ease-out;


	&.search-open{
		@include transform(translateY(190px));
	}
}

main {
	min-height: 50vh;
	@extend .g-bg-white-two;
	max-width: 1300px;
	margin: 0 auto;
	width: 100%;
	position: relative;
	//background-color: color(yellow);
}
// section paddings !
.g-p-1 {
	padding-top: vSize(15);
	//padding-bottom: vSize(5);
}

.g-pb-9 {
	padding-bottom: vSize(9);
}
.g-pt-9 {
	padding-top: vSize(9);
}
.g-pb-12{
	padding-bottom:vSize(12);
}
.g-pb-15 {
	padding-bottom: vSize(15);
}
.g-pt-15 {
	padding-top: vSize(15);
}
.g-pb-7 {
	padding-bottom: vSize(7);
}
.g-pt-7 {
	padding-top: vSize(7);
}
.g-pb-6 {
	padding-bottom: vSize(6);
}
.g-pt-6 {
	padding-top: vSize(6);
}

.g-pb-5 {
	padding-bottom: vSize(5);
}
.g-pt-5 {
	padding-top: vSize(5);
}
.g-pb-4 {
	padding-bottom: vSize(4);
}
.g-pt-4 {
	padding-top: vSize(4);
}

.g-pt-7 {
	padding-top: vSize(7);

}
.g-pt-8 {
	padding-top: vSize(8);
}

.g-mb-15 {
	margin-bottom: vSize(15);
}
.g-mt-7 {
	margin-top: vSize(7);

}
.g-mt-15 {
	margin-top: vSize(15);
}

.g-mt-3 {
	margin-top: vSize(3);
}

.g-mt-4 {
	margin-top: vSize(4);
}


.g-pt-16 {
	padding-top: vSize(16);
}
.g-pb-16 {
	padding-bottom: vSize(16);
}

.g-pt-17 {
	padding-top: vSize(17);
}
.g-pb-17 {
	padding-bottom: vSize(17);
}

.g-pt-20 {
	padding-top: vSize(20);
}
.g-pb-20 {
	padding-bottom: vSize(20);
}
.g-pb-28 {
	padding-bottom: vSize(28);
}

.g-m-1 {
	margin-top: vSize(15);
	//padding-bottom: vSize(5);
}

.g-no-pt {
	padding-top: 0!important;
}

// should no tbe here
.load {
	opacity: 0;
	@include fbTransition(opacity,0.5s);
}
.lazyloaded {
	opacity: 1;
}
/*
.isotope {
	visibility: hidden;
	opacity: 0;
	@include fbTransition(opacity,0.8s);
}

.isotope[style] {
	visibility: visible;
	opacity: 1;
}*/

