@import "../helpers/fonts";


.g-button, button {
	line-height: 40px;
	background-color: color(black);
	display: block;
	border:none;
	width:100%;
	text-align: left;
	text-decoration: none;
	color:color(white);

	font-size: 14px;
	padding: 0 0 0 15px;

	position: relative;

	span {

	}
	&.alt {
		@extend .t-links;
		color: color(black);
		line-height: 45px;
		background-color: transparent;
		vertical-align: middle;

		.circle {
			margin-left: 20px;
			vertical-align: middle;
			border-color: color(yellow);
		}

		&.left {
			padding-left: 0;
			.circle {
				margin-left: 0;
				margin-right: 20px;
			}
		}

		&.white {
			.circle {
				border-color: color(white);
			}
		}

	}

	.arrow {
		position: absolute;
		right: 25px;
		top:50%;
		display: block;
		height:2px;
		width:20px;
		background-color: color(white);
		@include fbTransition(transform,0.2s);
		&:after {
			position: absolute;
			content:" ";
			right:0px;
			top:-2px;
			width:6px;
			height:6px;
			display: block;
			border-bottom: 1px solid color(white);
			border-right: 1px solid color(white);
			@include transform(rotate(-45deg));
		}
	}
	&:hover,&.active {
		.arrow {
			@include transform(translateX(10px));
			background-color: color(yellow);
			&:after {
				border-color: color(yellow);
			}
		}
	}

	&.circle,.circle {
		position: relative;
		display: inline-block;
		border: 2px solid color(white,three);
		width:45px;
		line-height: 45px;
		height:45px;
		background-color: transparent;
		border-radius: 500px;
		.arrow {
			@include absolutelyCentred();
			background-color: color(black);
			&:after {
				border-color: color(black);
			}
		}
		.arrow-down {
			@include transform(rotate(90deg));
			top: 49%;
			left: 26%;
		}
		.arrow-left {
			@include transform(rotate(180deg));
			top: 49%;
			left: 26%;
		}
		&:hover,&.active {
			border-color: color(yellow);
		}

		.plus {
			width: 11px;
			height:11px;
			position: absolute;
			top:15px;
			left:15px;
			@include fbTransition(transform,0.2s);
			&:before {
				position: absolute;
				content:" ";
				width:11px;
				height:1px;
				display: block;
				top:5px;
				left:0;
				background-color: color(black);
			}
			&:after {
				position: absolute;
				content:" ";
				width:1px;
				height:11px;
				display: block;
				top:0;
				left:5px;
				background-color: color(black);
			}
		}

		&.reload {
			span {
				position: absolute;
				width: 100%;
				height: 100%;
				left: -4px;
				top: -4px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUwcHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDUwIDUwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MCA1MCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMkIyQjJCIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yNSwxMi4zNDNjNi45NSwwLDEyLjU4NCw1LjYzNCwxMi41ODQsMTIuNTg0UzMxLjk1LDM3LjUxLDI1LDM3LjUxIiAvPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzJCMkIyQiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNMjUsMTIuMzQzYy02Ljk1LDAtMTIuNTg0LDUuNjM0LTEyLjU4NCwxMi41ODRjMCwyLjcyNSwwLjg2Niw1LjI0NywyLjMzOCw3LjMwN2wyLjI4MSwyLjI4MSIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiMyQjJCMkIiIHN0cm9rZS1saW5lam9pbj0iYmV2ZWwiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgcG9pbnRzPSIxNy4yODcsMzAuMjA0IDE3LjI4NywzNC42NjkgMTIuODIyLDM0LjY2OSAiLz48L2c+PC9zdmc+);
			}
		}

		&.tooltip {
			background-color: color(yellow);
			border-color: color(yellow);
			&.grey {
				background-color: color(white);
				border-color: color(white,six);
			}

			&:active,&.active {
				.plus{
					@include transform(rotate(-45deg));
				}
			}
		}
	}
	&.active {
		.circle {
			.plus{
				@include transform(rotate(-45deg));
			}
		}
	}


	span.icon{
		@include absolutelyCentred(true, true);
	}
}