footer {
	max-width: 1300px;
	margin: 0 auto;
	background-color: color(yellow);
	.wrapp {
		//padding-top: vSize(14);
		//padding-bottom: vSize(12);
		>.gl-container {
			display: flex;
			[class ^= "icon-"] {
				margin-bottom: vSize(4);
				height:48px;
			}
			div.gc-4 {
				display: flex;
				article {
					width:100%;
					position: relative;
					a {
						margin-bottom: $gutterWidth;
						position: absolute;
						bottom: 0;
					}
				}
			}
		}
		form {
			.check {
				label:before {
					background-color: color(yellow,two);
				}
			}
		}

		.sub {
			ul li {
				display: inline-block;
				margin-right: $gutterWidth;

			}

		}
		.pb {
			padding-bottom: 8px;
		}
	}
	.t-right{
		span{
			display:inline-block;
			vertical-align: top;
		}
	}
	.legrand{
		@include img-svgs-legrand-logo-02;
	}
	.v-line{
		width:1px;
		border-right:1px solid color(black, one);
		height:17px;
		margin:2px 16px 0px;
	}





}