

.cp-top-section {
	overflow: hidden;
	position: relative;
	padding-bottom: vSize(11);
	padding-top: calc(13% - 42px);


	&.detail {
		padding-top: 9%;
		min-height: 750px;
	}

	.full {
		height:100%;
		position: absolute;
		top: 0;
		width: 100%;
		>div {
			position: absolute;
			top: 0;
		}
	}

	&.home {
		max-height: 665px;
		height:85vh;
		.text {

		}
		.cp-captions {
			background-color: transparent;
			width:43%;
			.dots {
				width:100%;
				padding-left: 21px;
				float:left;
				text-align: left;
				a {
					text-align: left;
				}
			}

		}
	}

	.full2 {
		height:100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 0;
		background-color: color(white,four);

	}
	.g-bg-white-four {
		width:47%;
		bottom: 0;
	}
	.image {
		left:40%;
		width: 60%;
		top:auto!important;
		bottom: 0!important;
		overflow:hidden;
		img{
			display: block;
			max-width: none;
			max-height: 100%;
			height:auto;
			width: 100%;
		}
	}

	.image.image-gallery {
		height: 100%;
		width: 60%;
		margin-left: 40%;
		left:0;
		background-color: color(white,three);
		img{
			width: auto;
		}
		.swiper-wrapper{
			height: 100%;
			width:calc(100% + 2px);
			left:-1px;
		}
		.swiper-slide {
			position: absolute;
			opacity: 0;
			&:first-of-type{
				opacity: 1;
			}
			top:0;
			left:0;
			bottom: 0;
			right:0;
			background-size: cover;
			background-position: center;
			overflow: hidden; // that makes a difference in performance
		}
	}


	a {
		margin-top: vSize(6);
	}
	.text {
		position: relative;
		z-index: 1;
		pointer-events: none;
		>*{
			pointer-events: auto;
		}
	}
	.download {
		a {
			display: block;
			text-decoration: none;;
			margin-top: 0;
			span{
				text-decoration: underline;
			}
		}
		a.print{
			margin-top: 16px;
		}
	}
	.specs {
		@extend .g-pb-7;
	}

	.t-container {
		position: relative;
		@extend .gl-container;
		.t-slide{
			position: absolute;
			opacity:0;
			&:first-of-type {
				opacity: 1;
			}
		}
	}

	.buttons{
		.g-button{
			display:inline-block;
			width:auto;
			&:not(:last-child){
				margin-right:vSize(8);
			}
		}
	}
}

