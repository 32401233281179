.order-tracker{
	@include display(flex);
	@include flex-wrap(wrap);
	> div{
		float:none;
		flex-grow:0;
		margin-bottom:vSize(2);
	}
}

.order-tracker-line-item{
	background-color: color(white, one);
	height:100%;
	padding:42px 64px 32px 85px;
	@include display(flex);
	@include flex-direction(column);


	.order-tracker-line-item-top{
		@include flex-grow(1);

		h2 sup{
			width:11px;
			height:11px;
			border-radius:11px;
			line-height:11px;
			display:inline-block;
			margin-left:vSize(1);
			&.success{
				background-color: #00ea84;
			}
			&.error{
				background-color: #ff004e;
			}
			&.warning{
				background-color: #ffe000;
			}
		}
	}

	.order-tracker-line-item-middle{
		@include flex-shrink(0);
		p{
			margin-bottom:0px;
		}
		strong{
			width:90px;
			display:inline-block;
		}
	}
	.order-tracker-line-item-bottom{
		margin-top:auto;


	}

}