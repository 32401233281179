.cp-top-section{
	  .image,.image-gallery {

		  left:0;
		  img{
			  width: 100%;
			  max-height: none;
			  max-width: none;
		  }
		  width: 100%!important;
		  margin-left: 0!important;
	  }
	  padding-top: vSize(0);
	  .full{
		 // z-index:0;
		  position:relative;
		  padding-bottom:fbRatio(660,607 + 45);
		  height:auto;
	  }
	  /*.full:before{
		  position: absolute;
		  z-index: 3;
		  background-color: color(white,four);
		  width:100%;
		  height:100%;
		  top:0;
		  left:0;
		  content:'';

	  }*/
	.image{
		//display:none;
	}

	&.home{
		background-color:#f7f6f1;
		height:auto;
		max-height:initial;
		padding-bottom:0px;
		//height:100vh;
		.full2{
			position:relative;
			height:auto;
			padding-bottom:fbRatio(660,445);
			div.image{
				position:absolute;

			}
		}
		.t-container{
			padding:vSize(3) 0px vSize(4);
			.gl-container{
				margin-left:0px !important;
				margin-right:0px !important;
				padding:0px;
			}
			.t-slide:first-child{
				position:relative;
			}
		}
	}
	a{
		margin-top:24px;
	}
	padding-bottom:vSize(4.5);
	min-height:initial;
	&.detail{
		min-height:initial;
		background-color:#f9f8f3;
	}
  }