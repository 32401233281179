.p-product main {
	background-color:#ebebe4;
	article>.full {
		position: relative;
		//margin-bottom: vSize(5);
	}
	.cp-top-section.detail {
		padding-top: 0;
	}
	.cp-top-section .full > div {
		//position: relative;
	}
	.cp-top-section .full{
		margin-bottom: vSize(4);
	}
	.cp-top-section .gallery {
		left:0;
		width: 100%;
		//padding-bottom: calc(100% - 0px);
		height:100%;
	}

	.cp-top-section .gallery .item {
		//position: absolute;
	}

	.cp-top-section .download a.bp2-hidden{
		display:none;
	}

	.variations{

	}
	section.g-bg-white-two{
		background-color:#ebebe4;
	}

	.specifications ul:before{
		top:0;
		bottom:0;
	}

	.cp-top-section .bar{
		bottom:45px;
	}
}
