.icon-info span {

	@include img-svgs-footer-ifo;
}
.icon-glass span {
	@include img-svgs-footer-search;
}
.icon-chat span {
	@include img-svgs-footer-livechat;
}
.icon-faq span {
	@include img-svgs-helpcentre-faq;
}
.icon-manual span {
	@include img-svgs-helpcentre-productmanuals;
}
.icon-stockist span {
	@include img-svgs-helpcentre-findstockist;
}
.icon-contact span {
	@include img-svgs-helpcentre-contactus;
}
.icon-trade span {
	@include img-svgs-helpcentre-tradecatalogue;
}

.icon-map-marker span{
	@include img-svgs-circle-map-pin;
}
.icon-calculator span{
	@include img-svgs-calc;
}
.icon-calculator-large span{
	@include img-svgs-helpcentre-calculator;
}

.icon-download-arrow{
	@include img-svgs-download-arrow;
}