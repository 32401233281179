.cp-top-section{
	min-height:665px;
}

.cp-top-section-fixed{
	position:relative;
	min-height:654px;
	> .gl-container{
		@include absolutelyCentred(true, true);

	}
}

.cp-top-section-map{
	height:960px;
}