header {
	 .bar nav a {
		 padding-left: 2vw;
		 padding-right: 2vw;
		 &:after{
			left: 2vw;
			right: 2vw;
		 }
	 }
}
