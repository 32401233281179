.cp-product-gallery {
	position: relative;
	padding: 0;
	.gc-3,.gc-4 {
		.wrapp {
			margin:0 8px;
		}
	}
	.swiper-wrapper {
		padding: 0 8px;
		position: relative;
	}
	img{
		width: 100%;
		height: auto;
	}
}
