/*
$flex: true;
$global-width: rem-calc(1340);

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  tiny: 20px,
  medium: 30px,
);
$column-gutter: 30px;

$breakpoints: (
  tiny: 0,
  small: 376px,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1600px,
  xxxlarge: 1920px,
);
$breakpoint-classes: (tiny small medium large xlarge xxlarge xxxlarge);

// ---- Fonts ---- //
$font-family-sans-serif: 'Open Sans', Arial, sans-serif;
$font-family-serif: Georgia, Cambria, 'Times New Roman', Times, serif;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$font-family-icon: 'Fontello';

$body-font-family: $font-family-sans-serif;

$global-weight-normal: normal;
$global-weight-bold: bold;

$body-bg: $white;
$body-font-color: #333;
$body-font-family: $font-family-sans-serif;
$body-font-weight: $global-weight-normal;
$body-font-style: normal;

// ---- Colours ---- //

// We use these as default colors throughout
$primary-color: #b1a47a;
$secondary-color: #3d3f48;
$tertiary-color: #6B9392;

$alert-color: #e74c3c;
$warning-color: #f1c40f;
$success-color: #7bc143;
$info-color: #e1e3e5;
$alternate-color: #b4d5f0;
$shade-color: #f0f1f2;

$global-color-palette: (
  primary:   $primary-color,
  secondary: $secondary-color,
  tertiary:  $tertiary-color,
  alert:     $alert-color,
  warning:   $warning-color,
  success:   $success-color,
  info:      $info-color,
  alternate: $alternate-color,
  light:     #FFFFFF,
  dark:      #000000
);

$foundation-palette: $global-color-palette;

$social-color-palette: (
  facebook:   #3b5998,
  twitter:    #00aced,
  gplus:      #dd4b39,
  youtube:    #bb0000,
  linkedin:   #007bb6,
  instagram:  #517fa4,
  pinterest:  #cb2027,
  tumblr:     #32506d,
  flikr:      #ff0084,
  vine:       #00bf8f
);

// ---- Header ---- //

$header-font-family: $font-family-sans-serif;
$header-font-weight: 600;
$header-lineheight: 1.2;
$header-sizes: (
  tiny: (
    'h1': 36,
    'h2': 28,
    'h3': 24,
    'h4': 20,
    'h5': 18,
    'h6': 16,
  ),
  small: (
    'h1': 45,
    'h2': 36,
    'h3': 26,
    'h4': 22,
    'h5': 18,
    'h6': 16,
  ),
  medium: (
    'h1': 60,
    'h2': 48,
    'h3': 34,
    'h4': 30,
    'h5': 26,
    'h6': 20,
  ),
);

// ---- Buttons ---- //

$button-font-family: $font-family-sans-serif;
$button-padding: 1.3em 2.5em;
$button-margin: 0;
$button-color-palette: map-merge($global-color-palette, $social-color-palette);
$button-sizes: (
  nano:    0.55rem,
  tiny:    0.7rem,
  small:   0.842rem,
  default: 1rem,
  large:   1.25rem,
);

// ---- Forms ---- //

$form-spacing: rem-calc(23.34);
$select-triangle-color: $secondary-color;

// ---- Breadcrumbs ---- //

$breadcrumbs-item-font-size: rem-calc(16);
$breadcrumbs-item-color: $primary-color;
$breadcrumbs-item-color-current: $primary-color;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-slash: true;

// ---- Table ---- //

$table-border: 0;
$table-padding: rem-calc(15);
$table-striped-background: $shade-color;

// ---- Tabs ---- //

$tab-background-active: $secondary-color;
$tab-item-padding: rem-calc(16) rem-calc(50);

// ---- Accordion ---- //

$accordion-background: transparent;

// ---- Menu ---- //

$menu-link-font-size: rem-calc(18);
$menu-link-color: $white;
$menu-link-sticky-color: $body-font-color;
$menu-link-padding: 0 ($column-gutter/2);
$menu-logo-width: 80px;
$menu-logo-margin: 0 0 0 0;
$menu-mega: true;
$menu-sub-menu-background: $primary-color;
$menu-sub-menu-link-color: $white;
$menu-sub-menu-link-font-size: rem-calc(18);

$mobile-menu-link-color: $white;
$mobile-menu-link-font-size: rem-calc(22);
$mobile-menu-button-color: $white;
$mobile-menu-child-icon: $icon-play-circled;
$mobile-menu-background: $primary-color;


////////////////
// Base
////////////////

@import "base/normalize";
@import "base/spacing";
@import "base/alignment";
@import "base/easing";
@import "base/animation";
@import "base/wordpress";
@import "base/contact-form-7";
@import "base/text-colors";
@import "base/svg-url";
@import "base/tde";

////////////////
// Foundation
////////////////

@import 'vendor/foundation/foundation';

@if $flex {
  $global-flexbox: true !global;
}

@include foundation-global-styles;

@if not $flex {
  @include foundation-grid;
} @else {
  @include foundation-flex-grid;
}

@include foundation-typography;
@include foundation-forms;
@include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
@include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
@include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

@if $flex {
  @include foundation-flex-classes;
}

// Workaround for inconsistent form sizing
text-inputs(),
select,
textarea,
.input-group {
  margin-bottom: rem-calc(20);

  &.input-group-field {
    margin: 0;
  }
}

////////////////
// Vendor
////////////////

@import "vendor/full-page";
@import "vendor/owl-carousel";
@import "vendor/slick";
@import "vendor/lightcase";
@import "vendor/animated/animated";

// Imported Animations
@include animation(fade-in-down);
@include animation(fade-in);
@include animation(fade-out-up);
@include animation(bounce-in);
@include animation(bounce-out);

////////////////
// Elements
////////////////

@import "elements/ul-ol";
@import "elements/headings";
@import "elements/tables";
@import "elements/blockquote";

////////////////
// Components
////////////////

@import "components/buttons";
@import "components/backdrop";
@import "components/forms/fieldset";
@import "components/forms/radio";
@import "components/forms/checkbox";
@import "components/forms/date";
@import "components/forms/ninja/form";
@import "components/card";
@import "components/tile";
@import "components/hero";
@import "components/hero-internal";
@import "components/hero-carousel";
@import "components/share-page";
@import "components/content-sidebar";
@import "components/product-cards";
@import "components/masonry-grid";
@import "components/solutions";
@import "components/footer";
@import "components/pagination";
@import "components/testimonials";
@import "components/overlay";
@import "components/loader";
@import "components/single-navigation";
@import "components/sidebar-block";
@import "components/sidebar-block-list";
@import "components/sidebar-block-search";
@import "components/filtered-listing";

////////////////
// Navigation
////////////////

@import "menus/standard";
@import "menus/minimal";
@import "menus/shared/menu-list";
@import "menus/shared/menu-list-icons";
@import "menus/shared/menu-list-search";
@import "menus/shared/mega-menu";
@import "menus/shared/fullscreen-menu";
@import "menus/helpers";
// @import "components/menus";
// @import "components/desktop-menu";
// @import "components/mobile-menu";

////////////////
// WooCommerce
////////////////

@import "woocommerce/woocommerce";

////////////////
// Regions
////////////////

@import "regions/breadcrumbs";
@import "regions/featured-posts";
@import "regions/page-content";
@import "regions/services-tiles";
@import "regions/slider-block";
@import "regions/gallery-grid";
@import "regions/intro-text";
@import "regions/team-listing";

@import "regions/content-blocks/base";
@import "regions/content-blocks/accordion";
@import "regions/content-blocks/text";
@import "regions/content-blocks/card";
@import "regions/content-blocks/listing";
@import "regions/content-blocks/full-backdrop";
@import "regions/content-blocks/showcase";
@import "regions/content-blocks/basic-call-to-action";
@import "regions/content-blocks/map";
@import "regions/content-blocks/supporters-carousel";
@import "regions/content-blocks/testimonials-carousel";
@import "regions/content-blocks/featured-articles";
@import "regions/content-blocks/smart-grid";


////////////////
// Layouts
////////////////

@import "layouts/template-home";
@import "layouts/template-archive";
@import "layouts/template-contact";
@import "layouts/template-gallery";

////////////////
// Hacks
////////////////

@import "base/hacks";
*/
@import url("//hello.myfonts.net/count/2f00d8");
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, .cp-label.v2 span, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary {
  display: block; }

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object,
embed {
  max-width: 100%; }

/*
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
	In fact, it *will* cause problems with Google Maps' controls at small size.
	If this is the case for you, try uncommenting the following:
#map img {
		max-width: none;
}
*/
/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.
	you'll have to restore the bullets within content,
	which is fine because they're probably customized anyway */
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

th {
  font-weight: bold;
  vertical-align: bottom; }

td {
  font-weight: normal;
  vertical-align: top; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */ }

input[type="radio"] {
  vertical-align: text-bottom; }

input[type="checkbox"] {
  vertical-align: middle; }

select, input, textarea {
  font: 99% sans-serif; }

table {
  font-size: inherit;
  font: 100%; }

small {
  font-size: 85%; }

strong, #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, .cp-label.v2 span {
  font-weight: bold; }

td, td img {
  vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
  margin: 0; }

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover
   (commented out as usage is rare, and the filter syntax messes with some pre-processors)
.ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));}
*/
/* let's clear some floats */
.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  overflow: hidden; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong, #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, .cp-label.v2 span {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

@font-face {
  font-family: "TVNordEF-Regular";
  src: url("/app/fonts/2F00D8_0_0.eot");
  src: url("/app/fonts/2F00D8_0_0.eot?#iefix") format("embedded-opentype"), url("/app/fonts/2F00D8_0_0.woff2") format("woff2"), url("/app/fonts/2F00D8_0_0.woff") format("woff"), url("/app/fonts/2F00D8_0_0.ttf") format("truetype"); }

@font-face {
  font-family: "TVNordEF-Bold";
  src: url("/app/fonts/2F00D8_1_0.eot");
  src: url("/app/fonts/2F00D8_1_0.eot?#iefix") format("embedded-opentype"), url("/app/fonts/2F00D8_1_0.woff2") format("woff2"), url("/app/fonts/2F00D8_1_0.woff") format("woff"), url("/app/fonts/2F00D8_1_0.ttf") format("truetype"); }

.t-default, body, .cp-text-top h1 small, .p-product main .cp-top-section .t-h1 p.product-code, .p-contact main .map-overlay {
  font-family: TVNordEF-Regular, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #2b2b2b;
  text-decoration: none; }

.t-h1 {
  margin-bottom: 40px;
  font-size: 48px;
  line-height: 60px; }

.t-h2, .cp-search-drawer input, .order-tracker-multi-screen-2.loading:after {
  font-family: TVNordEF-Regular, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 48px; }

.t-h2-2 {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 32px; }

.t-h3, .p-contact main .content-overlay div {
  font-size: 23px;
  line-height: 1em;
  margin-bottom: 24px; }

.t-h4, .cp-module a, .cp-module-side .t strong, .cp-module-side .t #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, #menu-drawer nav ul.tree .cp-module-side .t ul[data-depth="1"] > li.has-children > a, .cp-module-side .t .cp-label.v2 span, .cp-label.v2 .cp-module-side .t span, header .bar nav a, .cp-swiper-gallery.cfeature .items .text, .cp-breadcrumb a:last-of-type, .p-contact main .map-overlay h2 {
  line-height: 24px;
  font-family: TVNordEF-Bold, Arial, sans-serif;
  font-style: normal;
  font-weight: normal; }

.t-h5 {
  font-family: "franklin-gothic-urw-cond-n7", "franklin-gothic-urw-cond", "sans-serif";
  font-style: "normal";
  font-weight: 700;
  font-size: 14px; }

.t-links, .g-button.alt, button.alt, .cp-module .overlay span, #menu-drawer nav ul.tree > li > a, #menu-drawer nav ul.main-links > li > a, #menu-drawer nav ul.products > li > a, .cp-search-drawer .desktop-recent-searches div, .p-contact main .map-container .overlay div {
  font-size: 21px; }

a {
  text-decoration: none; }

p {
  margin-bottom: 16px; }

.t-right {
  text-align: right; }

.t-left {
  text-align: left; }

.t-center {
  text-align: center; }

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

strong, #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, .cp-label.v2 span {
  font-family: TVNordEF-Bold, Arial, sans-serif;
  font-style: normal;
  font-weight: normal; }

/**
handles display of reversed and obfuscated mailto links
**/
.otliam {
  direction: rtl;
  unicode-bidi: bidi-override; }
  .otliam i, .otliam em, .otliam b {
    font-style: normal; }
  .otliam b:before {
    content: '.'; }
  .otliam em:before {
    content: '@'; }

* {
  outline: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box; }

a {
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

input, textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[data-clickthrough] {
  cursor: pointer; }

body {
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: greyscale;
  -webkit-font-smoothing: antialiased; }

main {
  overflow: hidden; }

.icon-info span, .icon-glass span, .icon-chat span, .icon-faq span, .icon-manual span, .icon-stockist span, .icon-contact span, .icon-trade span, .icon-map-marker span, .icon-calculator span, .icon-calculator-large span, .icon-download-arrow, footer .legrand, header .logo span, header .search span, header .mobile-menu span, #menu-drawer .close span, .cp-search-drawer .close span, .cp-country-checker .close span, .survey-overlay .close span {
  display: inline-block;
  background: url(/app/svg/sprite.css-72317177.svg) no-repeat; }

.gl-container, .cp-top-section .t-container {
  padding: 0 8px;
  margin: 0 auto;
  width: 100%;
  max-width: 1016px; }
  .gl-container.full, .cp-top-section .full.t-container {
    max-width: 100%; }
  .gl-container:after, .cp-top-section .t-container:after {
    display: block;
    content: ' ';
    clear: both; }
  .gl-container .gl-container, .cp-top-section .t-container .gl-container, .gl-container .cp-top-section .t-container, .cp-top-section .gl-container .t-container, .cp-top-section .t-container .t-container {
    margin-left: -16px !important;
    margin-right: -16px !important;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0; }
  .gl-container > *, .cp-top-section .t-container > * {
    padding: 0 8px;
    width: 100%; }
  .gl-container > .gc-full, .cp-top-section .t-container > .gc-full {
    width: 100% !important; }
  .gl-container .gc-right, .cp-top-section .t-container .gc-right {
    float: right; }
  .gl-container .gc-left, .cp-top-section .t-container .gc-left {
    float: left; }

.gc-1 {
  width: 8.3333333333%;
  float: left; }

.gcp-1 {
  margin-left: 8.3333333333%; }

.gc-2 {
  width: 16.6666666667%;
  float: left; }

.gcp-2 {
  margin-left: 16.6666666667%; }

.gc-3 {
  width: 25%;
  float: left; }

.gcp-3 {
  margin-left: 25%; }

.gc-4 {
  width: 33.3333333333%;
  float: left; }

.gcp-4 {
  margin-left: 33.3333333333%; }

.gc-5 {
  width: 41.6666666667%;
  float: left; }

.gcp-5 {
  margin-left: 41.6666666667%; }

.gc-6 {
  width: 50%;
  float: left; }

.gcp-6 {
  margin-left: 50%; }

.gc-7 {
  width: 58.3333333333%;
  float: left; }

.gcp-7 {
  margin-left: 58.3333333333%; }

.gc-8 {
  width: 66.6666666667%;
  float: left; }

.gcp-8 {
  margin-left: 66.6666666667%; }

.gc-9 {
  width: 75%;
  float: left; }

.gcp-9 {
  margin-left: 75%; }

.gc-10 {
  width: 83.3333333333%;
  float: left; }

.gcp-10 {
  margin-left: 83.3333333333%; }

.gc-11 {
  width: 91.6666666667%;
  float: left; }

.gcp-11 {
  margin-left: 91.6666666667%; }

.gc-12 {
  width: 100%;
  float: left; }

.gcp-12 {
  margin-left: 100%; }

@media (min-width: 1024px) {
  .gc-1 {
    width: 8.3333333333%; }
  .gc-2 {
    width: 16.6666666667%; }
  .gc-3 {
    width: 25%; }
  .gc-4 {
    width: 33.3333333333%; }
  .gc-5 {
    width: 41.6666666667%; }
  .gc-6 {
    width: 50%; }
  .gc-7 {
    width: 58.3333333333%; }
  .gc-8 {
    width: 66.6666666667%; }
  .gc-9 {
    width: 75%; }
  .gc-10 {
    width: 83.3333333333%; }
  .gc-11 {
    width: 91.6666666667%; }
  .gc-12 {
    width: 100%; }
  .bp4-gcp-0 {
    margin-left: 0; }
  .bp4-gc-half {
    width: 50%; }
  .bp4-gc-1-12 {
    width: 8.3333333333%; }
  .bp4-gcp-1-12 {
    margin-left: 8.3333333333%; }
  .bp4-gc-2-12 {
    width: 16.6666666667%; }
  .bp4-gcp-2-12 {
    margin-left: 16.6666666667%; }
  .bp4-gc-3-12 {
    width: 25%; }
  .bp4-gcp-3-12 {
    margin-left: 25%; }
  .bp4-gc-4-12 {
    width: 33.3333333333%; }
  .bp4-gcp-4-12 {
    margin-left: 33.3333333333%; }
  .bp4-gc-5-12 {
    width: 41.6666666667%; }
  .bp4-gcp-5-12 {
    margin-left: 41.6666666667%; }
  .bp4-gc-6-12 {
    width: 50%; }
  .bp4-gcp-6-12 {
    margin-left: 50%; }
  .bp4-gc-7-12 {
    width: 58.3333333333%; }
  .bp4-gcp-7-12 {
    margin-left: 58.3333333333%; }
  .bp4-gc-8-12 {
    width: 66.6666666667%; }
  .bp4-gcp-8-12 {
    margin-left: 66.6666666667%; }
  .bp4-gc-9-12 {
    width: 75%; }
  .bp4-gcp-9-12 {
    margin-left: 75%; }
  .bp4-gc-10-12 {
    width: 83.3333333333%; }
  .bp4-gcp-10-12 {
    margin-left: 83.3333333333%; }
  .bp4-gc-11-12 {
    width: 91.6666666667%; }
  .bp4-gcp-11-12 {
    margin-left: 91.6666666667%; }
  .bp4-gc-12-12 {
    width: 100%; }
  .bp4-gcp-12-12 {
    margin-left: 100%; } }

@media (max-width: 1023px) {
  .gc-1 {
    width: 11.1111111111%; }
  .gc-2 {
    width: 22.2222222222%; }
  .gc-3 {
    width: 33.3333333333%; }
  .gc-4 {
    width: 44.4444444444%; }
  .gc-5 {
    width: 55.5555555556%; }
  .gc-6 {
    width: 66.6666666667%; }
  .gc-7 {
    width: 77.7777777778%; }
  .gc-8 {
    width: 88.8888888889%; }
  .gc-9 {
    width: 100%; }
  .gc-10 {
    width: 100%; }
  .gc-11 {
    width: 100%; }
  .gc-12 {
    width: 100%; }
  .bp3-gcp-0 {
    margin-left: 0; }
  .bp3-gc-half {
    width: 50%; }
  .bp3-gc-1-9 {
    width: 11.1111111111%; }
  .bp3-gcp-1-12 {
    margin-left: 8.3333333333%; }
  .bp3-gc-2-9 {
    width: 22.2222222222%; }
  .bp3-gcp-2-12 {
    margin-left: 16.6666666667%; }
  .bp3-gc-3-9 {
    width: 33.3333333333%; }
  .bp3-gcp-3-12 {
    margin-left: 25%; }
  .bp3-gc-4-9 {
    width: 44.4444444444%; }
  .bp3-gcp-4-12 {
    margin-left: 33.3333333333%; }
  .bp3-gc-5-9 {
    width: 55.5555555556%; }
  .bp3-gcp-5-12 {
    margin-left: 41.6666666667%; }
  .bp3-gc-6-9 {
    width: 66.6666666667%; }
  .bp3-gcp-6-12 {
    margin-left: 50%; }
  .bp3-gc-7-9 {
    width: 77.7777777778%; }
  .bp3-gcp-7-12 {
    margin-left: 58.3333333333%; }
  .bp3-gc-8-9 {
    width: 88.8888888889%; }
  .bp3-gcp-8-12 {
    margin-left: 66.6666666667%; }
  .bp3-gc-9-9 {
    width: 100%; }
  .bp3-gcp-9-12 {
    margin-left: 75%; } }

@media (max-width: 767px) {
  .gc-1 {
    width: 16.6666666667%; }
  .gc-2 {
    width: 33.3333333333%; }
  .gc-3 {
    width: 50%; }
  .gc-4 {
    width: 66.6666666667%; }
  .gc-5 {
    width: 83.3333333333%; }
  .gc-6 {
    width: 100%; }
  .gc-7 {
    width: 100%; }
  .gc-8 {
    width: 100%; }
  .gc-9 {
    width: 100%; }
  .gc-10 {
    width: 100%; }
  .gc-11 {
    width: 100%; }
  .gc-12 {
    width: 100%; }
  .bp2-gcp-0 {
    margin-left: 0; }
  .bp2-gc-half {
    width: 50%; }
  .bp2-gc-1-6 {
    width: 16.6666666667%; }
  .bp2-gcp-1-12 {
    margin-left: 8.3333333333%; }
  .bp2-gc-2-6 {
    width: 33.3333333333%; }
  .bp2-gcp-2-12 {
    margin-left: 16.6666666667%; }
  .bp2-gc-3-6 {
    width: 50%; }
  .bp2-gcp-3-12 {
    margin-left: 25%; }
  .bp2-gc-4-6 {
    width: 66.6666666667%; }
  .bp2-gcp-4-12 {
    margin-left: 33.3333333333%; }
  .bp2-gc-5-6 {
    width: 83.3333333333%; }
  .bp2-gcp-5-12 {
    margin-left: 41.6666666667%; }
  .bp2-gc-6-6 {
    width: 100%; }
  .bp2-gcp-6-12 {
    margin-left: 50%; } }

@media (max-width: 479px) {
  .gc-1 {
    width: 33.3333333333%; }
  .gc-2 {
    width: 66.6666666667%; }
  .gc-3 {
    width: 100%; }
  .gc-4 {
    width: 100%; }
  .gc-5 {
    width: 100%; }
  .gc-6 {
    width: 100%; }
  .gc-7 {
    width: 100%; }
  .gc-8 {
    width: 100%; }
  .gc-9 {
    width: 100%; }
  .gc-10 {
    width: 100%; }
  .gc-11 {
    width: 100%; }
  .gc-12 {
    width: 100%; }
  .bp1-gcp-0 {
    margin-left: 0; }
  .bp1-gc-half {
    width: 50%; }
  .bp1-gc-1-3 {
    width: 33.3333333333%; }
  .bp1-gcp-1-12 {
    margin-left: 8.3333333333%; }
  .bp1-gc-2-3 {
    width: 66.6666666667%; }
  .bp1-gcp-2-12 {
    margin-left: 16.6666666667%; }
  .bp1-gc-3-3 {
    width: 100%; }
  .bp1-gcp-3-12 {
    margin-left: 25%; } }

.g-hidden {
  display: none; }

.g-visible {
  display: block; }

@media (min-width: 1024px) {
  .bp4-hidden {
    display: none; }
  .bp4-visible {
    display: block; } }

@media (max-width: 1023px) {
  .bp3-hidden {
    display: none; }
  .bp3-visible {
    display: block; } }

@media (max-width: 767px) {
  .bp2-hidden {
    display: none; }
  .bp2-visible {
    display: block; } }

@media (max-width: 479px) {
  .bp1-hidden {
    display: none; }
  .bp1-visible {
    display: block; } }

body, html {
  height: 100%;
  width: 100%; }

.scrollable {
  margin-top: 94px;
  backface-visibility: visible;
  background-color: #eaeae3;
  transition: padding-top 0.5s ease-out; }
  .scrollable.search-open {
    -webkit-transform: translateY(190px);
    -moz-transform: translateY(190px);
    -ms-transform: translateY(190px);
    -o-transform: translateY(190px);
    transform: translateY(190px); }

main {
  min-height: 50vh;
  max-width: 1300px;
  margin: 0 auto;
  width: 100%;
  position: relative; }

.g-p-1 {
  padding-top: 120px; }

.g-pb-9 {
  padding-bottom: 72px; }

.g-pt-9 {
  padding-top: 72px; }

.g-pb-12 {
  padding-bottom: 96px; }

.g-pb-15 {
  padding-bottom: 120px; }

.g-pt-15 {
  padding-top: 120px; }

.g-pb-7, .cp-top-section .specs {
  padding-bottom: 56px; }

.g-pt-7 {
  padding-top: 56px; }

.g-pb-6 {
  padding-bottom: 48px; }

.g-pt-6 {
  padding-top: 48px; }

.g-pb-5 {
  padding-bottom: 40px; }

.g-pt-5 {
  padding-top: 40px; }

.g-pb-4 {
  padding-bottom: 32px; }

.g-pt-4 {
  padding-top: 32px; }

.g-pt-7 {
  padding-top: 56px; }

.g-pt-8 {
  padding-top: 64px; }

.g-mb-15 {
  margin-bottom: 120px; }

.g-mt-7 {
  margin-top: 56px; }

.g-mt-15 {
  margin-top: 120px; }

.g-mt-3 {
  margin-top: 24px; }

.g-mt-4 {
  margin-top: 32px; }

.g-pt-16 {
  padding-top: 128px; }

.g-pb-16 {
  padding-bottom: 128px; }

.g-pt-17 {
  padding-top: 136px; }

.g-pb-17 {
  padding-bottom: 136px; }

.g-pt-20 {
  padding-top: 160px; }

.g-pb-20 {
  padding-bottom: 160px; }

.g-pb-28 {
  padding-bottom: 224px; }

.g-m-1 {
  margin-top: 120px; }

.g-no-pt {
  padding-top: 0 !important; }

.load {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /** prevent ios flickers **/
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s; }

.lazyloaded {
  opacity: 1; }

/*
.isotope {
	visibility: hidden;
	opacity: 0;
	@include fbTransition(opacity,0.8s);
}

.isotope[style] {
	visibility: visible;
	opacity: 1;
}*/
.g-bg-yellow-one {
  background-color: #ffe000; }

.g-c-yellow-one {
  color: #ffe000; }

.g-bg-yellow-two {
  background-color: #f1cb33; }

.g-c-yellow-two {
  color: #f1cb33; }

.g-bg-yellow-three {
  background-color: #ffd800; }

.g-c-yellow-three {
  color: #ffd800; }

.g-bg-yellow-four {
  background-color: #ffda00; }

.g-c-yellow-four {
  color: #ffda00; }

.g-bg-yellow-five {
  background-color: #ffd000; }

.g-c-yellow-five {
  color: #ffd000; }

.g-bg-white-one {
  background-color: #ffffff; }

.g-c-white-one {
  color: #ffffff; }

.g-bg-white-two, main {
  background-color: #f1f1eb; }

.g-c-white-two {
  color: #f1f1eb; }

.g-bg-white-three {
  background-color: #e4e4d9; }

.g-c-white-three {
  color: #e4e4d9; }

.g-bg-white-four {
  background-color: #f9f8f3; }

.g-c-white-four {
  color: #f9f8f3; }

.g-bg-white-five {
  background-color: #b2b2b2; }

.g-c-white-five {
  color: #b2b2b2; }

.g-bg-white-six {
  background-color: #dddddd; }

.g-c-white-six {
  color: #dddddd; }

.g-bg-white-seven {
  background-color: #DEDED6; }

.g-c-white-seven {
  color: #DEDED6; }

.g-bg-black-one {
  background-color: #242424; }

.g-c-black-one {
  color: #242424; }

.g-bg-black-two {
  background-color: #2b2b2b; }

.g-c-black-two {
  color: #2b2b2b; }

.g-bg-black-three {
  background-color: #7b7b7b; }

.g-c-black-three {
  color: #7b7b7b; }

.g-bg-black-four {
  background-color: #dadbd5; }

.g-c-black-four {
  color: #dadbd5; }

.g-bg-ordertracker-grey {
  background-color: #eaeae8; }

.g-c-ordertracker-grey {
  color: #eaeae8; }

.g-bg-ordertracker-black {
  background-color: #2b2b2b; }

.g-c-ordertracker-black {
  color: #2b2b2b; }

.g-bg-ordertracker-white {
  background-color: #ffffff; }

.g-c-ordertracker-white {
  color: #ffffff; }

form.darker input[type="text"], form.darker input[type="number"], form.darker textarea, form.darker select {
  background-color: #f1f1eb;
  color: #242424; }

form .field {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden;
  background-color: #ffffff !important; }
  form .field:before {
    position: absolute;
    width: 16px;
    height: 4px;
    top: 19px;
    left: -18px;
    -webkit-backface-visibility: hidden;
    /** prevent ios flickers **/
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
    -moz-transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
    transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    transition-delay: 0.2s;
    background-color: #ffe000;
    content: ' '; }

form .check, form .nobg {
  background-color: transparent !important; }

form input[type="text"], form input[type="number"], form input[type="email"], form textarea, form select {
  -webkit-backface-visibility: hidden;
  /** prevent ios flickers **/
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
  -moz-transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
  transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
  width: 100%;
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  display: block; }
  form input[type="text"]::-webkit-input-placeholder, form input[type="number"]::-webkit-input-placeholder, form input[type="email"]::-webkit-input-placeholder, form textarea::-webkit-input-placeholder, form select::-webkit-input-placeholder {
    color: #2b2b2b;
    line-height: 40px; }
  form input[type="text"]::-moz-placeholder, form input[type="number"]::-moz-placeholder, form input[type="email"]::-moz-placeholder, form textarea::-moz-placeholder, form select::-moz-placeholder {
    color: #2b2b2b;
    line-height: 40px; }
  form input[type="text"]:-moz-placeholder, form input[type="number"]:-moz-placeholder, form input[type="email"]:-moz-placeholder, form textarea:-moz-placeholder, form select:-moz-placeholder {
    color: #2b2b2b;
    line-height: 40px; }
  form input[type="text"]:-ms-input-placeholder, form input[type="number"]:-ms-input-placeholder, form input[type="email"]:-ms-input-placeholder, form textarea:-ms-input-placeholder, form select:-ms-input-placeholder {
    color: #2b2b2b;
    line-height: 40px; }

form input[type=number]::-webkit-inner-spin-button,
form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

form [id^="parsley-id-"] {
  display: none; }

form .error input[type="text"], form .error input[type="number"], form .error input[type="email"], form .error textarea, form .error select {
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -ms-transform: translateX(30px);
  -o-transform: translateX(30px);
  transform: translateX(30px); }

form .error:before {
  -webkit-transform: translateX(35px);
  -moz-transform: translateX(35px);
  -ms-transform: translateX(35px);
  -o-transform: translateX(35px);
  transform: translateX(35px); }

form input + label, form select + label, form textarea + label {
  display: none; }

form input, form select {
  margin-top: 2px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  max-height: 40px;
  display: block; }

form select {
  height: 40px; }

form textarea {
  padding: 10px 15px;
  display: block;
  min-height: 110px; }

form button.alt {
  float: right;
  display: inline-block;
  width: auto; }

form .check {
  position: relative; }
  form .check label {
    padding-left: 37px;
    display: block;
    position: relative; }
    form .check label:before {
      content: " ";
      display: block;
      background-color: #e4e4d9;
      position: absolute;
      width: 24px;
      height: 24px;
      left: 0;
      top: 0; }
    form .check label:after {
      position: absolute;
      width: 10px;
      height: 4px;
      background: transparent;
      border: 1px solid #242424;
      border-top: none;
      border-right: none;
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -moz-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
      -o-transform: translate(-50%, -50%) rotate(-45deg);
      transform: translate(-50%, -50%) rotate(-45deg);
      top: 47%;
      left: 12px;
      content: " ";
      display: none; }
  form .check input {
    position: absolute;
    visibility: hidden; }
  form .check input:checked + label:after {
    display: inline-block; }

.icon-info span {
  background-position: -413.1px 0;
  width: 51px;
  height: 51px; }

.icon-glass span {
  background-position: -161px -339.8px;
  width: 51.21px;
  height: 51.21px; }

.icon-chat span {
  background-position: -212.21px -339.8px;
  width: 51px;
  height: 48.62px; }

.icon-faq span {
  background-position: 0 -107.8px;
  width: 116px;
  height: 116px; }

.icon-manual span {
  background-position: -116px -223.8px;
  width: 116px;
  height: 116px; }

.icon-stockist span {
  background-position: -232px -223.8px;
  width: 116px;
  height: 116px; }

.icon-contact span {
  background-position: -116px -107.8px;
  width: 116px;
  height: 116px; }

.icon-trade span {
  background-position: 0 -223.8px;
  width: 116px;
  height: 116px; }

.icon-map-marker span {
  background-position: -108px -339.8px;
  width: 53px;
  height: 53px; }

.icon-calculator span {
  background-position: -297.1px -116px;
  width: 58px;
  height: 55px; }

.icon-calculator-large span {
  background-position: -297.1px 0;
  width: 116px;
  height: 116px; }

.icon-download-arrow {
  background-position: -413.1px -51px;
  width: 14px;
  height: 26px; }

.g-button, button {
  line-height: 40px;
  background-color: #242424;
  display: block;
  border: none;
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: #ffffff;
  font-size: 14px;
  padding: 0 0 0 15px;
  position: relative; }
  .g-button.alt, button.alt {
    color: #242424;
    line-height: 45px;
    background-color: transparent;
    vertical-align: middle; }
    .g-button.alt .circle, button.alt .circle {
      margin-left: 20px;
      vertical-align: middle;
      border-color: #ffe000; }
    .g-button.alt.left, button.alt.left {
      padding-left: 0; }
      .g-button.alt.left .circle, button.alt.left .circle {
        margin-left: 0;
        margin-right: 20px; }
    .g-button.alt.white .circle, button.alt.white .circle {
      border-color: #ffffff; }
  .g-button .arrow, button .arrow {
    position: absolute;
    right: 25px;
    top: 50%;
    display: block;
    height: 2px;
    width: 20px;
    background-color: #ffffff;
    -webkit-backface-visibility: hidden;
    /** prevent ios flickers **/
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s; }
    .g-button .arrow:after, button .arrow:after {
      position: absolute;
      content: " ";
      right: 0px;
      top: -2px;
      width: 6px;
      height: 6px;
      display: block;
      border-bottom: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg); }
  .g-button:hover .arrow, .g-button.active .arrow, button:hover .arrow, button.active .arrow {
    -webkit-transform: translateX(10px);
    -moz-transform: translateX(10px);
    -ms-transform: translateX(10px);
    -o-transform: translateX(10px);
    transform: translateX(10px);
    background-color: #ffe000; }
    .g-button:hover .arrow:after, .g-button.active .arrow:after, button:hover .arrow:after, button.active .arrow:after {
      border-color: #ffe000; }
  .g-button.circle, .g-button .circle, button.circle, button .circle {
    position: relative;
    display: inline-block;
    border: 2px solid #e4e4d9;
    width: 45px;
    line-height: 45px;
    height: 45px;
    background-color: transparent;
    border-radius: 500px; }
    .g-button.circle .arrow, .g-button .circle .arrow, button.circle .arrow, button .circle .arrow {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background-color: #242424; }
      .g-button.circle .arrow:after, .g-button .circle .arrow:after, button.circle .arrow:after, button .circle .arrow:after {
        border-color: #242424; }
    .g-button.circle .arrow-down, .g-button .circle .arrow-down, button.circle .arrow-down, button .circle .arrow-down {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
      top: 49%;
      left: 26%; }
    .g-button.circle .arrow-left, .g-button .circle .arrow-left, button.circle .arrow-left, button .circle .arrow-left {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      top: 49%;
      left: 26%; }
    .g-button.circle:hover, .g-button.circle.active, .g-button .circle:hover, .g-button .circle.active, button.circle:hover, button.circle.active, button .circle:hover, button .circle.active {
      border-color: #ffe000; }
    .g-button.circle .plus, .g-button .circle .plus, button.circle .plus, button .circle .plus {
      width: 11px;
      height: 11px;
      position: absolute;
      top: 15px;
      left: 15px;
      -webkit-backface-visibility: hidden;
      /** prevent ios flickers **/
      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      transition-property: transform;
      -webkit-transition-duration: 0.2s;
      -moz-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-timing-function: ease-out;
      -moz-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s; }
      .g-button.circle .plus:before, .g-button .circle .plus:before, button.circle .plus:before, button .circle .plus:before {
        position: absolute;
        content: " ";
        width: 11px;
        height: 1px;
        display: block;
        top: 5px;
        left: 0;
        background-color: #242424; }
      .g-button.circle .plus:after, .g-button .circle .plus:after, button.circle .plus:after, button .circle .plus:after {
        position: absolute;
        content: " ";
        width: 1px;
        height: 11px;
        display: block;
        top: 0;
        left: 5px;
        background-color: #242424; }
    .g-button.circle.reload span, .g-button .circle.reload span, button.circle.reload span, button .circle.reload span {
      position: absolute;
      width: 100%;
      height: 100%;
      left: -4px;
      top: -4px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUwcHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDUwIDUwIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MCA1MCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMkIyQjJCIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0yNSwxMi4zNDNjNi45NSwwLDEyLjU4NCw1LjYzNCwxMi41ODQsMTIuNTg0UzMxLjk1LDM3LjUxLDI1LDM3LjUxIiAvPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzJCMkIyQiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBkPSJNMjUsMTIuMzQzYy02Ljk1LDAtMTIuNTg0LDUuNjM0LTEyLjU4NCwxMi41ODRjMCwyLjcyNSwwLjg2Niw1LjI0NywyLjMzOCw3LjMwN2wyLjI4MSwyLjI4MSIvPjxwb2x5bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiMyQjJCMkIiIHN0cm9rZS1saW5lam9pbj0iYmV2ZWwiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgcG9pbnRzPSIxNy4yODcsMzAuMjA0IDE3LjI4NywzNC42NjkgMTIuODIyLDM0LjY2OSAiLz48L2c+PC9zdmc+); }
    .g-button.circle.tooltip, .g-button .circle.tooltip, button.circle.tooltip, button .circle.tooltip {
      background-color: #ffe000;
      border-color: #ffe000; }
      .g-button.circle.tooltip.grey, .g-button .circle.tooltip.grey, button.circle.tooltip.grey, button .circle.tooltip.grey {
        background-color: #ffffff;
        border-color: #dddddd; }
      .g-button.circle.tooltip:active .plus, .g-button.circle.tooltip.active .plus, .g-button .circle.tooltip:active .plus, .g-button .circle.tooltip.active .plus, button.circle.tooltip:active .plus, button.circle.tooltip.active .plus, button .circle.tooltip:active .plus, button .circle.tooltip.active .plus {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg); }
  .g-button.active .circle .plus, button.active .circle .plus {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .g-button span.icon, button span.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.cp-module {
  background-color: #f1f1eb;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
  cursor: pointer;
  background-color: #e4e4d9; }
  .cp-module.transparent {
    background-color: transparent; }
  .cp-module.large {
    background-color: #fff; }
    .cp-module.large .image-wrapp {
      position: relative;
      padding-bottom: 71%; }
      .cp-module.large .image-wrapp:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #242424;
        z-index: 10;
        opacity: 0.2; }
      .cp-module.large .image-wrapp .bar {
        bottom: 0; }
    .cp-module.large .card {
      position: relative;
      padding: 0 21px 72%; }
      .cp-module.large .card div {
        position: absolute;
        top: 0;
        bottom: 0;
        margin-bottom: 20px;
        overflow: hidden;
        left: 21px;
        right: 21px; }
      .cp-module.large .card h3 {
        margin-top: 16px; }
      .cp-module.large .card em {
        display: block;
        margin-bottom: 24px; }
  .cp-module img {
    position: absolute;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto; }
  .cp-module .overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(43, 43, 43, 0.8);
    -webkit-backface-visibility: hidden;
    /** prevent ios flickers **/
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    opacity: 0; }
    .cp-module .overlay div {
      text-align: center;
      position: absolute;
      top: calc(50% - 32px - 13px);
      left: 0;
      width: 100%; }
    .cp-module .overlay span {
      display: inline-block;
      border-bottom: 1px solid #ffe000;
      color: #ffffff; }
  .cp-module .bar {
    position: absolute;
    z-index: 10;
    height: 8px;
    background-color: #ffe000;
    width: 100%;
    left: 100%;
    bottom: 56px;
    -webkit-backface-visibility: hidden;
    /** prevent ios flickers **/
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translateX(-30%);
    -moz-transform: translateX(-30%);
    -ms-transform: translateX(-30%);
    -o-transform: translateX(-30%);
    transform: translateX(-30%); }
  .cp-module a {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    display: block;
    height: 56px;
    line-height: 56px !important;
    background-color: #ffffff; }
    .cp-module a span:first-of-type {
      display: inline-block;
      width: calc(100% - 70px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 21px; }
    .cp-module a span:last-of-type {
      display: inline-block;
      float: right; }
      .cp-module a span:last-of-type.plus {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 22px;
        right: 28px;
        -webkit-backface-visibility: hidden;
        /** prevent ios flickers **/
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        transition-property: transform;
        -webkit-transition-duration: 0.2s;
        -moz-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-timing-function: ease-out;
        -moz-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        transition-delay: 0s; }
        .cp-module a span:last-of-type.plus:before {
          position: absolute;
          content: " ";
          width: 11px;
          height: 1px;
          display: block;
          top: 5px;
          left: 0;
          background-color: #242424; }
        .cp-module a span:last-of-type.plus:after {
          position: absolute;
          content: " ";
          width: 1px;
          height: 11px;
          display: block;
          top: 0;
          left: 5px;
          background-color: #242424; }
  .no-touch .cp-module:hover .bar {
    -webkit-transform: translateX(-99%);
    -moz-transform: translateX(-99%);
    -ms-transform: translateX(-99%);
    -o-transform: translateX(-99%);
    transform: translateX(-99%);
    width: 110%; }
  .no-touch .cp-module:hover .overlay {
    opacity: 1; }

.gc-6 .cp-module {
  padding-bottom: calc(100% + 112px); }

.gc-3 .cp-module {
  padding-bottom: calc(100% + 56px); }

.cp-module-side {
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
  padding-bottom: 31%; }
  .cp-module-side img {
    display: block;
    width: 100%;
    height: auto; }
  .cp-module-side .img {
    position: absolute;
    width: 31%;
    background-color: #e4e4d9; }
  .cp-module-side .t {
    padding: 16px;
    position: absolute;
    top: 0;
    left: 31%;
    width: 75%;
    z-index: 10;
    display: block;
    height: 100%; }
    .cp-module-side .t span {
      position: absolute;
      bottom: 8px;
      color: #b2b2b2; }
    .cp-module-side .t p {
      margin-bottom: 0; }

footer {
  max-width: 1300px;
  margin: 0 auto;
  background-color: #ffe000; }
  footer .wrapp > .gl-container, footer .cp-top-section .wrapp > .t-container, .cp-top-section footer .wrapp > .t-container {
    display: flex; }
    footer .wrapp > .gl-container [class^="icon-"], footer .cp-top-section .wrapp > .t-container [class^="icon-"], .cp-top-section footer .wrapp > .t-container [class^="icon-"] {
      margin-bottom: 32px;
      height: 48px; }
    footer .wrapp > .gl-container div.gc-4, footer .cp-top-section .wrapp > .t-container div.gc-4, .cp-top-section footer .wrapp > .t-container div.gc-4 {
      display: flex; }
      footer .wrapp > .gl-container div.gc-4 article, footer .cp-top-section .wrapp > .t-container div.gc-4 article, .cp-top-section footer .wrapp > .t-container div.gc-4 article {
        width: 100%;
        position: relative; }
        footer .wrapp > .gl-container div.gc-4 article a, footer .cp-top-section .wrapp > .t-container div.gc-4 article a, .cp-top-section footer .wrapp > .t-container div.gc-4 article a {
          margin-bottom: 16px;
          position: absolute;
          bottom: 0; }
  footer .wrapp form .check label:before {
    background-color: #f1cb33; }
  footer .wrapp .sub ul li {
    display: inline-block;
    margin-right: 16px; }
  footer .wrapp .pb {
    padding-bottom: 8px; }
  footer .t-right span {
    display: inline-block;
    vertical-align: top; }
  footer .legrand {
    background-position: 0 0;
    width: 164.2px;
    height: 23.1px; }
  footer .v-line {
    width: 1px;
    border-right: 1px solid #242424;
    height: 17px;
    margin: 2px 16px 0px; }

header {
  position: fixed;
  height: 95px;
  z-index: 30;
  width: 100%;
  top: 0;
  left: 0;
  transition: top 0.5s ease-out; }
  header .dest {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 95px; }
  header .outer-wrapper {
    position: relative;
    z-index: 30; }
  header .outer-wrapper:after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 20;
    width: 15px;
    right: 0;
    pointer-events: none; }
  header .wrapp {
    max-width: 1300px !important;
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 100%; }
  header .gl-container.full, header .cp-top-section .full.t-container, .cp-top-section header .full.t-container {
    padding-left: 5px; }
  header .bar {
    position: relative;
    height: 95px;
    z-index: 20;
    background-color: #eaeae3; }
    header .bar .wrapp {
      background-color: #ffffff; }
  header a.search span, header a.logo span {
    text-indent: -5000px; }
  header .logo {
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -31px;
    height: 100%;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center; }
    header .logo span {
      background-position: 0 -46.8px;
      width: 137px;
      height: 61px; }
    header .logo:after {
      display: none !important; }
  header .bar nav ul li {
    display: inline-block; }
  header .bar nav a {
    overflow: hidden;
    position: relative;
    line-height: 95px;
    padding-left: 33.5px;
    padding-right: 33.5px;
    height: 100%;
    display: block; }
    header .bar nav a:after {
      -webkit-backface-visibility: hidden;
      /** prevent ios flickers **/
      -webkit-transition-property: -webkit-transform;
      -moz-transition-property: -moz-transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      -moz-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      left: 33.5px;
      right: 33.5px;
      width: auto; }
  header .bar {
    overflow: hidden; }
    header .bar a {
      line-height: 95px;
      height: 100%;
      display: block; }
      header .bar a:after {
        -webkit-backface-visibility: hidden;
        /** prevent ios flickers **/
        -webkit-transition-property: -webkit-transform;
        -moz-transition-property: -moz-transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        -moz-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        transition-delay: 0s;
        content: " ";
        display: block;
        height: 8px;
        position: absolute;
        bottom: -8px;
        background-color: #ffe000;
        width: 100%; }
      header .bar a:hover:after, header .bar a.active:after, header .bar a.active-always:after {
        -webkit-transform: translateY(-8px);
        -moz-transform: translateY(-8px);
        -ms-transform: translateY(-8px);
        -o-transform: translateY(-8px);
        transform: translateY(-8px); }
  header .search, header .mobile-menu {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 95px; }
    header .search.mobile-menu, header .mobile-menu.mobile-menu {
      right: 95px;
      display: none; }
      header .search.mobile-menu span, header .mobile-menu.mobile-menu span {
        margin-top: 0;
        text-indent: -9999px;
        height: 2px;
        width: 20px;
        background-color: #242424; }
        header .search.mobile-menu span:before, header .mobile-menu.mobile-menu span:before {
          bottom: auto;
          top: -7px; }
        header .search.mobile-menu span:after, header .search.mobile-menu span:before, header .mobile-menu.mobile-menu span:after, header .mobile-menu.mobile-menu span:before {
          content: " ";
          display: block;
          height: 2px;
          position: absolute;
          background-color: #242424;
          bottom: -7px;
          width: 20px; }
    header .search span, header .mobile-menu span {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -10px;
      margin-left: -10px;
      background-position: -413.1px -77px;
      width: 20px;
      height: 19px;
      line-height: 95px; }

#menu-drawer {
  width: 100%;
  position: absolute;
  height: calc(100vh - 95px);
  top: 95px;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  left: 0;
  z-index: 10; }
  #menu-drawer .close {
    position: absolute;
    right: 38px;
    top: 32px; }
    #menu-drawer .close span {
      background-position: -433.1px -77px;
      width: 17.41px;
      height: 17.41px; }
  #menu-drawer .wrapp {
    padding: 72px 0 21px;
    background-color: #2b2b2b;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
  #menu-drawer nav {
    margin-right: 21px;
    margin-left: 21px; }
    #menu-drawer nav ul.tree > li > a, #menu-drawer nav ul.main-links > li > a, #menu-drawer nav ul.products > li > a {
      line-height: 45px; }
    #menu-drawer nav a {
      color: #b2b2b2; }
      #menu-drawer nav a:hover, #menu-drawer nav a.active {
        color: #f1cb33; }
    #menu-drawer nav strong, #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, #menu-drawer nav .cp-label.v2 span, .cp-label.v2 #menu-drawer nav span {
      color: #ffffff; }
    #menu-drawer nav ul.products > li a {
      display: block;
      width: 100%; }
    #menu-drawer nav ul.products ul {
      padding-top: 35px;
      padding-bottom: 35px;
      vertical-align: top;
      display: inline-block;
      width: 20%;
      max-width: 205px; }
      #menu-drawer nav ul.products ul:first-of-type {
        margin-left: 40px;
        position: relative; }
        #menu-drawer nav ul.products ul:first-of-type:before {
          border-left: 6px solid #f1cb33;
          content: " ";
          display: block;
          position: absolute;
          left: -40px;
          top: 6px;
          bottom: 7px; }
    #menu-drawer nav ul.tree li[data-depth="0"] {
      clear: both;
      height: 45px;
      overflow: hidden; }
      #menu-drawer nav ul.tree li[data-depth="0"] > a {
        display: block; }
    #menu-drawer nav ul.tree ul[data-depth="1"] {
      opacity: 0;
      max-width: none;
      display: block;
      margin-top: 35px;
      margin-bottom: 35px;
      vertical-align: top; }
      #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children {
        vertical-align: top;
        width: 20%;
        display: inline-block; }
        #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a {
          color: #ffffff; }
      #menu-drawer nav ul.tree ul[data-depth="1"] > li[data-depth="1"] {
        width: 20%; }
      #menu-drawer nav ul.tree ul[data-depth="1"] ul[data-depth="2"] {
        padding-bottom: 24px; }
      #menu-drawer nav ul.tree ul[data-depth="1"]:first-of-type {
        margin-left: 40px;
        position: relative; }
        #menu-drawer nav ul.tree ul[data-depth="1"]:first-of-type:before {
          border-left: 6px solid #f1cb33;
          content: " ";
          display: block;
          position: absolute;
          left: -40px;
          top: 6px;
          bottom: 7px; }

.cp-search-drawer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #eaeae3; }
  .cp-search-drawer .wrapp {
    min-height: 190px;
    background-color: #2b2b2b;
    padding-top: 32px;
    padding-left: 8px; }
  .cp-search-drawer strong, .cp-search-drawer #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, #menu-drawer nav ul.tree .cp-search-drawer ul[data-depth="1"] > li.has-children > a, .cp-search-drawer .cp-label.v2 span, .cp-label.v2 .cp-search-drawer span {
    color: #ffe000;
    display: block;
    padding-bottom: 16px; }
  .cp-search-drawer .field {
    padding-right: 8px;
    background-color: #242424 !important; }
  .cp-search-drawer input {
    background-color: #242424 !important;
    line-height: 64px;
    min-height: 64px;
    color: #939393;
    font-size: 18px;
    margin-right: 5px;
    text-overflow: ellipsis; }
    .cp-search-drawer input::-webkit-input-placeholder {
      color: #7b7b7b !important;
      line-height: 64px !important;
      min-height: 64px !important;
      text-overflow: ellipsis;
      font-size: 18px; }
    .cp-search-drawer input::-moz-placeholder {
      color: #7b7b7b !important;
      line-height: 64px !important;
      min-height: 64px !important;
      text-overflow: ellipsis;
      font-size: 18px; }
    .cp-search-drawer input:-moz-placeholder {
      color: #7b7b7b !important;
      line-height: 64px !important;
      min-height: 64px !important;
      text-overflow: ellipsis;
      font-size: 18px; }
    .cp-search-drawer input:-ms-input-placeholder {
      color: #7b7b7b !important;
      line-height: 64px !important;
      min-height: 64px !important;
      text-overflow: ellipsis;
      font-size: 18px; }
    .cp-search-drawer input::-moz-selection {
      background-color: #2b2b2b; }
    .cp-search-drawer input::selection {
      background-color: #2b2b2b; }
  .cp-search-drawer .desktop-recent-searches {
    max-width: 397px; }
    .cp-search-drawer .desktop-recent-searches div {
      margin-top: -8px;
      color: #b2b2b2; }
      .cp-search-drawer .desktop-recent-searches div a {
        line-height: 32px; }
  .cp-search-drawer .close {
    padding: 32px 38px;
    position: absolute;
    right: 0px;
    top: 0px; }
    .cp-search-drawer .close span {
      background-position: -433.1px -77px;
      width: 17.41px;
      height: 17.41px; }

.country-checker-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 1000;
  background-color: #eaeae3; }

.cp-country-checker {
  position: relative;
  height: 56px;
  background-color: #2b2b2b;
  color: #ffffff; }
  .cp-country-checker p {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 24px;
    right: 64px; }
    .cp-country-checker p a {
      color: #ffe000;
      text-decoration: underline;
      margin-left: 1em; }
  .cp-country-checker .close {
    padding: 16px 38px;
    position: absolute;
    right: 0px;
    top: 0px; }
    .cp-country-checker .close span {
      background-position: -433.1px -77px;
      width: 17.41px;
      height: 17.41px; }

body.country-check header {
  top: 56px;
  transition-delay: 0.5s; }

body.country-check .scrollable {
  padding-top: 56px;
  transition-delay: 0.5s; }

.cp-grid-overlay {
  display: block;
  position: absolute;
  background: url(/app/img/ui/12column-grid-overlay.png) repeat-y center top;
  height: 100%;
  width: 100%;
  z-index: 20;
  pointer-events: none; }

.cp-top-section {
  overflow: hidden;
  position: relative;
  padding-bottom: 88px;
  padding-top: calc(13% - 42px); }
  .cp-top-section.detail {
    padding-top: 9%;
    min-height: 750px; }
  .cp-top-section .full {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
    .cp-top-section .full > div {
      position: absolute;
      top: 0; }
  .cp-top-section.home {
    max-height: 665px;
    height: 85vh; }
    .cp-top-section.home .cp-captions {
      background-color: transparent;
      width: 43%; }
      .cp-top-section.home .cp-captions .dots {
        width: 100%;
        padding-left: 21px;
        float: left;
        text-align: left; }
        .cp-top-section.home .cp-captions .dots a {
          text-align: left; }
  .cp-top-section .full2 {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background-color: #f9f8f3; }
  .cp-top-section .g-bg-white-four {
    width: 47%;
    bottom: 0; }
  .cp-top-section .image {
    left: 40%;
    width: 60%;
    top: auto !important;
    bottom: 0 !important;
    overflow: hidden; }
    .cp-top-section .image img {
      display: block;
      max-width: none;
      max-height: 100%;
      height: auto;
      width: 100%; }
  .cp-top-section .image.image-gallery {
    height: 100%;
    width: 60%;
    margin-left: 40%;
    left: 0;
    background-color: #e4e4d9; }
    .cp-top-section .image.image-gallery img {
      width: auto; }
    .cp-top-section .image.image-gallery .swiper-wrapper {
      height: 100%;
      width: calc(100% + 2px);
      left: -1px; }
    .cp-top-section .image.image-gallery .swiper-slide {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-size: cover;
      background-position: center;
      overflow: hidden; }
      .cp-top-section .image.image-gallery .swiper-slide:first-of-type {
        opacity: 1; }
  .cp-top-section a {
    margin-top: 48px; }
  .cp-top-section .text {
    position: relative;
    z-index: 1;
    pointer-events: none; }
    .cp-top-section .text > * {
      pointer-events: auto; }
  .cp-top-section .download a {
    display: block;
    text-decoration: none;
    margin-top: 0; }
    .cp-top-section .download a span {
      text-decoration: underline; }
  .cp-top-section .download a.print {
    margin-top: 16px; }
  .cp-top-section .t-container {
    position: relative; }
    .cp-top-section .t-container .t-slide {
      position: absolute;
      opacity: 0; }
      .cp-top-section .t-container .t-slide:first-of-type {
        opacity: 1; }
  .cp-top-section .buttons .g-button {
    display: inline-block;
    width: auto; }
    .cp-top-section .buttons .g-button:not(:last-child) {
      margin-right: 64px; }

.cp-product-gallery {
  position: relative;
  padding: 0; }
  .cp-product-gallery .gc-3 .wrapp, .cp-product-gallery .gc-4 .wrapp {
    margin: 0 8px; }
  .cp-product-gallery .swiper-wrapper {
    padding: 0 8px;
    position: relative; }
  .cp-product-gallery img {
    width: 100%;
    height: auto; }

.cp-captions {
  background: #ffffff;
  position: absolute;
  bottom: 0;
  height: 72px;
  overflow: hidden;
  z-index: 1; }
  .cp-captions .items {
    width: 60%;
    float: left;
    padding-left: 16px;
    position: relative; }
    .cp-captions .items .text {
      position: absolute;
      left: 16px;
      top: 0;
      height: 72px;
      background-color: #ffffff;
      line-height: 72px;
      opacity: 0; }
      .cp-captions .items .text:first-of-type {
        opacity: 1; }
  .cp-captions .dots {
    width: 40%;
    float: right;
    text-align: right;
    padding-right: 16px; }
    .cp-captions .dots a {
      margin: 32px 5px 30px;
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #dddddd;
      border-radius: 10px;
      position: relative; }
      .cp-captions .dots a.active, .cp-captions .dots a:hover {
        background-color: #ffe000; }

.cp-swiper-gallery {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  /* Fix of Webkit flickering */
  z-index: 1;
  /* IE10 Windows Phone 8 Fixes */
  /* ===============================================================
	Your custom styles, here you need to specify container's and slide's
	sizes, pagination, etc.
	================================================================*/
  /* ===============================================================
	Pagination Styles
	================================================================*/ }
  .cp-swiper-gallery.gallery {
    left: 47%;
    width: 53%;
    position: absolute;
    background-color: #DEDED6;
    height: 100%;
    bottom: 0 !important;
    overflow: hidden; }
    .cp-swiper-gallery.gallery .item {
      position: relative; }
      .cp-swiper-gallery.gallery .item img {
        position: absolute;
        bottom: 72px;
        left: 0;
        width: auto;
        height: auto; }
  .cp-swiper-gallery.cfeature {
    left: 0;
    width: 100%;
    position: absolute;
    height: 100%;
    bottom: 0 !important;
    overflow: hidden; }
    .cp-swiper-gallery.cfeature .item {
      width: 484px;
      height: 596px;
      position: relative; }
      .cp-swiper-gallery.cfeature .item img {
        position: absolute;
        bottom: 56px;
        left: 0;
        width: 100%;
        height: auto; }
    .cp-swiper-gallery.cfeature .cp-captions {
      height: 56px; }
    .cp-swiper-gallery.cfeature .items .text {
      height: 56px;
      line-height: 56px; }
    .cp-swiper-gallery.cfeature .item .t-h2, .cp-swiper-gallery.cfeature .item .cp-search-drawer input, .cp-search-drawer .cp-swiper-gallery.cfeature .item input, .cp-swiper-gallery.cfeature .item .order-tracker-multi-screen-2.loading:after {
      position: absolute;
      bottom: 72px;
      z-index: 1;
      color: white;
      font-weight: normal;
      padding-bottom: 30px;
      margin-left: 12.5%;
      width: 62.5%; }
  .cp-swiper-gallery .swiper-wrapper {
    position: relative;
    width: 10000px;
    height: 100%;
    padding: 0;
    -webkit-transition-property: -webkit-transform, left, top;
    -webkit-transition-duration: 0s;
    -webkit-transform: translate3d(0px, 0, 0);
    -webkit-transition-timing-function: ease;
    -moz-transition-property: -moz-transform, left, top;
    -moz-transition-duration: 0s;
    -moz-transform: translate3d(0px, 0, 0);
    -moz-transition-timing-function: ease;
    -o-transition-property: -o-transform, left, top;
    -o-transition-duration: 0s;
    -o-transform: translate3d(0px, 0, 0);
    -o-transition-timing-function: ease;
    -o-transform: translate(0px, 0px);
    -ms-transition-property: -ms-transform, left, top;
    -ms-transition-duration: 0s;
    -ms-transform: translate3d(0px, 0, 0);
    -ms-transition-timing-function: ease;
    transition-property: transform, left, top;
    transition-duration: 0s;
    transform: translate3d(0px, 0, 0);
    transition-timing-function: ease; }
  .cp-swiper-gallery .swiper-free-mode > .swiper-wrapper {
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    -ms-transition-timing-function: ease-out;
    -o-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .cp-swiper-gallery .swiper-slide {
    float: left;
    height: 100%; }
  .cp-swiper-gallery .swiper-wp8-horizontal {
    -ms-touch-action: pan-y; }
  .cp-swiper-gallery .swiper-wp8-vertical {
    -ms-touch-action: pan-x; }
  .cp-swiper-gallery .swiper-container {
    /* Specify Swiper's Size: */
    /*width:200px;
		height: 100px;*/ }
  .cp-swiper-gallery .swiper-slide {
    /* Specify Slides's Size: */
    /*width: 100%;
		height: 100%;*/ }
  .cp-swiper-gallery .swiper-slide-active {
    /* Specific active slide styling: */ }
  .cp-swiper-gallery .swiper-slide-visible {
    /* Specific visible slide styling: */ }
  .cp-swiper-gallery .swiper-pagination-switch {
    /* Stylize pagination button: */ }
  .cp-swiper-gallery .swiper-active-switch {
    /* Specific active button style: */ }
  .cp-swiper-gallery .swiper-visible-switch {
    /* Specific visible button style: */ }

.cp-breadcrumb {
  position: relative;
  padding-top: 48px;
  padding-bottom: 64px;
  z-index: 1;
  margin-left: 21px; }
  .cp-breadcrumb a:before {
    content: " - ";
    display: inline-block;
    margin-right: 5px;
    margin-left: 1px; }
  .cp-breadcrumb a:first-of-type:before {
    display: none; }

.cp-label {
  background-color: #ffe000;
  display: inline-block;
  padding: 5px 7px 3px 5px;
  line-height: 16px;
  vertical-align: middle;
  margin-bottom: 16px;
  overflow: hidden; }
  .cp-label a:before {
    content: " - ";
    display: inline-block;
    margin-right: 5px;
    margin-left: 1px; }
  .cp-label a:first-of-type:before {
    display: none; }
  .cp-label a:last-of-type:after {
    content: " - ";
    display: inline-block;
    margin-right: 1px;
    margin-left: 5px; }
  .cp-label.v2 {
    padding: 0; }
    .cp-label.v2 span {
      display: inline-block;
      padding: 5px 7px 3px 5px; }

.cp-text-top a.g-button {
  display: inline-block;
  width: auto; }
  .cp-text-top a.g-button:last-of-type {
    margin-left: 100px; }
  .cp-text-top a.g-button:first-of-type {
    margin-left: 0; }

.cp-text-top h1 small {
  display: block;
  margin-top: 16px; }

[class*=" cp-line-"] {
  position: relative; }
  [class*=" cp-line-"]:before {
    position: absolute;
    top: 12px;
    bottom: 12px;
    display: block;
    width: 8px;
    left: -30px;
    content: " "; }
  [class*=" cp-line-"].cp-line-inset {
    margin-left: 30px; }
  [class*=" cp-line-"].cp-line-zero:before {
    top: 0;
    bottom: 0; }
  [class*=" cp-line-"].cp-line-white:before {
    background-color: #ffffff; }
  [class*=" cp-line-"].cp-line-yellow:before {
    background-color: #ffe000; }

.cp-block {
  margin-top: 16px; }
  .cp-block:first-of-type {
    margin-top: 0; }
  .cp-block .g-button.alt {
    display: inline-block;
    width: auto;
    padding-top: 40px;
    padding-left: 0; }
    .cp-block .g-button.alt > span {
      margin-left: 0; }
  .cp-block h1 {
    margin-bottom: 16px; }
  .cp-block p {
    margin-bottom: 8px; }
  .cp-block.open form {
    display: block; }
  .cp-block .collapsible {
    display: block;
    padding-top: 24px; }
  .cp-block a {
    text-decoration: underline; }

.cp-cta {
  border-radius: 100px;
  background-color: #ffe000;
  display: block;
  height: 100px;
  width: 100px;
  position: relative; }
  .cp-cta > span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .cp-cta > span .text {
      display: block;
      text-align: center;
      line-height: 20px; }
    .cp-cta > span .plus {
      margin: 10px auto 0;
      position: relative;
      display: block;
      width: 11px;
      height: 11px; }
      .cp-cta > span .plus:before, .cp-cta > span .plus:after {
        position: absolute;
        content: " ";
        display: block;
        background-color: #242424; }
      .cp-cta > span .plus:before {
        width: 11px;
        height: 1px;
        top: 5px;
        left: 0; }
      .cp-cta > span .plus:after {
        width: 1px;
        height: 11px;
        top: 0;
        left: 5px; }

.gc-3.list {
  position: relative;
  float: right; }

.select-field {
  height: 42px;
  overflow: visible !important; }
  .select-field:before {
    display: none; }
  .select-field .cp-filter-list {
    left: 0;
    right: 0; }
    .select-field .cp-filter-list .active {
      background-color: #f1f1eb;
      color: #242424; }
      .select-field .cp-filter-list .active:after {
        border-color: #242424 transparent transparent transparent; }
    .select-field .cp-filter-list ul {
      max-height: 50vh;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #e4e4d9; }
      .select-field .cp-filter-list ul li.selected {
        font-weight: bold; }
      .select-field .cp-filter-list ul li a {
        color: #242424 !important;
        text-decoration: none; }
      .select-field .cp-filter-list ul li a:hover {
        background-color: #f1f1eb; }

.cp-filter-list {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 8px;
  right: 8px;
  display: inline-block;
  color: #ffffff;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0; }
  .cp-filter-list div.active {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    background-color: #2b2b2b;
    line-height: 40px;
    cursor: pointer;
    position: relative; }
    .cp-filter-list div.active span.filter-count {
      color: #ffe000; }
    .cp-filter-list div.active:after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color: #ffffff transparent transparent transparent;
      display: block;
      right: 16px;
      top: 50%;
      margin-top: -2px; }
  .cp-filter-list ul {
    padding: 50px 0 10px;
    width: 100%;
    background-color: #242424;
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    cursor: pointer; }
    .cp-filter-list ul li {
      opacity: 0;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }
      .cp-filter-list ul li a {
        display: block;
        line-height: 40px;
        position: relative;
        padding-left: 16px;
        padding-right: 16px; }
        .cp-filter-list ul li a span {
          position: absolute;
          right: 16px;
          color: #ffe000; }
        .cp-filter-list ul li a:hover {
          background-color: #2b2b2b; }

.draggable {
  position: absolute;
  width: 90%;
  height: 6px;
  background-color: #f1f1eb;
  bottom: 35px;
  left: 5%; }
  .draggable .handle {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 4px solid #f1cb33;
    background-color: #ffffff;
    top: -7px; }

.cp-category-summary {
  padding-bottom: 47.2024415056%;
  position: relative;
  margin-bottom: 56px;
  background-color: #f9f8f3; }
  .cp-category-summary div.left, .cp-category-summary div.right {
    position: absolute;
    height: 100%;
    top: 0; }
  .cp-category-summary div.left {
    padding: 56px 96px 48px 88px;
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*.g-button{
			position:absolute;
			bottom:vSize(6);

		}*/ }
    .cp-category-summary div.left .g-button {
      margin-top: auto; }
  .cp-category-summary div.right {
    right: 0;
    padding-left: 8px; }
    .cp-category-summary div.right > div {
      background-color: #DEDED6;
      height: 100%; }
  .cp-category-summary .cp-label {
    margin-bottom: 8px; }
  .cp-category-summary .t-h2, .cp-category-summary .cp-search-drawer input, .cp-search-drawer .cp-category-summary input, .cp-category-summary .order-tracker-multi-screen-2.loading:after {
    margin-bottom: 16px; }
  .cp-category-summary .bar {
    position: absolute;
    z-index: 10;
    height: 8px;
    background-color: #ffe000;
    width: 100%;
    left: 100%;
    bottom: 72px;
    -webkit-backface-visibility: hidden;
    /** prevent ios flickers **/
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translateX(-30%);
    -moz-transform: translateX(-30%);
    -ms-transform: translateX(-30%);
    -o-transform: translateX(-30%);
    transform: translateX(-30%); }
  .cp-category-summary div.item {
    text-align: center; }
    .cp-category-summary div.item img {
      height: auto; }

.survey-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  opacity: 0;
  visibility: hidden; }
  .survey-overlay:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-color: #0e0e0e;
    opacity: 0.6; }
  .survey-overlay .close {
    position: absolute;
    right: 38px;
    top: 24px;
    cursor: pointer; }
    .survey-overlay .close span {
      background-position: -433.1px -77px;
      width: 17.41px;
      height: 17.41px; }

.survey-overlay-content {
  position: relative;
  width: 100%;
  background-color: #eeede4;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }
  .survey-overlay-content .button-wrapper {
    padding-top: 16px; }

@media (max-width: 767px) {
  .survey-overlay-content {
    background-image: none !important; } }

.order-tracker {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .order-tracker > div {
    float: none;
    flex-grow: 0;
    margin-bottom: 16px; }

.order-tracker-line-item {
  background-color: #ffffff;
  height: 100%;
  padding: 42px 64px 32px 85px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column; }
  .order-tracker-line-item .order-tracker-line-item-top {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-positive: 1; }
    .order-tracker-line-item .order-tracker-line-item-top h2 sup {
      width: 11px;
      height: 11px;
      border-radius: 11px;
      line-height: 11px;
      display: inline-block;
      margin-left: 8px; }
      .order-tracker-line-item .order-tracker-line-item-top h2 sup.success {
        background-color: #00ea84; }
      .order-tracker-line-item .order-tracker-line-item-top h2 sup.error {
        background-color: #ff004e; }
      .order-tracker-line-item .order-tracker-line-item-top h2 sup.warning {
        background-color: #ffe000; }
  .order-tracker-line-item .order-tracker-line-item-middle {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    flex-shrink: 0;
    -ms-flex-negative: 0; }
    .order-tracker-line-item .order-tracker-line-item-middle p {
      margin-bottom: 0px; }
    .order-tracker-line-item .order-tracker-line-item-middle strong, .order-tracker-line-item .order-tracker-line-item-middle #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, #menu-drawer nav ul.tree .order-tracker-line-item .order-tracker-line-item-middle ul[data-depth="1"] > li.has-children > a, .order-tracker-line-item .order-tracker-line-item-middle .cp-label.v2 span, .cp-label.v2 .order-tracker-line-item .order-tracker-line-item-middle span {
      width: 90px;
      display: inline-block; }
  .order-tracker-line-item .order-tracker-line-item-bottom {
    margin-top: auto; }

.p-home-page main {
  overflow: hidden; }
  .p-home-page main .home {
    z-index: 1; }
  .p-home-page main .gl-container, .p-home-page main .cp-top-section .t-container, .cp-top-section .p-home-page main .t-container {
    position: relative; }
  .p-home-page main .isotope {
    position: relative;
    padding: 0 !important; }
    .p-home-page main .isotope .tile {
      padding: 0 8px; }
  .p-home-page main .awesome-bg-image {
    position: absolute;
    top: -8px;
    left: -47px; }
    .p-home-page main .awesome-bg-image img {
      max-width: initial; }
  .p-home-page main .b-feature {
    position: relative;
    clear: both; }
    .p-home-page main .b-feature a.g-button {
      margin-top: 16px; }
  .p-home-page main .tiles {
    position: relative;
    width: 100%; }
  .p-home-page main .manual {
    position: relative;
    padding-bottom: 100%;
    margin-bottom: 16px; }
    .p-home-page main .manual a.cp-cta {
      position: absolute;
      left: calc(25% - 32px);
      top: 25%;
      width: 124px;
      height: 124px;
      font-family: TVNordEF-Bold, Arial, sans-serif;
      font-style: normal;
      font-weight: normal; }
      .p-home-page main .manual a.cp-cta > span {
        min-height: 62px; }
    .p-home-page main .manual img {
      width: 100%;
      height: auto;
      max-width: 650px;
      position: absolute; }
  .p-home-page main .tiles .gc-8 .cp-module {
    padding-bottom: 100%; }
  .p-home-page main .tiles .gc-4 .cp-module {
    padding-bottom: 100%; }

.p-product main .cp-top-section {
  padding-top: 0;
  padding-bottom: 40px;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  box-direction: normal;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify; }
  .p-product main .cp-top-section .t-h1 p.product-code {
    color: #b2b2b2; }
  .p-product main .cp-top-section .bar {
    position: absolute;
    z-index: 10;
    height: 8px;
    background-color: #ffe000;
    width: 100%;
    left: 100%;
    bottom: 72px;
    -webkit-backface-visibility: hidden;
    /** prevent ios flickers **/
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translateX(-30%);
    -moz-transform: translateX(-30%);
    -ms-transform: translateX(-30%);
    -o-transform: translateX(-30%);
    transform: translateX(-30%); }
  .p-product main .cp-top-section .specs.no-body {
    margin-top: -32px; }

.p-product main .insitue {
  position: relative; }
  .p-product main .insitue .images {
    background: #e4e4d9;
    width: 100%;
    overflow: hidden;
    max-height: 575px;
    height: 575px;
    margin-bottom: -72px; }
    .p-product main .insitue .images .swiper-wrapper {
      width: 10000px; }
    .p-product main .insitue .images div {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover; }
  .p-product main .insitue img {
    display: block; }
  .p-product main .insitue .cp-captions {
    position: relative; }

.p-product main .stockists .right {
  position: relative; }
  .p-product main .stockists .right img {
    position: absolute;
    right: 0;
    top: 0;
    width: 400px; }

.p-product main .stockists img {
  width: 100%;
  height: auto; }

.p-product main section > .gl-container .gl-container, .p-product main .cp-top-section section > .t-container .gl-container, .cp-top-section .p-product main section > .t-container .gl-container, .p-product main section > .gl-container .cp-top-section .t-container, .cp-top-section .p-product main section > .gl-container .t-container, .p-product main .cp-top-section section > .t-container .t-container, .cp-top-section .p-product main section > .t-container .t-container {
  padding-top: 16px; }

.p-product main .specifications {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0; }
  .p-product main .specifications a.close {
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    height: 45px;
    margin: 0;
    z-index: 1; }
  .p-product main .specifications .gc-12 {
    position: relative; }
  .p-product main .specifications ul {
    position: relative;
    padding-left: 18px; }
    .p-product main .specifications ul:before {
      position: absolute;
      content: "";
      top: 5px;
      bottom: 13px;
      left: 0;
      border-left: 4px solid #ffe000; }
    .p-product main .specifications ul li {
      position: relative;
      padding-left: 20px;
      padding-bottom: 8px; }
      .p-product main .specifications ul li:before {
        position: absolute;
        content: "-";
        display: block;
        left: 0; }

.p-product main .video-embed {
  position: relative;
  cursor: pointer; }
  .p-product main .video-embed .img {
    width: 100%;
    text-align: center;
    background-color: #DEDED6;
    position: relative; }
    .p-product main .video-embed .img:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      content: '';
      background-color: #242424;
      opacity: 0.3; }
  .p-product main .video-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    visibility: hidden;
    opacity: 0; }
  .p-product main .video-embed:hover .video-embed-label {
    opacity: 0.7; }

.p-product main .video-embed-label {
  position: absolute;
  bottom: 46px;
  left: 54px;
  color: #ffffff;
  font-size: 18px;
  line-height: 54px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  /** prevent ios flickers **/
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s; }
  .p-product main .video-embed-label span {
    width: 54px;
    height: 54px;
    border: 2px solid #ffffff;
    border-radius: 26px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 26px;
    position: relative; }
    .p-product main .video-embed-label span:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      display: block;
      margin-left: 2px;
      width: 10px;
      height: 0px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 12px solid #ffffff; }

.p-wheretobuy main {
  position: relative; }
  .p-wheretobuy main .bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
    height: 100%;
    background-color: #242424;
    background-position: center; }
  .p-wheretobuy main .cp-text-top h1 {
    margin-bottom: 96px; }
  .p-wheretobuy main .gl-container input, .p-wheretobuy main .cp-top-section .t-container input, .cp-top-section .p-wheretobuy main .t-container input, .p-wheretobuy main .gl-container select, .p-wheretobuy main .cp-top-section .t-container select, .cp-top-section .p-wheretobuy main .t-container select, .p-wheretobuy main .gl-container button, .p-wheretobuy main .cp-top-section .t-container button, .cp-top-section .p-wheretobuy main .t-container button {
    line-height: 64px;
    font-size: 18px;
    min-height: 64px; }
    .p-wheretobuy main .gl-container input::-webkit-input-placeholder, .p-wheretobuy main .cp-top-section .t-container input::-webkit-input-placeholder, .cp-top-section .p-wheretobuy main .t-container input::-webkit-input-placeholder, .p-wheretobuy main .gl-container select::-webkit-input-placeholder, .p-wheretobuy main .cp-top-section .t-container select::-webkit-input-placeholder, .cp-top-section .p-wheretobuy main .t-container select::-webkit-input-placeholder, .p-wheretobuy main .gl-container button::-webkit-input-placeholder, .p-wheretobuy main .cp-top-section .t-container button::-webkit-input-placeholder, .cp-top-section .p-wheretobuy main .t-container button::-webkit-input-placeholder {
      line-height: 64px; }
    .p-wheretobuy main .gl-container input::-moz-placeholder, .p-wheretobuy main .cp-top-section .t-container input::-moz-placeholder, .cp-top-section .p-wheretobuy main .t-container input::-moz-placeholder, .p-wheretobuy main .gl-container select::-moz-placeholder, .p-wheretobuy main .cp-top-section .t-container select::-moz-placeholder, .cp-top-section .p-wheretobuy main .t-container select::-moz-placeholder, .p-wheretobuy main .gl-container button::-moz-placeholder, .p-wheretobuy main .cp-top-section .t-container button::-moz-placeholder, .cp-top-section .p-wheretobuy main .t-container button::-moz-placeholder {
      line-height: 64px; }
    .p-wheretobuy main .gl-container input:-moz-placeholder, .p-wheretobuy main .cp-top-section .t-container input:-moz-placeholder, .cp-top-section .p-wheretobuy main .t-container input:-moz-placeholder, .p-wheretobuy main .gl-container select:-moz-placeholder, .p-wheretobuy main .cp-top-section .t-container select:-moz-placeholder, .cp-top-section .p-wheretobuy main .t-container select:-moz-placeholder, .p-wheretobuy main .gl-container button:-moz-placeholder, .p-wheretobuy main .cp-top-section .t-container button:-moz-placeholder, .cp-top-section .p-wheretobuy main .t-container button:-moz-placeholder {
      line-height: 64px; }
    .p-wheretobuy main .gl-container input:-ms-input-placeholder, .p-wheretobuy main .cp-top-section .t-container input:-ms-input-placeholder, .cp-top-section .p-wheretobuy main .t-container input:-ms-input-placeholder, .p-wheretobuy main .gl-container select:-ms-input-placeholder, .p-wheretobuy main .cp-top-section .t-container select:-ms-input-placeholder, .cp-top-section .p-wheretobuy main .t-container select:-ms-input-placeholder, .p-wheretobuy main .gl-container button:-ms-input-placeholder, .p-wheretobuy main .cp-top-section .t-container button:-ms-input-placeholder, .cp-top-section .p-wheretobuy main .t-container button:-ms-input-placeholder {
      line-height: 64px; }
  .p-wheretobuy main .gl-container select, .p-wheretobuy main .cp-top-section .t-container select, .cp-top-section .p-wheretobuy main .t-container select {
    height: 64px; }
  .p-wheretobuy main .gl-container h1, .p-wheretobuy main .cp-top-section .t-container h1, .cp-top-section .p-wheretobuy main .t-container h1 {
    -webkit-backface-visibility: hidden;
    /** prevent ios flickers **/
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s; }
  .p-wheretobuy main .gl-container form, .p-wheretobuy main .cp-top-section .t-container form, .cp-top-section .p-wheretobuy main .t-container form {
    display: block;
    position: relative; }
    .p-wheretobuy main .gl-container form:before, .p-wheretobuy main .cp-top-section .t-container form:before, .cp-top-section .p-wheretobuy main .t-container form:before {
      -webkit-backface-visibility: hidden;
      /** prevent ios flickers **/
      -webkit-transition-property: transform, opacity;
      -moz-transition-property: transform, opacity;
      transition-property: transform, opacity;
      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      transition-duration: 0.5s;
      -webkit-transition-timing-function: ease-out;
      -moz-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-transform: translate(-33px, 200%);
      -moz-transform: translate(-33px, 200%);
      -ms-transform: translate(-33px, 200%);
      -o-transform: translate(-33px, 200%);
      transform: translate(-33px, 200%);
      opacity: 0;
      content: ' ';
      width: 8px;
      top: 0;
      bottom: 0;
      position: absolute;
      background-color: #ffe000;
      display: block; }
  .p-wheretobuy main .gl-container .line h1, .p-wheretobuy main .cp-top-section .t-container .line h1, .cp-top-section .p-wheretobuy main .t-container .line h1 {
    -webkit-transform: translateX(-33px);
    -moz-transform: translateX(-33px);
    -ms-transform: translateX(-33px);
    -o-transform: translateX(-33px);
    transform: translateX(-33px); }
  .p-wheretobuy main .gl-container .line form:before, .p-wheretobuy main .cp-top-section .t-container .line form:before, .cp-top-section .p-wheretobuy main .t-container .line form:before {
    -webkit-transform: translate(-33px, 0);
    -moz-transform: translate(-33px, 0);
    -ms-transform: translate(-33px, 0);
    -o-transform: translate(-33px, 0);
    transform: translate(-33px, 0);
    opacity: 1; }
  .p-wheretobuy main .gl-container .buttons a, .p-wheretobuy main .cp-top-section .t-container .buttons a, .cp-top-section .p-wheretobuy main .t-container .buttons a {
    display: inline-block;
    width: auto;
    margin-right: 100px;
    padding-bottom: 18px; }
    .p-wheretobuy main .gl-container .buttons a:last-of-type, .p-wheretobuy main .cp-top-section .t-container .buttons a:last-of-type, .cp-top-section .p-wheretobuy main .t-container .buttons a:last-of-type {
      margin-right: 0; }
  .p-wheretobuy main .electrician .filter-type, .p-wheretobuy main .electrician .cp-filter-list {
    display: none; }
  .p-wheretobuy main .cp-block {
    margin-bottom: 18px; }
    .p-wheretobuy main .cp-block:after {
      display: block;
      content: "";
      clear: both; }
    .p-wheretobuy main .cp-block .cp-label {
      margin-bottom: 0; }
    .p-wheretobuy main .cp-block h1 {
      margin-bottom: 8px; }
    .p-wheretobuy main .cp-block .g-button {
      padding-top: 30px; }
  .p-wheretobuy main .filter .gc-3 {
    position: relative; }
  .p-wheretobuy main .filter .t-h3, .p-wheretobuy .p-contact main .filter .content-overlay div, .p-contact .p-wheretobuy main .filter .content-overlay div, .p-wheretobuy .p-contact main .content-overlay .filter div, .p-contact .p-wheretobuy main .content-overlay .filter div {
    line-height: 40px; }

.p-wheretobuy footer .wrapp {
  margin-top: 0; }

.p-about main .container {
  position: relative;
  top: -112px; }

.p-about main .wysiwyg a:not(.g-button) {
  text-decoration: underline; }

.p-about main .wysiwyg a.g-button {
  margin-top: 48px; }

.p-about main .history {
  padding-left: 0;
  padding-right: 0;
  background-color: #ffffff;
  position: relative; }
  .p-about main .history .gc-6 {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    padding-bottom: 50%; }
  .p-about main .history .images {
    overflow: hidden;
    position: absolute;
    width: 100%;
    padding-bottom: 100%;
    background-color: #e4e4d9; }
    .p-about main .history .images div {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      opacity: 0;
      height: 100%;
      width: 100%; }
      .p-about main .history .images div:first-of-type {
        opacity: 1; }
      .p-about main .history .images div img {
        width: 100%;
        height: auto;
        display: block; }
  .p-about main .history > a.g-button {
    position: absolute;
    display: none;
    top: 50%;
    width: 45px;
    margin: 0;
    padding: 0;
    margin-top: -22px; }
    .p-about main .history > a.g-button .circle {
      margin: 0;
      display: block; }
    .p-about main .history > a.g-button.left {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      left: 16px; }
    .p-about main .history > a.g-button.right {
      right: 16px; }
  .p-about main .history .slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-right: 1px solid #f1f1eb; }
    .p-about main .history .slides .slide {
      position: absolute;
      background-color: #ffffff;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0; }
      .p-about main .history .slides .slide:first-of-type {
        opacity: 1; }
      .p-about main .history .slides .slide div.year {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 140px;
        color: #f1f1eb; }
      .p-about main .history .slides .slide .bottom {
        padding: 10px 34px;
        position: absolute;
        bottom: 45px; }
        .p-about main .history .slides .slide .bottom a {
          text-decoration: underline; }

.p-help main .select .center-col, .p-search main .select .center-col {
  overflow: visible; }

.p-help main .cp-text-top .cp-filter-list .active, .p-search main .cp-text-top .cp-filter-list .active {
  background-color: #ffffff; }

.p-help main .center-col, .p-search main .center-col {
  position: relative;
  overflow: hidden; }
  .p-help main .center-col .collapsible, .p-search main .center-col .collapsible {
    position: absolute;
    width: 100%; }

.p-help main .collapsible, .p-search main .collapsible {
  opacity: 0;
  visibility: hidden; }

.p-help main .filesize, .p-search main .filesize {
  margin-top: 16px;
  text-transform: lowercase; }

.p-help main .downloads, .p-search main .downloads {
  padding-left: 16px;
  padding-right: 16px; }
  .p-help main .downloads .gc-2 .g-button, .p-search main .downloads .gc-2 .g-button {
    padding-top: 20px; }

.p-contact main .cp-text-top {
  z-index: 21;
  position: relative; }

.p-contact main #offices .gl-container, .p-contact main #offices .cp-top-section .t-container, .cp-top-section .p-contact main #offices .t-container {
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: wrap;
  -moz-flex-flow: wrap;
  flex-flow: wrap; }
  .p-contact main #offices .gl-container h2, .p-contact main #offices .cp-top-section .t-container h2, .cp-top-section .p-contact main #offices .t-container h2 {
    margin-bottom: 16px; }
  .p-contact main #offices .gl-container .adr, .p-contact main #offices .cp-top-section .t-container .adr, .cp-top-section .p-contact main #offices .t-container .adr {
    margin-bottom: 24px; }
  .p-contact main #offices .gl-container .cp-label, .p-contact main #offices .cp-top-section .t-container .cp-label, .cp-top-section .p-contact main #offices .t-container .cp-label {
    margin-bottom: 8px; }

.p-contact main #map {
  height: 465px; }

.p-contact main .map-container {
  position: relative; }
  .p-contact main .map-container .overlay {
    cursor: pointer;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 36, 0.6); }
    .p-contact main .map-container .overlay div {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -26px;
      margin-left: -26px;
      line-height: 53px;
      color: #ffffff; }
      .p-contact main .map-container .overlay div span {
        position: absolute;
        left: -70px; }

.p-contact main .map-marker {
  width: 50px;
  height: 50px;
  display: block;
  position: absolute;
  background-color: #ffe000;
  border-radius: 50px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -o-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-backface-visibility: hidden;
  /** prevent ios flickers **/
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
  -moz-transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
  transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  cursor: pointer;
  z-index: 10; }
  .p-contact main .map-marker div {
    width: 11px;
    height: 11px;
    position: absolute;
    top: 20px;
    left: 20px;
    -webkit-backface-visibility: hidden;
    /** prevent ios flickers **/
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-timing-function: ease-out;
    -moz-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s; }
    .p-contact main .map-marker div:before, .p-contact main .map-marker div:after {
      -webkit-backface-visibility: hidden;
      /** prevent ios flickers **/
      -webkit-transition-property: opacity;
      -moz-transition-property: opacity;
      transition-property: opacity;
      -webkit-transition-duration: 0.2s;
      -moz-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-timing-function: 0.5s;
      -moz-transition-timing-function: 0.5s;
      transition-timing-function: 0.5s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      position: absolute;
      content: " ";
      display: block;
      background-color: #242424;
      opacity: 0; }
    .p-contact main .map-marker div:before {
      width: 11px;
      height: 1px;
      top: 5px;
      left: 0; }
    .p-contact main .map-marker div:after {
      width: 1px;
      height: 11px;
      top: 0;
      left: 5px; }
  .p-contact main .map-marker:hover {
    z-index: 20;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
    .p-contact main .map-marker:hover div:before, .p-contact main .map-marker:hover div:after {
      opacity: 1; }

.p-contact main .map-overlay {
  width: 233px;
  background-color: #ffe000;
  position: absolute;
  padding: 16px; }
  .p-contact main .map-overlay h2 {
    margin-bottom: 16px;
    font-size: 15px; }
  .p-contact main .map-overlay .cp-label {
    display: none; }
  .p-contact main .map-overlay .adr {
    margin-bottom: 24px; }
  .p-contact main .map-overlay .cp-label {
    margin-bottom: 8px; }
  .p-contact main .map-overlay:before {
    position: absolute;
    content: " ";
    bottom: -14px;
    left: 50%;
    margin-left: -12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 12px 0 12px;
    border-color: #ffe000 transparent transparent transparent; }
  .p-contact main .map-overlay a {
    text-decoration: underline; }
  .p-contact main .map-overlay .directions {
    margin-top: 16px;
    display: block !important;
    float: left; }
  .p-contact main .map-overlay .tel, .p-contact main .map-overlay .fax, .p-contact main .map-overlay .email {
    display: none; }
  .p-contact main .map-overlay .back {
    margin-top: 16px;
    display: block !important;
    float: right; }

.p-contact main textarea {
  min-height: 146px; }

.p-contact main .last-row {
  margin-top: 16px; }
  .p-contact main .last-row .gc-6:first-of-type {
    padding-top: 12px; }

.p-contact main .contact-form {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20; }
  .p-contact main .contact-form .wrapp {
    background-color: #f1f1eb;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%); }

.p-contact main .content-overlay {
  opacity: 0;
  display: none;
  cursor: pointer;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(36, 36, 36, 0.6); }
  .p-contact main .content-overlay div {
    padding-top: 60px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: -26px;
    margin-left: -26px;
    line-height: 53px;
    color: #ffffff; }
    .p-contact main .content-overlay div a {
      border-bottom: 2px solid #ffe000; }

.p-category main div.image {
  height: 100%;
  width: 60%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom; }

.p-category main .cp-module {
  padding-bottom: 120%;
  background-color: #e4e4d9; }
  .p-category main .cp-module img {
    width: 100%;
    height: auto; }

.p-category main .breaker {
  clear: both;
  display: block;
  margin-bottom: 0;
  width: 100%; }

.p-search main > .gl-container, .p-search .cp-top-section main > .t-container, .cp-top-section .p-search main > .t-container {
  position: relative; }

.order-tracker-multi header, .order-tracker-multi footer {
  display: none; }

.order-tracker-multi .scrollable {
  margin-top: 0px !important;
  overflow: hidden; }

.order-tracker-multi main {
  max-width: initial;
  width: 100%;
  height: 100vh;
  background: #eaeae8;
  color: #2b2b2b; }

.order-tracker-multi .gl-container, .order-tracker-multi .cp-top-section .t-container, .cp-top-section .order-tracker-multi .t-container {
  padding: 0 8px;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px; }
  .order-tracker-multi .gl-container.full, .order-tracker-multi .cp-top-section .full.t-container, .cp-top-section .order-tracker-multi .full.t-container {
    max-width: 100%; }
  .order-tracker-multi .gl-container:after, .order-tracker-multi .cp-top-section .t-container:after, .cp-top-section .order-tracker-multi .t-container:after {
    display: block;
    content: ' ';
    clear: both; }
  .order-tracker-multi .gl-container .gl-container, .order-tracker-multi .cp-top-section .t-container .gl-container, .cp-top-section .order-tracker-multi .t-container .gl-container, .order-tracker-multi .gl-container .cp-top-section .t-container, .cp-top-section .order-tracker-multi .gl-container .t-container, .order-tracker-multi .cp-top-section .t-container .t-container, .cp-top-section .order-tracker-multi .t-container .t-container {
    margin-left: -16px !important;
    margin-right: -16px !important;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0; }
  .order-tracker-multi .gl-container > *, .order-tracker-multi .cp-top-section .t-container > *, .cp-top-section .order-tracker-multi .t-container > * {
    padding: 0 8px;
    width: 100%; }
  .order-tracker-multi .gl-container > .gc-full, .order-tracker-multi .cp-top-section .t-container > .gc-full, .cp-top-section .order-tracker-multi .t-container > .gc-full {
    width: 100% !important; }
  .order-tracker-multi .gl-container .gc-right, .order-tracker-multi .cp-top-section .t-container .gc-right, .cp-top-section .order-tracker-multi .t-container .gc-right {
    float: right; }
  .order-tracker-multi .gl-container .gc-left, .order-tracker-multi .cp-top-section .t-container .gc-left, .cp-top-section .order-tracker-multi .t-container .gc-left {
    float: left; }

.order-tracker-multi .gc-1 {
  width: 6.25%;
  float: left; }

.order-tracker-multi .gcp-1 {
  margin-left: 6.25%; }

.order-tracker-multi .gc-2 {
  width: 12.5%;
  float: left; }

.order-tracker-multi .gcp-2 {
  margin-left: 12.5%; }

.order-tracker-multi .gc-3 {
  width: 18.75%;
  float: left; }

.order-tracker-multi .gcp-3 {
  margin-left: 18.75%; }

.order-tracker-multi .gc-4 {
  width: 25%;
  float: left; }

.order-tracker-multi .gcp-4 {
  margin-left: 25%; }

.order-tracker-multi .gc-5 {
  width: 31.25%;
  float: left; }

.order-tracker-multi .gcp-5 {
  margin-left: 31.25%; }

.order-tracker-multi .gc-6 {
  width: 37.5%;
  float: left; }

.order-tracker-multi .gcp-6 {
  margin-left: 37.5%; }

.order-tracker-multi .gc-7 {
  width: 43.75%;
  float: left; }

.order-tracker-multi .gcp-7 {
  margin-left: 43.75%; }

.order-tracker-multi .gc-8 {
  width: 50%;
  float: left; }

.order-tracker-multi .gcp-8 {
  margin-left: 50%; }

.order-tracker-multi .gc-9 {
  width: 56.25%;
  float: left; }

.order-tracker-multi .gcp-9 {
  margin-left: 56.25%; }

.order-tracker-multi .gc-10 {
  width: 62.5%;
  float: left; }

.order-tracker-multi .gcp-10 {
  margin-left: 62.5%; }

.order-tracker-multi .gc-11 {
  width: 68.75%;
  float: left; }

.order-tracker-multi .gcp-11 {
  margin-left: 68.75%; }

.order-tracker-multi .gc-12 {
  width: 75%;
  float: left; }

.order-tracker-multi .gcp-12 {
  margin-left: 75%; }

.order-tracker-multi .gc-13 {
  width: 81.25%;
  float: left; }

.order-tracker-multi .gcp-13 {
  margin-left: 81.25%; }

.order-tracker-multi .gc-14 {
  width: 87.5%;
  float: left; }

.order-tracker-multi .gcp-14 {
  margin-left: 87.5%; }

.order-tracker-multi .gc-15 {
  width: 93.75%;
  float: left; }

.order-tracker-multi .gcp-15 {
  margin-left: 93.75%; }

.order-tracker-multi .gc-16 {
  width: 100%;
  float: left; }

.order-tracker-multi .gcp-16 {
  margin-left: 100%; }

@media (min-width: 1024px) {
  .order-tracker-multi .gc-1 {
    width: 6.25%; }
  .order-tracker-multi .gc-2 {
    width: 12.5%; }
  .order-tracker-multi .gc-3 {
    width: 18.75%; }
  .order-tracker-multi .gc-4 {
    width: 25%; }
  .order-tracker-multi .gc-5 {
    width: 31.25%; }
  .order-tracker-multi .gc-6 {
    width: 37.5%; }
  .order-tracker-multi .gc-7 {
    width: 43.75%; }
  .order-tracker-multi .gc-8 {
    width: 50%; }
  .order-tracker-multi .gc-9 {
    width: 56.25%; }
  .order-tracker-multi .gc-10 {
    width: 62.5%; }
  .order-tracker-multi .gc-11 {
    width: 68.75%; }
  .order-tracker-multi .gc-12 {
    width: 75%; }
  .order-tracker-multi .gc-13 {
    width: 81.25%; }
  .order-tracker-multi .gc-14 {
    width: 87.5%; }
  .order-tracker-multi .gc-15 {
    width: 93.75%; }
  .order-tracker-multi .gc-16 {
    width: 100%; }
  .order-tracker-multi .bpot4-gcp-0 {
    margin-left: 0; }
  .order-tracker-multi .bpot4-gc-half {
    width: 50%; }
  .order-tracker-multi .bpot4-gc-1-16 {
    width: 6.25%; }
  .order-tracker-multi .bpot4-gcp-1-16 {
    margin-left: 6.25%; }
  .order-tracker-multi .bpot4-gc-2-16 {
    width: 12.5%; }
  .order-tracker-multi .bpot4-gcp-2-16 {
    margin-left: 12.5%; }
  .order-tracker-multi .bpot4-gc-3-16 {
    width: 18.75%; }
  .order-tracker-multi .bpot4-gcp-3-16 {
    margin-left: 18.75%; }
  .order-tracker-multi .bpot4-gc-4-16 {
    width: 25%; }
  .order-tracker-multi .bpot4-gcp-4-16 {
    margin-left: 25%; }
  .order-tracker-multi .bpot4-gc-5-16 {
    width: 31.25%; }
  .order-tracker-multi .bpot4-gcp-5-16 {
    margin-left: 31.25%; }
  .order-tracker-multi .bpot4-gc-6-16 {
    width: 37.5%; }
  .order-tracker-multi .bpot4-gcp-6-16 {
    margin-left: 37.5%; }
  .order-tracker-multi .bpot4-gc-7-16 {
    width: 43.75%; }
  .order-tracker-multi .bpot4-gcp-7-16 {
    margin-left: 43.75%; }
  .order-tracker-multi .bpot4-gc-8-16 {
    width: 50%; }
  .order-tracker-multi .bpot4-gcp-8-16 {
    margin-left: 50%; }
  .order-tracker-multi .bpot4-gc-9-16 {
    width: 56.25%; }
  .order-tracker-multi .bpot4-gcp-9-16 {
    margin-left: 56.25%; }
  .order-tracker-multi .bpot4-gc-10-16 {
    width: 62.5%; }
  .order-tracker-multi .bpot4-gcp-10-16 {
    margin-left: 62.5%; }
  .order-tracker-multi .bpot4-gc-11-16 {
    width: 68.75%; }
  .order-tracker-multi .bpot4-gcp-11-16 {
    margin-left: 68.75%; }
  .order-tracker-multi .bpot4-gc-12-16 {
    width: 75%; }
  .order-tracker-multi .bpot4-gcp-12-16 {
    margin-left: 75%; }
  .order-tracker-multi .bpot4-gc-13-16 {
    width: 81.25%; }
  .order-tracker-multi .bpot4-gcp-13-16 {
    margin-left: 81.25%; }
  .order-tracker-multi .bpot4-gc-14-16 {
    width: 87.5%; }
  .order-tracker-multi .bpot4-gcp-14-16 {
    margin-left: 87.5%; }
  .order-tracker-multi .bpot4-gc-15-16 {
    width: 93.75%; }
  .order-tracker-multi .bpot4-gcp-15-16 {
    margin-left: 93.75%; }
  .order-tracker-multi .bpot4-gc-16-16 {
    width: 100%; }
  .order-tracker-multi .bpot4-gcp-16-16 {
    margin-left: 100%; } }

@media (min-width: 1400px) {
  .order-tracker-multi .gc-1 {
    width: 6.25%; }
  .order-tracker-multi .gc-2 {
    width: 12.5%; }
  .order-tracker-multi .gc-3 {
    width: 18.75%; }
  .order-tracker-multi .gc-4 {
    width: 25%; }
  .order-tracker-multi .gc-5 {
    width: 31.25%; }
  .order-tracker-multi .gc-6 {
    width: 37.5%; }
  .order-tracker-multi .gc-7 {
    width: 43.75%; }
  .order-tracker-multi .gc-8 {
    width: 50%; }
  .order-tracker-multi .gc-9 {
    width: 56.25%; }
  .order-tracker-multi .gc-10 {
    width: 62.5%; }
  .order-tracker-multi .gc-11 {
    width: 68.75%; }
  .order-tracker-multi .gc-12 {
    width: 75%; }
  .order-tracker-multi .gc-13 {
    width: 81.25%; }
  .order-tracker-multi .gc-14 {
    width: 87.5%; }
  .order-tracker-multi .gc-15 {
    width: 93.75%; }
  .order-tracker-multi .gc-16 {
    width: 100%; }
  .order-tracker-multi .bpot5-gcp-0 {
    margin-left: 0; }
  .order-tracker-multi .bpot5-gc-half {
    width: 50%; }
  .order-tracker-multi .bpot5-gc-1-16 {
    width: 6.25%; }
  .order-tracker-multi .bpot5-gcp-1-16 {
    margin-left: 6.25%; }
  .order-tracker-multi .bpot5-gc-2-16 {
    width: 12.5%; }
  .order-tracker-multi .bpot5-gcp-2-16 {
    margin-left: 12.5%; }
  .order-tracker-multi .bpot5-gc-3-16 {
    width: 18.75%; }
  .order-tracker-multi .bpot5-gcp-3-16 {
    margin-left: 18.75%; }
  .order-tracker-multi .bpot5-gc-4-16 {
    width: 25%; }
  .order-tracker-multi .bpot5-gcp-4-16 {
    margin-left: 25%; }
  .order-tracker-multi .bpot5-gc-5-16 {
    width: 31.25%; }
  .order-tracker-multi .bpot5-gcp-5-16 {
    margin-left: 31.25%; }
  .order-tracker-multi .bpot5-gc-6-16 {
    width: 37.5%; }
  .order-tracker-multi .bpot5-gcp-6-16 {
    margin-left: 37.5%; }
  .order-tracker-multi .bpot5-gc-7-16 {
    width: 43.75%; }
  .order-tracker-multi .bpot5-gcp-7-16 {
    margin-left: 43.75%; }
  .order-tracker-multi .bpot5-gc-8-16 {
    width: 50%; }
  .order-tracker-multi .bpot5-gcp-8-16 {
    margin-left: 50%; }
  .order-tracker-multi .bpot5-gc-9-16 {
    width: 56.25%; }
  .order-tracker-multi .bpot5-gcp-9-16 {
    margin-left: 56.25%; }
  .order-tracker-multi .bpot5-gc-10-16 {
    width: 62.5%; }
  .order-tracker-multi .bpot5-gcp-10-16 {
    margin-left: 62.5%; }
  .order-tracker-multi .bpot5-gc-11-16 {
    width: 68.75%; }
  .order-tracker-multi .bpot5-gcp-11-16 {
    margin-left: 68.75%; }
  .order-tracker-multi .bpot5-gc-12-16 {
    width: 75%; }
  .order-tracker-multi .bpot5-gcp-12-16 {
    margin-left: 75%; }
  .order-tracker-multi .bpot5-gc-13-16 {
    width: 81.25%; }
  .order-tracker-multi .bpot5-gcp-13-16 {
    margin-left: 81.25%; }
  .order-tracker-multi .bpot5-gc-14-16 {
    width: 87.5%; }
  .order-tracker-multi .bpot5-gcp-14-16 {
    margin-left: 87.5%; }
  .order-tracker-multi .bpot5-gc-15-16 {
    width: 93.75%; }
  .order-tracker-multi .bpot5-gcp-15-16 {
    margin-left: 93.75%; }
  .order-tracker-multi .bpot5-gc-16-16 {
    width: 100%; }
  .order-tracker-multi .bpot5-gcp-16-16 {
    margin-left: 100%; } }

@media (max-width: 1023px) {
  .order-tracker-multi .gc-1 {
    width: 12.5%; }
  .order-tracker-multi .gc-2 {
    width: 25%; }
  .order-tracker-multi .gc-3 {
    width: 37.5%; }
  .order-tracker-multi .gc-4 {
    width: 50%; }
  .order-tracker-multi .gc-5 {
    width: 62.5%; }
  .order-tracker-multi .gc-6 {
    width: 75%; }
  .order-tracker-multi .gc-7 {
    width: 87.5%; }
  .order-tracker-multi .gc-8 {
    width: 100%; }
  .order-tracker-multi .gc-9 {
    width: 100%; }
  .order-tracker-multi .gc-10 {
    width: 100%; }
  .order-tracker-multi .gc-11 {
    width: 100%; }
  .order-tracker-multi .gc-12 {
    width: 100%; }
  .order-tracker-multi .gc-13 {
    width: 100%; }
  .order-tracker-multi .gc-14 {
    width: 100%; }
  .order-tracker-multi .gc-15 {
    width: 100%; }
  .order-tracker-multi .gc-16 {
    width: 100%; }
  .order-tracker-multi .bpot3-gcp-0 {
    margin-left: 0; }
  .order-tracker-multi .bpot3-gc-half {
    width: 50%; }
  .order-tracker-multi .bpot3-gc-1-8 {
    width: 12.5%; }
  .order-tracker-multi .bpot3-gcp-1-16 {
    margin-left: 6.25%; }
  .order-tracker-multi .bpot3-gc-2-8 {
    width: 25%; }
  .order-tracker-multi .bpot3-gcp-2-16 {
    margin-left: 12.5%; }
  .order-tracker-multi .bpot3-gc-3-8 {
    width: 37.5%; }
  .order-tracker-multi .bpot3-gcp-3-16 {
    margin-left: 18.75%; }
  .order-tracker-multi .bpot3-gc-4-8 {
    width: 50%; }
  .order-tracker-multi .bpot3-gcp-4-16 {
    margin-left: 25%; }
  .order-tracker-multi .bpot3-gc-5-8 {
    width: 62.5%; }
  .order-tracker-multi .bpot3-gcp-5-16 {
    margin-left: 31.25%; }
  .order-tracker-multi .bpot3-gc-6-8 {
    width: 75%; }
  .order-tracker-multi .bpot3-gcp-6-16 {
    margin-left: 37.5%; }
  .order-tracker-multi .bpot3-gc-7-8 {
    width: 87.5%; }
  .order-tracker-multi .bpot3-gcp-7-16 {
    margin-left: 43.75%; }
  .order-tracker-multi .bpot3-gc-8-8 {
    width: 100%; }
  .order-tracker-multi .bpot3-gcp-8-16 {
    margin-left: 50%; } }

.order-tracker-multi .g-hidden {
  display: none; }

.order-tracker-multi .g-visible {
  display: block; }

@media (min-width: 1024px) {
  .order-tracker-multi .bpot4-hidden {
    display: none; }
  .order-tracker-multi .bpot4-visible {
    display: block; } }

@media (min-width: 1400px) {
  .order-tracker-multi .bpot5-hidden {
    display: none; }
  .order-tracker-multi .bpot5-visible {
    display: block; } }

@media (max-width: 1023px) {
  .order-tracker-multi .bpot3-hidden {
    display: none; }
  .order-tracker-multi .bpot3-visible {
    display: block; } }

.order-tracker-multi-wrapper {
  height: 100%; }

.order-tracker-multi-screen-1 {
  height: 100%;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out; }
  .order-tracker-multi-screen-1 nav {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: #ffffff;
    display: -webkit-box;
    display: -moz-box;
    display: box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -o-align-items: center;
    align-items: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -o-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    width: 148px; }
    .order-tracker-multi-screen-1 nav > div {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      flex-grow: 1;
      -ms-flex-positive: 1;
      width: 100%;
      text-align: center;
      position: relative; }
      .order-tracker-multi-screen-1 nav > div:not(:last-child) {
        border-bottom: 1px solid #eaeae8; }
      .order-tracker-multi-screen-1 nav > div img {
        vertical-align: middle; }
      .order-tracker-multi-screen-1 nav > div a {
        display: block;
        width: 100%;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
  .order-tracker-multi-screen-1 .gl-container, .order-tracker-multi-screen-1 .cp-top-section .t-container, .cp-top-section .order-tracker-multi-screen-1 .t-container {
    max-width: initial;
    height: 100%; }
    .order-tracker-multi-screen-1 .gl-container > *, .order-tracker-multi-screen-1 .cp-top-section .t-container > *, .cp-top-section .order-tracker-multi-screen-1 .t-container > * {
      position: relative;
      height: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      box-orient: vertical;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      box-direction: normal;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      flex-direction: column;
      -ms-flex-direction: column;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      box-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      -ms-justify-content: center;
      -o-justify-content: center;
      justify-content: center;
      -ms-flex-pack: center; }
  .order-tracker-multi-screen-1-img {
    max-width: 100%;
    height: auto; }
  .order-tracker-multi-screen-1.loading {
    opacity: 0; }

.order-tracker-multi-screen-2 {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100vh;
  background-color: #eaeae8;
  -webkit-transition-property: -webkit-transform, background-color;
  -moz-transition-property: -moz-transform, background-color;
  transition-property: transform, background-color;
  -webkit-transition-duration: 1s, 0.5s;
  -moz-transition-duration: 1s, 0.5s;
  transition-duration: 1s, 0.5s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  overflow: hidden; }
  .order-tracker-multi-screen-2 nav {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vh;
    height: 40px;
    background: #2b2b2b;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
    -webkit-transform: translateY(100vh) rotate(-90deg);
    -moz-transform: translateY(100vh) rotate(-90deg);
    -ms-transform: translateY(100vh) rotate(-90deg);
    -o-transform: translateY(100vh) rotate(-90deg);
    transform: translateY(100vh) rotate(-90deg);
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    transform-origin: 0% 0%; }
  .order-tracker-multi-screen-2 .gl-container, .order-tracker-multi-screen-2 .cp-top-section .t-container, .cp-top-section .order-tracker-multi-screen-2 .t-container {
    max-width: initial; }
  .order-tracker-multi-screen-2.loading {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%); }
    .order-tracker-multi-screen-2.loading:after {
      content: 'Loading...';
      display: block;
      font-size: 21px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .order-tracker-multi-screen-2.loaded {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    background-color: #eaeae8; }

.order-tracker-multi .order-tracker {
  padding-top: 56px;
  padding-bottom: 56px;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.order-tracker-multi .order-tracker-line-item {
  padding: 32px 0px 24px;
  background: none; }
  .order-tracker-multi .order-tracker-line-item .cp-label {
    background: none; }
  .order-tracker-multi .order-tracker-line-item strong.label, .order-tracker-multi .order-tracker-line-item #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a.label, #menu-drawer nav ul.tree .order-tracker-multi .order-tracker-line-item ul[data-depth="1"] > li.has-children > a.label, .order-tracker-multi .order-tracker-line-item .cp-label.v2 span.label, .cp-label.v2 .order-tracker-multi .order-tracker-line-item span.label {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .order-tracker-multi .order-tracker-line-item-top h2 {
    position: relative; }
  .order-tracker-multi .order-tracker-line-item-top h2 sup {
    position: absolute;
    top: 1em;
    left: -10%;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    margin-top: -8px; }

@media (max-width: 1023px) {
  .order-tracker-multi .order-tracker {
    padding-left: 48px !important;
    padding-top: 32px !important;
    padding-bottom: 32px !important; }
  .order-tracker-multi-screen-1 nav {
    bottom: initial;
    right: 0;
    width: 100%;
    height: 64px;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-direction: normal;
    box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    flex-direction: row;
    -ms-flex-direction: row; }
    .order-tracker-multi-screen-1 nav > div {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      flex-grow: 1;
      -ms-flex-positive: 1;
      height: 100%; }
      .order-tracker-multi-screen-1 nav > div:not(:last-child) {
        border-bottom: 0px;
        border-right: 1px solid #eaeae8; }
    .order-tracker-multi-screen-1 nav img {
      max-width: calc(100% - 16px);
      height: auto; }
  .order-tracker-multi .order-tracker-line-item {
    background: #ffffff;
    padding: 24px; }
    .order-tracker-multi .order-tracker-line-item-top h2 sup {
      position: relative;
      top: 0.1em;
      left: 0.25em;
      width: 16px;
      height: 16px;
      margin-left: 8px;
      margin-top: 8px; } }

@media (min-width: 1024px) {
  .cp-top-section {
    min-height: 665px; }
  .cp-top-section-fixed {
    position: relative;
    min-height: 654px; }
    .cp-top-section-fixed > .gl-container, .cp-top-section .cp-top-section-fixed > .t-container {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
  .cp-top-section-map {
    height: 960px; } }

@media (max-width: 1023px) {
  footer .wrapp > .gl-container .gl-container div.bp3-gc-4-9, footer .cp-top-section .wrapp > .t-container .gl-container div.bp3-gc-4-9, .cp-top-section footer .wrapp > .t-container .gl-container div.bp3-gc-4-9, footer .wrapp > .gl-container .cp-top-section .t-container div.bp3-gc-4-9, .cp-top-section footer .wrapp > .gl-container .t-container div.bp3-gc-4-9, footer .cp-top-section .wrapp > .t-container .t-container div.bp3-gc-4-9, .cp-top-section footer .wrapp > .t-container .t-container div.bp3-gc-4-9 {
    width: 50%; }
  header .bar nav a {
    padding-left: 2vw;
    padding-right: 2vw; }
    header .bar nav a:after {
      left: 2vw;
      right: 2vw; }
  .p-home-page main .awesome-bg-image {
    left: -20%; }
  .cp-product-gallery .gc-3 {
    width: 30vw; }
  .cp-product-gallery .gc-4 {
    width: 38vw; }
  .cp-breadcrumb {
    padding-top: 5%;
    padding-bottom: 6%;
    margin-left: 16px; }
  .cp-category-summary {
    position: relative;
    margin-bottom: 24px;
    background-color: #f9f8f3;
    padding-bottom: initial; }
    .cp-category-summary div.left, .cp-category-summary div.right {
      position: relative;
      width: 100%;
      float: none; }
    .cp-category-summary div.left {
      padding: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    .cp-category-summary div.right {
      padding-left: 0;
      height: 464px;
      max-height: calc(72px + 100vw - 32px); }
      .cp-category-summary div.right > div {
        position: absolute;
        height: 100%;
        width: 100%; }
      .cp-category-summary div.right .cp-swiper-gallery .swiper-slide {
        max-width: calc(100vw - 48px); } }

@media (max-width: 767px) {
  .t-default, body, .cp-text-top h1 small, .p-product main .cp-top-section .t-h1 p.product-code, .p-contact main .map-overlay {
    font-family: TVNordEF-Regular, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px; }
  .t-h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px; }
  .t-h2, .cp-search-drawer input, .order-tracker-multi-screen-2.loading:after {
    font-size: 19px;
    line-height: 32px; }
  .t-h3, .p-contact main .content-overlay div {
    font-size: 19px; }
  .t-h4, .cp-module a, .cp-module-side .t strong, .cp-module-side .t #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, #menu-drawer nav ul.tree .cp-module-side .t ul[data-depth="1"] > li.has-children > a, .cp-module-side .t .cp-label.v2 span, .cp-label.v2 .cp-module-side .t span, header .bar nav a, .cp-swiper-gallery.cfeature .items .text, .cp-breadcrumb a:last-of-type, .p-contact main .map-overlay h2 {
    font-size: 12px;
    line-height: 20px; }
  .t-links, .g-button.alt, button.alt, .cp-module .overlay span, #menu-drawer nav ul.tree > li > a, #menu-drawer nav ul.main-links > li > a, #menu-drawer nav ul.products > li > a, .cp-search-drawer .desktop-recent-searches div, .p-contact main .map-container .overlay div {
    font-size: 16px; }
  .scrollable {
    margin-top: 95px; }
  .g-p-1 {
    padding-top: 56px; }
  .g-m-1 {
    margin-top: 56px; }
  .g-pt-20 {
    padding-top: 80px; }
  .g-pb-20 {
    padding-bottom: 80px; }
  .g-pb-28 {
    padding-bottom: 80px; }
  .g-pb-9 {
    padding-bottom: 32px; }
  .g-pt-9 {
    padding-top: 32px; }
  .g-pb-15 {
    padding-bottom: 56px; }
  .g-pt-15 {
    padding-top: 56px; }
  .g-mb-15 {
    margin-bottom: 56px; }
  footer .wrapp > .gl-container, footer .cp-top-section .wrapp > .t-container, .cp-top-section footer .wrapp > .t-container {
    display: block; }
    footer .wrapp > .gl-container div.gc-4, footer .cp-top-section .wrapp > .t-container div.gc-4, .cp-top-section footer .wrapp > .t-container div.gc-4 {
      display: block; }
      footer .wrapp > .gl-container div.gc-4 article, footer .cp-top-section .wrapp > .t-container div.gc-4 article, .cp-top-section footer .wrapp > .t-container div.gc-4 article {
        min-height: 300px; }
  footer .wrapp .sub ul li {
    display: block;
    margin-bottom: 1em; }
  footer .wrapp .sub .t-right a {
    text-decoration: underline; }
  header .bar nav {
    display: none; }
  header .bar .mobile-menu {
    display: block; }
  header .menu .close {
    top: 119px; }
  header .menu nav .tree-container {
    overflow: hidden;
    height: 40px; }
  header .menu nav sup {
    color: #ffe000; }
  header .menu nav ul.tree {
    margin-bottom: 16px;
    position: relative;
    margin-left: 40px; }
    header .menu nav ul.tree > li a {
      line-height: 40px;
      font-size: 16px; }
      header .menu nav ul.tree > li a span {
        position: relative;
        display: inline-block !important;
        margin-left: 3px; }
    header .menu nav ul.tree:before {
      border-left: 6px solid #f1cb33;
      content: " ";
      display: block;
      position: absolute;
      left: -40px;
      top: 6px;
      bottom: 7px; }
    header .menu nav ul.tree ul {
      display: none !important;
      width: 100%;
      margin: 0; }
      header .menu nav ul.tree ul:last-of-type {
        margin-bottom: 0; }
      header .menu nav ul.tree ul li a {
        font-size: 14px; }
      header .menu nav ul.tree ul:last-of-type {
        margin-bottom: 16px; }
    header .menu nav ul.tree ul:first-of-type {
      margin-left: 0; }
      header .menu nav ul.tree ul:first-of-type:before {
        display: none; }
  header #menu-drawer nav ul.tree ul[data-depth="1"] > li[data-depth="1"] {
    width: 50%; }
  header #menu-drawer nav ul.tree ul[data-depth="1"] {
    margin-left: 0 !important; }
    header #menu-drawer nav ul.tree ul[data-depth="1"]:before {
      display: none !important; }
  header #menu-drawer ul.main-links > li > a {
    font-size: 20px;
    line-height: 40px; }
    header #menu-drawer ul.main-links > li > a sup {
      font-size: 12px; }
  header .search, header .mobile-menu {
    width: 95px; }
    header .search.mobile-menu, header .mobile-menu.mobile-menu {
      right: 0; }
  header .search {
    right: 95px; }
  header #menu-drawer nav ul.tree li[data-depth="0"] {
    height: 40px; }
  .cp-search-drawer .gl-container > *, .cp-search-drawer .cp-top-section .t-container > *, .cp-top-section .cp-search-drawer .t-container > * {
    float: none; }
  .cp-search-drawer .mobile-recent-searches {
    padding-top: 0px; }
    .cp-search-drawer .mobile-recent-searches > a {
      display: block;
      text-align: right;
      color: #b2b2b2; }
    .cp-search-drawer .mobile-recent-searches .searches {
      color: #b2b2b2;
      display: none;
      text-align: right;
      padding-top: 16px; }
      .cp-search-drawer .mobile-recent-searches .searches a {
        display: inline-block;
        margin-right: 16px; }
  .cp-search-drawer .wrapp {
    padding-top: 20px;
    padding-bottom: 16px;
    min-height: 0px; }
  .cp-search-drawer .close {
    padding: 16px; }
  .cp-search-drawer strong, .cp-search-drawer #menu-drawer nav ul.tree ul[data-depth="1"] > li.has-children > a, #menu-drawer nav ul.tree .cp-search-drawer ul[data-depth="1"] > li.has-children > a, .cp-search-drawer .cp-label.v2 span, .cp-label.v2 .cp-search-drawer span {
    padding-bottom: 16px; }
  .cp-search-drawer input {
    line-height: 40px;
    min-height: 40px; }
    .cp-search-drawer input::-webkit-input-placeholder {
      line-height: 40px !important;
      min-height: 40px !important; }
    .cp-search-drawer input::-moz-placeholder {
      line-height: 40px !important;
      min-height: 40px !important; }
    .cp-search-drawer input:-moz-placeholder {
      line-height: 40px !important;
      min-height: 40px !important; }
    .cp-search-drawer input:-ms-input-placeholder {
      line-height: 40px !important;
      min-height: 40px !important; }
  .cp-top-section {
    padding-top: 0px;
    /*.full:before{
		  position: absolute;
		  z-index: 3;
		  background-color: color(white,four);
		  width:100%;
		  height:100%;
		  top:0;
		  left:0;
		  content:'';

	  }*/
    padding-bottom: 36px;
    min-height: initial; }
    .cp-top-section .image, .cp-top-section .image-gallery {
      left: 0;
      width: 100% !important;
      margin-left: 0 !important; }
      .cp-top-section .image img, .cp-top-section .image-gallery img {
        width: 100%;
        max-height: none;
        max-width: none; }
    .cp-top-section .full {
      position: relative;
      padding-bottom: 98.7878787879%;
      height: auto; }
    .cp-top-section.home {
      background-color: #f7f6f1;
      height: auto;
      max-height: initial;
      padding-bottom: 0px; }
      .cp-top-section.home .full2 {
        position: relative;
        height: auto;
        padding-bottom: 67.4242424242%; }
        .cp-top-section.home .full2 div.image {
          position: absolute; }
      .cp-top-section.home .t-container {
        padding: 24px 0px 32px; }
        .cp-top-section.home .t-container .gl-container, .cp-top-section.home .t-container .t-container {
          margin-left: 0px !important;
          margin-right: 0px !important;
          padding: 0px; }
        .cp-top-section.home .t-container .t-slide:first-child {
          position: relative; }
    .cp-top-section a {
      margin-top: 24px; }
    .cp-top-section.detail {
      min-height: initial;
      background-color: #f9f8f3; }
  .cp-product-gallery .gc-3 {
    width: 43vw; }
  .cp-product-gallery .gc-4 {
    width: 42vw; }
  .cp-text-top a.g-button {
    display: block; }
    .cp-text-top a.g-button:last-of-type {
      margin-left: 0;
      margin-top: 16px; }
  .cp-captions {
    height: 45px; }
    .cp-captions .items {
      float: left;
      padding-right: 16px;
      position: relative;
      height: 45px; }
      .cp-captions .items .text {
        position: absolute;
        left: 16px;
        top: 0;
        height: 45px;
        background-color: #ffffff;
        line-height: 45px;
        opacity: 0; }
        .cp-captions .items .text:first-of-type {
          opacity: 1; }
    .cp-captions .dots {
      width: auto;
      float: left;
      text-align: left;
      padding-left: 16px; }
      .cp-captions .dots a {
        margin: 18px 5px 10px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #dddddd;
        border-radius: 10px;
        position: relative; }
        .cp-captions .dots a.active, .cp-captions .dots a:hover {
          background-color: #ffe000; }
  .cp-swiper-gallery.gallery {
    left: 0;
    width: 100%;
    height: 100%; }
    .cp-swiper-gallery.gallery .item img {
      position: absolute;
      bottom: 45px;
      left: 0;
      width: auto;
      height: auto; }
  [class*=" cp-line-"] {
    position: relative;
    padding-left: 32px; }
    [class*=" cp-line-"]:before {
      top: 4px;
      bottom: 4px;
      left: 0px; }
  .order-tracker-line-item {
    padding: 24px; }
  .p-product main {
    background-color: #ebebe4; }
    .p-product main article > .full {
      position: relative; }
    .p-product main .cp-top-section.detail {
      padding-top: 0; }
    .p-product main .cp-top-section .full {
      margin-bottom: 32px; }
    .p-product main .cp-top-section .gallery {
      left: 0;
      width: 100%;
      height: 100%; }
    .p-product main .cp-top-section .download a.bp2-hidden {
      display: none; }
    .p-product main section.g-bg-white-two {
      background-color: #ebebe4; }
    .p-product main .specifications ul:before {
      top: 0;
      bottom: 0; }
    .p-product main .cp-top-section .bar {
      bottom: 45px; }
  .p-category main .cp-top-section {
    background-color: #f9f8f3; }
  .p-category main .cp-top-section .full {
    margin-bottom: 32px; }
  .p-category main .cp-module {
    padding-bottom: calc(100% + 56px); }
  .cp-category-summary .bar {
    bottom: 45px; }
  .p-home-page main .categories {
    padding-top: 28px; }
    .p-home-page main .categories .t-h2, .p-home-page main .categories .cp-search-drawer input, .cp-search-drawer .p-home-page main .categories input, .p-home-page main .categories .order-tracker-multi-screen-2.loading:after {
      padding-bottom: 20px; }
  .p-home-page main .manual {
    padding-top: 72px;
    padding-bottom: 0; }
  .p-home-page main .manual img {
    position: relative;
    top: 0;
    left: 0px;
    max-width: 120%;
    margin-top: -35%; }
  .p-about main .history {
    padding-left: 0;
    padding-right: 0;
    background-color: #ffffff; }
    .p-about main .history .gc-6 {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      padding-bottom: 50%; }
      .p-about main .history .gc-6:first-of-type {
        padding-bottom: 0; }
      .p-about main .history .gc-6:last-of-type {
        display: none; }
    .p-about main .history .images {
      position: relative;
      padding-bottom: 0;
      background-color: #e4e4d9;
      height: 100vw !important;
      overflow: hidden; }
      .p-about main .history .images div {
        position: relative;
        top: 0;
        left: 0;
        opacity: 1 !important;
        width: 100vw;
        float: left;
        padding-bottom: 0; }
        .p-about main .history .images div img {
          width: 100%;
          height: auto;
          display: block; }
    .p-about main .history .cp-captions {
      height: auto; }
      .p-about main .history .cp-captions .items {
        position: relative;
        z-index: 1;
        width: 100%; }
      .p-about main .history .cp-captions .dots {
        z-index: 10;
        position: relative;
        float: right; }
      .p-about main .history .cp-captions .text {
        height: auto; }
    .p-about main .history .read-more-text {
      background-color: #ffffff;
      padding-right: 16px; }
    .p-about main .history a.read-more {
      text-decoration: underline;
      margin-left: 10px; }
      .p-about main .history a.read-more span {
        display: inline-block;
        -webkit-transform: translateX(-5px);
        -moz-transform: translateX(-5px);
        -ms-transform: translateX(-5px);
        -o-transform: translateX(-5px);
        transform: translateX(-5px); }
  .p-help main .cp-text-top h1, .p-search main .cp-text-top h1 {
    margin-bottom: 40px; }
  .p-help main .center-col, .p-search main .center-col {
    padding-left: 16px; }
    .p-help main .center-col .field:last-of-type, .p-search main .center-col .field:last-of-type {
      margin-bottom: 0; }
  .p-help main .third a.g-button, .p-search main .third a.g-button {
    padding-top: 33px; }
  .p-help main .g-pb-7, .p-help main .cp-top-section .specs, .cp-top-section .p-help main .specs, .p-search main .g-pb-7, .p-search main .cp-top-section .specs, .cp-top-section .p-search main .specs {
    padding-bottom: 24px; }
  .p-help main .g-pt-7, .p-search main .g-pt-7 {
    padding-top: 24px; }
  .p-help main #content, .p-search main #content {
    padding-left: 16px;
    padding-right: 16px; }
  .p-help main .gl-container section .gl-container, .p-help main .cp-top-section .t-container section .gl-container, .cp-top-section .p-help main .t-container section .gl-container, .p-help main .gl-container section .cp-top-section .t-container, .cp-top-section .p-help main .gl-container section .t-container, .p-help main .cp-top-section .t-container section .t-container, .cp-top-section .p-help main .t-container section .t-container, .p-search main .gl-container section .gl-container, .p-search main .cp-top-section .t-container section .gl-container, .cp-top-section .p-search main .t-container section .gl-container, .p-search main .gl-container section .cp-top-section .t-container, .cp-top-section .p-search main .gl-container section .t-container, .p-search main .cp-top-section .t-container section .t-container, .cp-top-section .p-search main .t-container section .t-container {
    margin: 0 !important; }
  .p-contact main div[data-lat] .adr {
    text-decoration: underline; }
  .p-wheretobuy main .cp-text-top h1 {
    margin-bottom: 40px; }
  .p-wheretobuy main .filter {
    padding-top: 28px;
    padding-bottom: 28px; }
  .p-wheretobuy main .cp-block {
    padding: 18px; }
  .p-wheretobuy main .cp-label {
    margin-bottom: 8px; }
  .p-wheretobuy main .cp-text-top .g-button {
    float: left;
    width: 50%;
    margin-top: 0px; } }

@media (max-width: 479px) {
  .scrollable {
    margin-top: 64px; }
  .p-product main .video-embed-label {
    left: 18px;
    bottom: 18px; }
  footer .wrapp > .gl-container, footer .cp-top-section .wrapp > .t-container, .cp-top-section footer .wrapp > .t-container {
    display: block; }
    footer .wrapp > .gl-container div.gc-4, footer .cp-top-section .wrapp > .t-container div.gc-4, .cp-top-section footer .wrapp > .t-container div.gc-4 {
      padding-top: 32px;
      display: block; }
      footer .wrapp > .gl-container div.gc-4:first-of-type, footer .cp-top-section .wrapp > .t-container div.gc-4:first-of-type, .cp-top-section footer .wrapp > .t-container div.gc-4:first-of-type {
        padding-top: 0; }
      footer .wrapp > .gl-container div.gc-4 article, footer .cp-top-section .wrapp > .t-container div.gc-4 article, .cp-top-section footer .wrapp > .t-container div.gc-4 article {
        min-height: inherit; }
        footer .wrapp > .gl-container div.gc-4 article a, footer .cp-top-section .wrapp > .t-container div.gc-4 article a, .cp-top-section footer .wrapp > .t-container div.gc-4 article a {
          margin-top: 16px;
          position: relative;
          bottom: auto; }
  footer .wrapp .t-right {
    text-align: left; }
  header {
    height: 64px; }
    header .bar {
      height: 64px; }
    header .bar nav a {
      overflow: hidden;
      position: relative;
      line-height: 64px; }
    header .logo {
      -webkit-transform: scale(0.65);
      -moz-transform: scale(0.65);
      -ms-transform: scale(0.65);
      -o-transform: scale(0.65);
      transform: scale(0.65);
      left: 16px; }
    header .bar a {
      line-height: 64px;
      height: 100%;
      display: block; }
    header .search, header .mobile-menu {
      width: 64px; }
      header .search.mobile-menu, header .mobile-menu.mobile-menu {
        right: 0; }
    header .search {
      right: 64px; }
    header .menu {
      top: 64px; }
      header .menu nav ul.products {
        margin-bottom: 16px; }
        header .menu nav ul.products > li a {
          line-height: 24px;
          font-size: 15px; }
    header #menu-drawer .wrapp {
      padding-top: 32px; }
    header #menu-drawer {
      height: calc(100vh - 64px);
      top: 64px; }
    header .dest {
      height: 64px; }
  .cp-search-drawer input::-webkit-input-placeholder {
    color: #242424 !important; }
  .cp-search-drawer input::-moz-placeholder {
    color: #242424 !important; }
  .cp-search-drawer input:-moz-placeholder {
    color: #242424 !important; }
  .cp-search-drawer input:-ms-input-placeholder {
    color: #242424 !important; }
  .cp-country-checker .close {
    padding-right: 24px; }
  .cp-text-top a.g-button {
    display: block; }
    .cp-text-top a.g-button:last-of-type {
      margin-left: 0;
      margin-top: 16px; }
  [class*=" cp-line-"] {
    position: relative;
    padding-left: 32px; }
    [class*=" cp-line-"]:before {
      top: 4px;
      bottom: 4px;
      left: 0px; }
  .cp-product-gallery .gc-3 {
    width: 75vw; }
  .cp-product-gallery .gc-4 {
    width: 75vw; }
  .cp-block {
    min-height: 134px; }
    .cp-block p {
      padding-right: 60px; }
  h2.list {
    width: 100%;
    padding-top: 86px; }
  .p-wheretobuy main .gl-container .line h1, .p-wheretobuy main .cp-top-section .t-container .line h1, .cp-top-section .p-wheretobuy main .t-container .line h1 {
    -webkit-transform: translateX(-33px);
    -moz-transform: translateX(-33px);
    -ms-transform: translateX(-33px);
    -o-transform: translateX(-33px);
    transform: translateX(-33px); }
  .p-wheretobuy main .gl-container .line form:before, .p-wheretobuy main .cp-top-section .t-container .line form:before, .cp-top-section .p-wheretobuy main .t-container .line form:before {
    -webkit-transform: translate(-33px, 0);
    -moz-transform: translate(-33px, 0);
    -ms-transform: translate(-33px, 0);
    -o-transform: translate(-33px, 0);
    transform: translate(-33px, 0);
    opacity: 1; }
  .p-wheretobuy main .cp-text-top .g-button {
    float: none;
    width: 100%;
    margin-top: inherit; }
    .p-wheretobuy main .cp-text-top .g-button:last-of-type {
      margin-top: 16px; }
  .p-about main .container {
    top: -33px; }
  .p-help main section, .p-search main section {
    position: relative; }
  .p-help main .third, .p-search main .third {
    position: absolute;
    top: 16px;
    right: 8px;
    text-align: right; }
    .p-help main .third a.g-button, .p-search main .third a.g-button {
      padding-top: 0; }
  .p-help main .center-col, .p-search main .center-col {
    padding-left: 16px;
    padding-right: 16px; }
    .p-help main .center-col h1, .p-search main .center-col h1 {
      padding-right: 70px; } }
