.p-wheretobuy main{
	.cp-text-top h1{
		margin-bottom:40px;
	}

	.filter{
		padding-top:28px;
		padding-bottom:28px;
	}
	.cp-block {
		padding: 18px;
	}
	.cp-label {
		margin-bottom:vSize(1);
	}
	.cp-text-top .g-button{
		float:left;
		width:50%;
		margin-top:0px;
	}


}
