.draggable {
	position: absolute;
	width:90%;
	height: 6px;
	background-color: color(white,two);
	bottom:35px;
	left:5%;
	.handle {
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 20px;
		border:4px solid color(yellow,two);
		background-color: color(white);
		top:-7px
	}
}