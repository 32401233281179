footer {
	.wrapp {
		> .gl-container {
			display: block;
			div.gc-4 {
				display: block;
				article {
					min-height: 300px;
					a {
					}
				}

			}
		}
		.sub {
			ul li{
				display:block;
				margin-bottom:1em;
			}
			.t-right a{
				text-decoration: underline;
			}
		}
	}
}
