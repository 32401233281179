body{
	padding: 0;
	margin: 0;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: greyscale;
	-webkit-font-smoothing: antialiased;
}

main {
	overflow: hidden;
}