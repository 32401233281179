.cp-swiper-gallery {



	&.gallery {
		left:0;
		width: 100%;
		//padding-bottom: calc(100% - 0px);
		height:100%;
		.item {
			img {
				position: absolute;
				bottom:45px;
				left:0;
				width: auto;
				height:auto;
			}
		}
	}


}
