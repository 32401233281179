@import "breakpoint";

// helper to
@mixin fbHelper($breakpoints:()) {
	.g-hidden{
		display: none;
	}
	.g-visible{
		display: block;
	}

	@each $name,$breakpoint in $breakpoints {
		@include breakpoint($name,$breakpoints){
			.bp#{$name}-hidden{
				display: none;
			}
			.bp#{$name}-visible{
				display: block;
			}
		}
	}
}
