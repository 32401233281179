$height:95px;
header  {
	position: fixed;
	height:$height;
	z-index: $z4;
	width:100%;
	top: 0;
	left: 0;
	transition: top 0.5s ease-out;


	.dest {
		position: absolute;
		//overflow-x: hidden;
		top:0;
		left:0;
		width:100%;
		height: $height;
	}

	.outer-wrapper {
		//overflow-y: scroll;
		position: relative;
		z-index: 30;
	}
	.outer-wrapper:after {
		content: "";
		//display: block;
		//background-color:#FAFAFA;
		height: 100%;
		position: absolute;
		top: 0;
		z-index: $z3;

		width: 15px;
		right: 0;
		pointer-events: none;
	}

	.wrapp {
		max-width: 1300px!important;
		width: 100%;
		margin: 0 auto;
		position: relative;
		height: 100%;

	}
	.gl-container.full {
		padding-left: 5px;
	}

	.bar {
		position: relative;
		height:$height;
		z-index: $z3;
		background-color: #eaeae3;
		.wrapp {
			background-color: color(white);
		}

	}

	a.search,a.logo {
		span {
			text-indent: -5000px;
		}
	}
	.logo {
		position: absolute;
		top:50%;
		left:20px;
		margin-top: -31px;
		height:100%;
		@include transform-origin(left center);
		span {
			@include img-svgs-HPM-logo;
		}
		&:after{
			display: none!important;
		}
	}
	.bar nav {
		ul {
			li {
				display: inline-block;
			}
		}
		a {
			overflow: hidden;
			position: relative;
			@extend .t-h4;
			line-height: $height;
			padding-left: $columnWidth/2;
			padding-right: $columnWidth/2;
			height: 100%;
			display: block;
			&:after {
				@include fbTransition(transform,0.3s);
				left:$columnWidth/2;
				right:$columnWidth/2;
				width:auto;
			}
		}

	}
	.bar {
		overflow: hidden;
		a {
			line-height: $height;
			height: 100%;
			display: block;
			&:after {
				@include fbTransition(transform,0.3s);
				content:" ";
				display: block;
				height: vSize(1);
				position: absolute;
				bottom: -8px;
				background-color: color(yellow);
				width: 100%;
			}
			&:hover,&.active,&.active-always {
				&:after {
					@include transform(translateY(-8px));
				}
			}
		}
	}


	.search,.mobile-menu {
		position: absolute;
		right:0;
		top:0;
		height:100%;
		width:$height;

		&.mobile-menu {
			right: $height;
			display: none;
			span {
				margin-top:0;
				text-indent: -9999px;
				height:2px;
				width: 20px;
				background-color: color(black);
				&:before {
					bottom:auto;
					top:-7px;
				}
				&:after,&:before {
					content:" ";
					display: block;
					height: 2px;
					position: absolute;
					background-color: color(black);
					bottom:-7px;
					width: 20px;
				}
			}
		}

		span {
			position: absolute;
			top:50%;
			left:50%;
			margin-top: -10px;
			margin-left: -10px;
			@include img-svgs-menu-search;
			line-height: 95px;
		}
	}
}
#menu-drawer {
	width:100%;
	//overflow-y: auto;
	//-webkit-overflow-scrolling: touch;

	position: absolute;
	height: calc(100vh - #{$height});
	top: $height;
	@include transform(translateY(-100%));
	left:0;
	z-index: $z2;

	.close {
		position: absolute;
		right: 38px;
		top: 32px;
		span {
			@include img-svgs-menu-dropdown-close;
		}
	}

	.wrapp{
		padding: 72px 0 21px;
		background-color: color(black,two);
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;

	}

	nav {
		margin-right: 21px;
		margin-left: 21px;
		ul.tree>li>a ,ul.main-links>li> a,ul.products>li> a {
			@extend .t-links;
			line-height: 45px;
		}
		a {
			color: #b2b2b2;
			&:hover,&.active {
				color: color(yellow,two);
			}
		}
		strong {
			color:color(white);
		}
		ul.products {
			>li {
				a {
					display: block;
					width: 100%;
				}
			}
			ul {
				padding-top: 35px;
				padding-bottom: 35px;
				vertical-align: top;
				display: inline-block;
				width: 20%;
				max-width: 205px;
				&:first-of-type {
					margin-left: 40px ;
					position: relative;
					&:before {
						border-left:6px solid color(yellow,two);
						content: " ";
						display: block;

						position: absolute;
						left:-40px;
						top:6px;
						bottom:7px;

					}
				}
			}
		}
		ul.tree {
			li[data-depth="0"]{
				clear: both;
				height:45px;
				overflow: hidden;
				>a {
					display: block;
				}
			}
			ul[data-depth="1"] {
				opacity: 0;
				//overflow: hidden;
				//height: 0;
				max-width: none;
				display: block;
				margin-top: 35px;
				margin-bottom: 35px;
				vertical-align: top;
				>li.has-children {
					vertical-align: top;
					width:20%;
					display: inline-block;
					>a {
						color:color(white);
						@extend strong;
					}
				}
				>li[data-depth="1"]{
					width: 20%;
				}
				>li[data-depth="1"]:not(.has-children){
					//padding-bottom: vSize(3);
				}

				ul[data-depth="2"]{
					padding-bottom: vSize(3);
				}

				&:first-of-type {
					margin-left: 40px ;
					position: relative;
					&:before {
						border-left:6px solid color(yellow,two);
						content: " ";
						display: block;

						position: absolute;
						left:-40px;
						top:6px;
						bottom:7px;

					}
				}

			}
		}
	}
}
