.p-help main, .p-search main {

	.cp-text-top h1{
		margin-bottom:40px;
	}

	.center-col {
		padding-left: $gutterWidth;
		.field:last-of-type{
			margin-bottom: 0;
		}
	}
	.third {

		a.g-button {
			padding-top: 33px;
		}
	}


	.g-pb-7 {
		padding-bottom: vSize(3);
	}
	.g-pt-7 {
		padding-top: vSize(3);
	}

	#content{
		padding-left: 16px;
		padding-right: 16px;
	}

	.gl-container section .gl-container{
		margin:0 !important;
	}
}

