.gc-3.list {
	position: relative;
	float: right;
}

.select-field {
	height:42px;
	overflow: visible!important;
	&:before {
		display: none;
	}
	.cp-filter-list {
		left:0;
		right:0;
		.active {
			background-color: color(white,two);
			color:color(black);
			&:after {
				border-color: color(black) transparent transparent transparent;
			}
		}
		ul{
			max-height: 50vh;
			overflow-x: hidden;
			overflow-y: auto;

			background-color: color(white,three);
			li.selected {
				font-weight: bold;
			}
			li a {
				color: color(black)!important;
				text-decoration: none;
			}
			li a:hover {
				background-color: color(white,two);
			}
		}
	}
}
.light-color {

}
.cp-filter-list {
	position: absolute;
	z-index: $z3;
	top:0;
	left:8px;
	right:8px;
	display: inline-block;
	color:color(white);
	overflow: hidden;

	padding-left: 0;
	padding-right: 0;
	div.active {
	padding-left: $gutterWidth;
	padding-right: $gutterWidth;
		width: 100%;
		background-color: color(black,two);

		line-height: 40px;
		cursor: pointer;
		span.filter-count {
			color: color(yellow);
		}
		position: relative;
		&:after {
			content: ' ';
			position: absolute;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 6px 4px 0 4px;
			border-color: #ffffff transparent transparent transparent;
			display: block;
			right: $gutterWidth;
			top:50%;
			margin-top: -2px;
		}
	}

	ul{
		padding: 50px 0 10px;
		width: 100%;
		background-color: color(black);
		position: absolute;
		top:0;
		@include transform(translateY(-100%));
		cursor: pointer;
		li {
			opacity:0;
			@include transform(translateY(-50%));
			a{
				display: block;
				line-height: 40px;
				position: relative;
				padding-left: $gutterWidth;
				padding-right: $gutterWidth;
				span{
					position: absolute;
					right: $gutterWidth;
					color:color(yellow);
				}
				&:hover{
					background-color: color(black,two);
				}
			}
		}
	}
}