.p-about main{



	.history {
		padding-left: 0;
		padding-right: 0;
		background-color: color(white);
		.gc-6 {
			padding-left: 0;
			padding-right: 0;
			position: relative;
			padding-bottom: 50%;
			&:first-of-type {
				padding-bottom: 0;
			}
			&:last-of-type {
				display: none;
			}
		}
		.images {
			position: relative;
			padding-bottom: 0;
			background-color: color(white,three);
			height: 100vw!important;
			overflow: hidden;
			div {
				position: relative;
				top:0;
				left:0;
				opacity:1!important;
				width: 100vw;
				float: left;
				padding-bottom: 0;
				img {
					width: 100%;
					height: auto;
					display:block;
				}
			}

		}
		
		.cp-captions {
			height:auto;
			.items {
				position: relative;
				z-index: $z1;
				width: 100%;


			}
			.dots {
				z-index: $z2;
				position: relative;
				float:right;
			}
			.text{
				height:auto;
			}
		}
		
		.read-more-text {
			//@extend .t-default;
			background-color:color(white);
			padding-right: $gutterWidth;
		}

		a.read-more {
			text-decoration: underline;
			margin-left: 10px;
			span {
				display: inline-block;
				@include transform(translateX(-5px));
			}
		}
	}


}

