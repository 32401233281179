.p-contact main {

	.cp-text-top {
		z-index: $z3 + 1;
		position: relative;
	}

	#offices .gl-container {
		@include display(flex);
		@include flex-flow(wrap);

		h2 {
			margin-bottom: vSize(2);
		}

		.adr {
			margin-bottom: vSize(3);
		}
		.cp-label {
			margin-bottom: vSize(1);
		}
	}

	#map {
		height: 465px;

	}
	.map-container{
		position: relative;
		.overlay {
			cursor: pointer;
			top:0;
			left:0;
			position: absolute;
			width: 100%;
			height:100%;
			div{
				position: absolute;
				top:50%;
				left:50%;
				margin-top: -26px;
				margin-left: -26px;
				line-height: 53px;
				color: color(white);
				@extend .t-links;
				span {
					position: absolute;
					left:-70px;
				}
			}
			background-color: rgba(36, 36, 36, 0.6);
		}
	}



	.map-marker {
		width:50px;
		height:50px;
		display: block;
		position: absolute;
		background-color: color(yellow);
		border-radius: 50px;
		@include transform(scale(0.3));
		@include fbTransition(transform,0.4s,cubic-bezier(0.5, -0.5, 0.5, 1.5));

		cursor: pointer;
		z-index: $z2;

		div {
			width: 11px;
			height:11px;
			position: absolute;
			top:20px;
			left:20px;
			@include fbTransition(transform,0.2s);
			&:before,&:after {
				@include fbTransition(opacity,0.2s,0.5s);
				position: absolute;
				content:" ";
				display: block;
				background-color: color(black);
				opacity: 0;
			}
			&:before {
				width:11px;
				height:1px;
				top:5px;
				left:0;

			}
			&:after {
				width:1px;
				height:11px;
				top:0;
				left:5px;
			}
		}

		&:hover {
			z-index: $z3;
			div:before,div:after {
				opacity: 1;
			}
			@include transform(scale(1));
		}
	}

	.map-overlay {
		width:233px;
		background-color: color(yellow);
		position: absolute;
		padding: $gutterWidth;
		@extend .t-default;
		h2 {
			margin-bottom: vSize(2);
			@extend .t-h4;
			font-size: 15px;

		}
		.cp-label {
			display: none;
		}
		.adr {
			margin-bottom: vSize(3);
		}
		.cp-label {
			margin-bottom: vSize(1);
		}

		&:before {
			position: absolute;
			content: " ";
			bottom: -14px;
			left:50%;
			margin-left: -12px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 14px 12px 0 12px;
			border-color: color(yellow) transparent transparent transparent;
		}
		a {
			text-decoration: underline;
		}
		.directions {
			margin-top: vSize(2);
			display: block!important;
			float: left;
		}
		.tel,.fax,.email {
			display: none;
		}
		.back {
			margin-top: vSize(2);
			display: block!important;
			float: right;
		}
	}


	textarea {
		min-height: 146px;
	}

	.last-row {
		.gc-6:first-of-type{
			padding-top: 12px;
		}
		margin-top: vSize(2);
	}

	.contact-form{
		display: none;
		position: absolute;
		width: 100%;
		height:100%;
		z-index: $z3;
		.wrapp {
			background-color: color(white,two);
			@include transform(translateY(-100%));
		}
	}
	.content-overlay {
		opacity: 0;
		display: none;
		cursor: pointer;
		left:0;
		position: absolute;
		width: 100%;
		height:100%;
		z-index: $z2;
		div{
			padding-top: 60px;
			position: absolute;
			top:0;
			left:50%;
			margin-top: -26px;
			margin-left: -26px;
			line-height: 53px;
			color: color(white);
			@extend .t-h3;

			a {
				border-bottom: 2px solid color(yellow);
			}

		}
		background-color: rgba(36, 36, 36, 0.6);
	}


}