.cp-swiper-gallery {

	margin:0 auto;
	position:relative;
	overflow:hidden;
	-webkit-backface-visibility:hidden;
	-moz-backface-visibility:hidden;
	-ms-backface-visibility:hidden;
	-o-backface-visibility:hidden;
	backface-visibility:hidden;
	/* Fix of Webkit flickering */
	z-index:1;


	&.gallery {
		left:47%;
		width:53%;
		position: absolute;
		background-color: #DEDED6;
		height:100%;
		bottom:0!important;
		overflow: hidden;
		.item {
			position: relative;
			img {
				position: absolute;
				bottom:72px;
				left:0;
				width: auto;
				height:auto;
			}
		}
	}

	&.cfeature {
		left:0;
		width:100%;
		position: absolute;
		height:100%;
		bottom:0!important;
		overflow: hidden;
		.item {
			width: 484px;
			height: 596px;
			position: relative;
			img {
				position: absolute;
				bottom:vSize(7);
				left:0;
				width: 100%;
				height:auto;
			}
		}


		.cp-captions{
			height:vSize(7);
		}
		.items .text{
			height:vSize(7);
			line-height:vSize(7);
			@extend .t-h4;

		}
		.item .t-h2{
			position:absolute;
			bottom:vSize(9);
			z-index:1;
			color:white;
			font-weight: normal;
			padding-bottom:30px;
			margin-left: 12.5%;
			width:62.5%;
		}


	}

	.swiper-wrapper {
		position:relative;
		width: 10000px;
		height: 100%;
		padding:0;
		-webkit-transition-property:-webkit-transform, left, top;
		-webkit-transition-duration:0s;
		-webkit-transform:translate3d(0px,0,0);
		-webkit-transition-timing-function:ease;

		-moz-transition-property:-moz-transform, left, top;
		-moz-transition-duration:0s;
		-moz-transform:translate3d(0px,0,0);
		-moz-transition-timing-function:ease;

		-o-transition-property:-o-transform, left, top;
		-o-transition-duration:0s;
		-o-transform:translate3d(0px,0,0);
		-o-transition-timing-function:ease;
		-o-transform:translate(0px,0px);

		-ms-transition-property:-ms-transform, left, top;
		-ms-transition-duration:0s;
		-ms-transform:translate3d(0px,0,0);
		-ms-transition-timing-function:ease;

		transition-property:transform, left, top;
		transition-duration:0s;
		transform:translate3d(0px,0,0);
		transition-timing-function:ease;
	}
	.swiper-free-mode > .swiper-wrapper {
		-webkit-transition-timing-function: ease-out;
		-moz-transition-timing-function: ease-out;
		-ms-transition-timing-function: ease-out;
		-o-transition-timing-function: ease-out;
		transition-timing-function: ease-out;
		margin: 0 auto;
	}
	.swiper-slide {
		float: left;
		height:100%;
	}

	/* IE10 Windows Phone 8 Fixes */
	.swiper-wp8-horizontal {
		-ms-touch-action: pan-y;
	}
	.swiper-wp8-vertical {
		-ms-touch-action: pan-x;
	}

	/* ===============================================================
	Your custom styles, here you need to specify container's and slide's
	sizes, pagination, etc.
	================================================================*/
	.swiper-container {
		/* Specify Swiper's Size: */

		/*width:200px;
		height: 100px;*/
	}
	.swiper-slide {
		/* Specify Slides's Size: */

		/*width: 100%;
		height: 100%;*/
	}
	.swiper-slide-active {
		/* Specific active slide styling: */

	}
	.swiper-slide-visible {
		/* Specific visible slide styling: */

	}
	/* ===============================================================
	Pagination Styles
	================================================================*/
	.swiper-pagination-switch {
		/* Stylize pagination button: */

	}
	.swiper-active-switch {
		/* Specific active button style: */

	}
	.swiper-visible-switch {
		/* Specific visible button style: */

	}
}
