.cp-cta {
	border-radius: 100px;
	background-color: color(yellow);
	display: block;
	height:100px;
	width:100px;
	position: relative;

	>span {
		display: block;
		@include absolutelyCentred();
		.text {
			display: block;
			text-align: center;
			line-height: 20px;
		}
		.plus {
			margin: 10px auto 0;
			position: relative;
			display: block;
			width: 11px;
			height:11px;
			&:before,&:after {
				position: absolute;
				content:" ";
				display: block;
				background-color: color(black);
			}
			&:before {
				width:11px;
				height:1px;
				top:5px;
				left:0;
			}
			&:after {
				width:1px;
				height:11px;
				top:0;
				left:5px;
			}
		}
	}
}