.p-about main{

	.container {
		position: relative;
		top: - vSize(14);

	}
	.wysiwyg a:not(.g-button){
		text-decoration: underline;
	}
	.wysiwyg a.g-button{
		margin-top:vSize(6);

	}

	.history {
		padding-left: 0;
		padding-right: 0;
		background-color: color(white);
		position:relative;
		.gc-6 {
			padding-left: 0;
			padding-right: 0;
			position: relative;
			padding-bottom: 50%;
		}
		.images {
			overflow: hidden;
			position: absolute;
			width: 100%;
			padding-bottom: 100%;
			background-color: color(white,three);
			div {
				position: absolute;
				top:0;
				left:0;
				display:block;
				opacity: 0;
				height: 100%;
				width: 100%;
				&:first-of-type {
					opacity: 1;
				}
				img {
					width: 100%;
					height: auto;
					display:block;
				}
			}
		}

		> a.g-button{
			position:absolute;
			display:none;
			top:50%;
			width:45px;
			margin:0;
			padding:0;
			margin-top:-22px;
			.circle{
				margin:0;
				display:block;
			}
			&.left{
				@include transform(rotate(180deg));
				left:vSize(2);
			}
			&.right{
				//@include transform(translateY(-50%));
				right:vSize(2);
			}
		}


		.slides {
			position: absolute;
			top:0;
			left: 0;
			width: 100%;
			height:100%;
			border-right: 1px solid color(white,two);
			.slide {
				position: absolute;
				background-color: color(white);
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				&:first-of-type {
					opacity: 1;
				}
				div.year {
					@include absolutelyCentred();
					font-size: 140px;
					color: color(white,two);
				}
				.bottom {
					padding: 10px 34px;
					position: absolute;
					bottom: 45px;
					a{
						text-decoration: underline;
					}
				}
			}

		}

	}


}

