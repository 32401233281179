@mixin absolutelyCentred($vertical:true,$horizontal:true,$important : false){
	position:absolute;


	@if $important == true {
		@if $vertical == true and $horizontal == true{
			top:50%;
			left:50%;
			@include transform(translate(-50%,-50%) !important);
		}
		@else if $vertical == true{
			top:50%;
			@include transform(translateY(-50%) !important);
		}
		@else if $horizontal == true{
			left:50%;
			@include transform(translateX(-50%) !important);
		}
	}
	@else {
		@if $vertical == true and $horizontal == true{
			top:50%;
			left:50%;
			@include transform(translate(-50%,-50%));
		}
		@else if $vertical == true{
			top:50%;
			@include transform(translateY(-50%));
		}
		@else if $horizontal == true{
			left:50%;
			@include transform(translateX(-50%));
		}
	}


}