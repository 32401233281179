.cp-captions {
	height:45px;
	.items {

		float: left;
		padding-right: $gutterWidth;
		position: relative;
		height: 45px;
		.text {
			position: absolute;
			left:$gutterWidth;
			top:0;
			height: 45px;
			background-color: color(white);
			line-height: 45px;
			opacity: 0;
			&:first-of-type {
				opacity: 1;
			}
		}
	}
	.dots {
		width:auto;
		float: left;
		text-align: left;
		padding-left: $gutterWidth;
		a{
			margin: 18px 5px 10px;
			display: inline-block;
			width:10px;
			height:10px;
			background-color: color(white,six);
			border-radius: 10px;
			&.active,&:hover {
				background-color: color(yellow,one);
			}
			position: relative;
		}
	}

}