
// all project specific colours always starting from one!
$colors: (
	yellow: (
		one: #ffe000,
		two: #f1cb33,
		three: #ffd800,
		four: #ffda00,
		five: #ffd000
	),
	white: (
		one: #ffffff,
		two: #f1f1eb,
		three: #e4e4d9,
		four: #f9f8f3,
		five: #b2b2b2,
		six: #dddddd,
		seven: #DEDED6,
		//eight: #2b2b2b,

	),
	black: (
		one: #242424,
		two: #2b2b2b,
		three :#7b7b7b,
		four: #dadbd5
	),
	ordertracker:(
		grey: #eaeae8,
		black: #2b2b2b,
		white: #ffffff
	)
);

// this is just a map to auto generate the style guide template
// the actual project specific type definitions can be found in typo.scss
$text-styles-map: (
	headings : (
		h1:'t-h1',
		h2:'t-h2',
		h3:'t-h3',
		h4:'t-h4',
		h5:'t-h5',
		h6:'t-h6'
	),
	body : (
		default:'t-default',
		bold:'t-bold'
	)
);


// project specific breakpoints
$breakpoints:(
	4 :(query:'(min-width:1024px)',columns:12),
	3 :(query:'(max-width:1023px)',columns:9),
	2 :(query:'(max-width:767px)',columns:6),
	1 :(query:'(max-width: 479px)',columns:3)
);
//$breakpoints:(
//	1 :(query:'(max-width: 479px)',columns:3),
//	2 :(query:'(min-width:480px) and (max-width:767px)',columns:6),
//	3 :(query:'(min-width:767px) and (max-width:1023px)',columns:9),
//	4 :(query:'(min-width:1024px)',columns:12)
//);

$gutterWidth: 16px;
$columnWidth: 67px;
$numberColumns: 12;
$verticalBaseLine:8px;

$z1 : 1;
$z2 :10;
$z3 :20;
$z4 :30;


// section padding
$gTopBottomPadding : 115px;


@function color($color, $tone: 'one') {
	@return map-get(map-get($colors, $color), $tone);
}


@function vSize($amount, $base: $verticalBaseLine){
	@return $amount * $base;
}


