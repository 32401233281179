.p-category main{

	div.image{
		height:100%;
		width:60%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right bottom;
	}

	.cp-module {
		padding-bottom: 120%;
		background-color: color(white,three);
		img {
			width:100%;
			height:auto;
		}
	}

	.breaker {
		clear: both;
		display: block;
		margin-bottom: 0;
		width: 100%;

	}



}
