.survey-overlay{
	position:fixed;
	width:100%;
	height:100vh;
	top:0;
	left:0;
	z-index:10000;
	opacity:0;
	visibility: hidden;
	&:before{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		content:'';
		background-color: #0e0e0e;
		opacity:0.6;
	}
	.close {
		position: absolute;
		right: 38px;
		top: 24px;
		span {
			@include img-svgs-menu-dropdown-close;
		}
		cursor: pointer;
	}
}

.survey-overlay-content{
	position:relative;
	width:100%;
	background-color: #eeede4;
	background-repeat: no-repeat;
	background-position:right top;
	background-size:contain;

	.button-wrapper{
		padding-top: vSize(2);
	}

	@include transform(translateY(-100%));
}

@include breakpoint(2){
	.survey-overlay-content{
		background-image: none !important;
	}
}