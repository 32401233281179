footer {
	.wrapp {
		> .gl-container {
			display: block;
			div.gc-4 {
				padding-top: vSize(4);
				&:first-of-type{
					padding-top: 0;
				}
				display: block;
				article {
					min-height: inherit;
					a {
						margin-top: vSize(2);
						position: relative;
						bottom: auto;
					}
				}

			}
		}
		.t-right {
			text-align: left;
		}
	}
}
