.cp-label {
	background-color: color(yellow);
	display: inline-block;
	padding: 5px 7px 3px 5px;
	line-height: vSize(2);
	vertical-align: middle;
	margin-bottom: vSize(2);

	overflow: hidden;
	a {
		&:before{
			content: " - ";
			display: inline-block;
			margin-right: 5px;
			margin-left: 1px;
		}
		&:first-of-type {
			&:before {
				display: none;
			}
		}
		&:last-of-type {
			&:after {
				content: " - ";
				display: inline-block;
				margin-right: 1px;
				margin-left: 5px;
			}
		}
	}

	&.v2{
		padding: 0;
		span {
			display: inline-block;
			padding: 5px 7px 3px 5px;
			@extend strong;
		}
	}
}