.p-category main{
	.cp-top-section{
		background-color: color(white,four);
	}
	.cp-top-section .full{
		margin-bottom: vSize(4);
	}

	.cp-module{
		padding-bottom:calc(100% + 56px);
	}



}
.cp-category-summary .bar{
	bottom:45px;
}