.cp-module {
	background-color: color(white,two);
	position: relative;
	overflow: hidden;
	margin-bottom: $gutterWidth;
	cursor: pointer;
	background-color: color(white,three);

	&.transparent {
		background-color: transparent;
	}

	&.large {
		background-color: #fff;
		.image-wrapp {
			position: relative;
			padding-bottom: 71%;

			&:before {
				content: ' ';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: color(black);
				z-index: $z2;
				opacity: 0.2;
			}
			img {
				//width: 100%;
				//height: auto;
			}
			.bar {
				bottom:0;
			}
		}
		.card {
			position: relative;
			padding: 0 21px 72%;
			div{
				position: absolute;
				top: 0;
				bottom: 0;
				margin-bottom: 20px;
				overflow: hidden;
				left: 21px;
				right: 21px;
			}
			h3 {
				margin-top: vSize(2);
			}
			em {
				display: block;
				margin-bottom:vSize(3);
			}
		}
	}

	img {
		position: absolute;
		z-index: $z1;
		display: block;
		width: 100%;
		height: auto;


	}
	.overlay {
		//@include img-svgs-circle-map-pin;
		position: absolute;
		z-index: $z2;
		top:0;
		left:0;
		width: 100%;
		height:100%;
		background-color: rgba(43,43,43,0.8);
		@include fbTransition(opacity,0.5s);
		opacity: 0;
		div {
			text-align: center;
			position: absolute;
			top:calc(50% - 32px - 13px);//height - 56px bar -8px line
			left:0;
			width:100%;
		}
		span{
			display: inline-block;
			border-bottom: 1px solid color(yellow);
			@extend .t-links;
			color: color(white);
		}
	}
	.bar {
		position: absolute;
		z-index: $z2;
		height:8px;
		background-color: color(yellow);
		width:100%;
		left:100%;
		bottom:56px;
		@include fbTransition(transform,0.5s);
		@include transform(translateX(-30%));
	}
	a {
		position: absolute;
		bottom: 0;
		left: 0;
		width:100%;
		z-index: $z2;
		@extend .t-h4;
		display: block;
		height:56px;
		line-height: 56px!important;

		background-color: color(white);
		span:first-of-type{
			display: inline-block;
			width: calc(100% - 70px);
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding-left: 21px;
		}
		span:last-of-type{
			display: inline-block;
			float: right;
			&.plus {
				width: 10px;
				height:10px;
				position: absolute;
				top:22px;
				right:28px;
				@include fbTransition(transform,0.2s);
				&:before {
					position: absolute;
					content:" ";
					width:11px;
					height:1px;
					display: block;
					top:5px;
					left:0;
					background-color: color(black);
				}
				&:after {
					position: absolute;
					content:" ";
					width:1px;
					height:11px;
					display: block;
					top:0;
					left:5px;
					background-color: color(black);
				}
			}
		}
	}
	@at-root .no-touch &{
		&:hover{
			.bar {
				@include transform(translateX(-99%));
				width: 110%;
			}
			.overlay {
				opacity: 1;
			}
		}
	}
}

.gc-6 .cp-module {
	padding-bottom: calc(100% + 112px);
}
.gc-3 .cp-module {
	padding-bottom: calc(100% + 56px);
}

.gc-4 .cp-module {
	//padding-bottom: 100%;
}