* {
  outline: none;
  @include backface-visibility(hidden);
  box-sizing: border-box;
}

a {
  color: inherit; // keep anchors from using ugly default
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input, textarea {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[data-clickthrough] {
  cursor: pointer;
}