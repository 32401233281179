@function fbRatio($width, $height){
	@return ($height / $width) * 100%;
}

@function stripUnits($number) {
	@return $number / ($number * 0 + 1);
}

@function fbRatioWithGutter($width, $height, $gutter:$gutterWidth){
	@return ($height / ($width + stripUnits($gutter))) * 100%;
}