%svg-common {
	display: inline-block;
	background: url(/app/svg/sprite.css-72317177.svg) no-repeat;
}


@mixin img-svgs-HPM-logo {
	@extend %svg-common;
	background-position: 0 -46.8px;width: 137px;height: 61px;
}

@mixin img-svgs-HPM-logo-2 {
	@extend %svg-common;
	background-position: -164.2px 0;width: 132.9px;height: 84px;
}

@mixin img-svgs-arrow-right {
	@extend %svg-common;
	background-position: -427.1px -51px;width: 26px;height: 14px;
}

@mixin img-svgs-calc {
	@extend %svg-common;
	background-position: -297.1px -116px;width: 58px;height: 55px;
}

@mixin img-svgs-circle-darkgrey-arrow-right {
	@extend %svg-common;
	background-position: -348px -223.8px;width: 54px;height: 54px;
}

@mixin img-svgs-circle-lightgrey-arrow-right {
	@extend %svg-common;
	background-position: -54px -339.8px;width: 54px;height: 54px;
}

@mixin img-svgs-circle-lightgrey-close {
	@extend %svg-common;
	background-position: 0 -339.8px;width: 54px;height: 54px;
}

@mixin img-svgs-circle-map-pin {
	@extend %svg-common;
	background-position: -108px -339.8px;width: 53px;height: 53px;
}

@mixin img-svgs-circle-white-arrow-down {
	@extend %svg-common;
	background-position: -232px -161.8px;width: 54px;height: 54px;
}

@mixin img-svgs-circle-white-arrow-right {
	@extend %svg-common;
	background-position: -232px -107.8px;width: 54px;height: 54px;
}

@mixin img-svgs-circle-yellow-arrow-down {
	@extend %svg-common;
	background-position: -348px -277.8px;width: 54px;height: 54px;
}

@mixin img-svgs-circle-yellow-arrow-right {
	@extend %svg-common;
	background-position: -355.1px -116px;width: 54px;height: 54px;
}

@mixin img-svgs-circle-yellow-full-close {
	@extend %svg-common;
	background-position: -349.1px -171px;width: 52px;height: 52px;
}

@mixin img-svgs-circle-yellow-full-plus {
	@extend %svg-common;
	background-position: -297.1px -171px;width: 52px;height: 52px;
}

@mixin img-svgs-download-arrow {
	@extend %svg-common;
	background-position: -413.1px -51px;width: 14px;height: 26px;
}

@mixin img-svgs-footer-ifo {
	@extend %svg-common;
	background-position: -413.1px 0;width: 51px;height: 51px;
}

@mixin img-svgs-footer-livechat {
	@extend %svg-common;
	background-position: -212.21px -339.8px;width: 51px;height: 48.62px;
}

@mixin img-svgs-footer-search {
	@extend %svg-common;
	background-position: -161px -339.8px;width: 51.21px;height: 51.21px;
}

@mixin img-svgs-helpcentre-calculator {
	@extend %svg-common;
	background-position: -297.1px 0;width: 116px;height: 116px;
}

@mixin img-svgs-helpcentre-contactus {
	@extend %svg-common;
	background-position: -116px -107.8px;width: 116px;height: 116px;
}

@mixin img-svgs-helpcentre-faq {
	@extend %svg-common;
	background-position: 0 -107.8px;width: 116px;height: 116px;
}

@mixin img-svgs-helpcentre-findstockist {
	@extend %svg-common;
	background-position: -232px -223.8px;width: 116px;height: 116px;
}

@mixin img-svgs-helpcentre-productmanuals {
	@extend %svg-common;
	background-position: -116px -223.8px;width: 116px;height: 116px;
}

@mixin img-svgs-helpcentre-tradecatalogue {
	@extend %svg-common;
	background-position: 0 -223.8px;width: 116px;height: 116px;
}

@mixin img-svgs-legrand-logo-01 {
	@extend %svg-common;
	background-position: 0 -23.1px;width: 159px;height: 23.7px;
}

@mixin img-svgs-legrand-logo-02 {
	@extend %svg-common;
	background-position: 0 0;width: 164.2px;height: 23.1px;
}

@mixin img-svgs-menu-dropdown-close {
	@extend %svg-common;
	background-position: -433.1px -77px;width: 17.41px;height: 17.41px;
}

@mixin img-svgs-menu-search {
	@extend %svg-common;
	background-position: -413.1px -77px;width: 20px;height: 19px;
}

@mixin img-svgs-plus {
	@extend %svg-common;
	background-position: -413.1px -96px;width: 15.31px;height: 15.31px;
}

@mixin img-svgs-tickbox-tick {
	@extend %svg-common;
	background-position: -427.1px -65px;width: 13.08px;height: 11.32px;
}

