[class*=" cp-line-"] {
	position: relative;
	&:before {
		position: absolute;
		top:12px;
		bottom:12px;
		display: block;
		width:8px;
		left:-30px;
		content: " ";
	}
	&.cp-line-inset {
		margin-left: 30px;
	}
	&.cp-line-zero {
		&:before {
			top:0;
			bottom:0;
		}
	}
	&.cp-line-white {
		&:before {
			background-color: color(white);
		}
	}
	&.cp-line-yellow {
		&:before {
			background-color: color(yellow);
		}
	}
}