.p-help main,.p-search main  {


	>.gl-container {
		//padding-left:$gutterWidth;
		//padding-right:$gutterWidth;
	}
	.select{
		.center-col {
			overflow: visible;
		}
		.cp-filter-list .active {
			//background-color: color(white);
		}
	}
	.cp-text-top {
		.cp-filter-list .active {
			background-color: color(white);
		}

	}
	.center-col {
		position: relative;
		overflow: hidden;
		.collapsible{
			position: absolute;
			width:100%;
		}
	}
	.collapsible {
		opacity: 0;
		visibility: hidden;
	}
	.filesize {
		margin-top: vSize(2);
		text-transform: lowercase;
	}
	.downloads {
		padding-left: $gutterWidth;
		padding-right: $gutterWidth;
		.gc-2 .g-button {
			padding-top: 20px;
		}
	}
}