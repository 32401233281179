.p-home-page main{
	overflow: hidden;
	.home{
		z-index:1;
	}
	.gl-container {
		position: relative;
	}
	.isotope {
		position: relative; //
		padding: 0!important; //
		.tile {
			padding: 0 $gutterWidth/2;
		}
	}
	.awesome-bg-image {
		position: absolute;
		top:-8px;
		left:-47px;
		img{
			max-width:initial;
		}
	}
	.b-feature {
		//padding-top: vSize(23);
		//padding-bottom: vSize(19);
		position:relative;
		a.g-button {
			margin-top: vSize(2);
		}
		clear: both;
	}
	.tiles {
		position: relative;
		width:100%;
	}
	.manual {
		position: relative;
		padding-bottom:100%;
		a.cp-cta{
			position: absolute;
			left: calc(25% - #{$gutterWidth * 2});
			top:25%;
			width:124px;
			height:124px;
			@include nord-bold();
			>span{
				min-height:62px;
			}

		}
		img {
			width: 100%;
			height: auto;
			max-width:650px;
			position:absolute;

		}
		//padding-bottom: vSize(7);
		margin-bottom:vSize(2);
	}
	.bfeature.cp-swiper-gallery {

	}


	.tiles{
		.gc-8 .cp-module {
			padding-bottom: 100%;
		}
		.gc-4 .cp-module {
			padding-bottom: 100%;
		}
	}

}

