@import "vars";
form{

	&.darker {
		input[type="text"],input[type="number"],textarea,select {
			background-color: color(white,two);
			color:color(black);
		}
	}

	.field {
		position: relative;
		width: 100%;
		margin-bottom: $gutterWidth;
		&:last-of-type {
			//margin-bottom: 0;
		}
		overflow: hidden;
		&:before {
			position: absolute;
			width:16px;
			height:4px;
			top:19px;
			left:-18px;
			@include fbTransition(transform,0.3s,cubic-bezier(0.5, -0.5, 0.5, 1.5),0.2s);
			background-color: color(yellow);
			content: ' ';

		}
		background-color: color(white)!important;
	}
	.check,.nobg {
		background-color: transparent!important;
	}

	input[type="text"],input[type="number"],input[type="email"],textarea,select {
		@include fbTransition(transform,0.3s,cubic-bezier(0.5, -0.5, 0.5, 1.5),0.2s);
		width: 100%;
		border: 0;
		border-radius: 0;
		background-color: color(white);
		-webkit-appearance: none;
		-webkit-border-radius: 0;
		display: block;
		@include placeholder {
			color:color(black,two);
			line-height: 40px;
		}

	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	[id^="parsley-id-"] {
		display: none;
	}

	.error {
		input[type="text"],input[type="number"],input[type="email"],textarea,select {
			@include transform(translateX(30px));
		}
		&:before {
			@include transform(translateX(35px));
		}

	}

	input + label,select + label,textarea + label {
		display: none;
	}
	input,select {
		margin-top: 2px;
		line-height: 40px;
		padding-left: 15px;
		padding-right: 15px;
		max-height: 40px;
		display: block;
	}
	select {
		height:40px;
	}
	textarea {
		padding: 10px 15px;
		display: block;
		min-height: 110px;
	}

	button.alt {
		float: right;
		display: inline-block;
		width: auto;
	}

	.check {
		position: relative;
		label {
			padding-left: 37px;
			display: block;
			position: relative;
			&:before{
				content:" ";
				display: block;
				background-color: color(white,three);
				position: absolute;
				width: 24px;
				height: 24px;
				left: 0;
				top:0;
			}
			&:after {
				position: absolute;
				width: 10px;
				height: 4px;
				background: transparent;
				border: 1px solid color(black);
				border-top: none;
				border-right: none;
				-webkit-transform: translate(-50%, -50%) rotate(-45deg);
				-moz-transform: translate(-50%, -50%) rotate(-45deg);
				-ms-transform: translate(-50%, -50%) rotate(-45deg);
				-o-transform: translate(-50%, -50%) rotate(-45deg);
				transform: translate(-50%, -50%) rotate(-45deg);
				top: 47%;
				left: 12px;
				content:" ";
				display: none;
			}
		}
		input{
			position: absolute;
			visibility: hidden;
		}

		input:checked + label {
			&:after {
				display: inline-block;
			}
		}


	}


}




