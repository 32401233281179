.cp-search-drawer {
	//@include transform(translateY(-100%));
	position: absolute;
	bottom:0;
	width:100%;
	left:0;
	//overflow-y: scroll;
	background-color:#eaeae3;


	.wrapp {
		min-height: 190px;
		background-color: color(black,two);
		padding-top: 32px;
		padding-left: 8px;
	}

	strong {
		color: color(yellow);
		display: block;
		padding-bottom: vSize(2);
	}

	.field {
		padding-right: 8px;
		background-color: color(black)!important;
	}
	input {
		background-color: color(black)!important;
		line-height: 64px;
		min-height: 64px;
		color:#939393;
		@extend .t-h2;
		font-size: 18px;
		margin-right: 5px;
		//-webkit-appearance: textfield !important;
		text-overflow: ellipsis;
		@include placeholder{
			color: color(black, three) !important;
			line-height: 64px !important;
			min-height: 64px !important;
			text-overflow: ellipsis;
			font-size: 18px;

		}
		@include selection(true){
			background-color: color(black, two);
		}

		&.filled{
			//color:color(white,one);
			//font-size: 18px;
		}

	}


	.desktop-recent-searches {
		strong {
			//padding-bottom: vSize(1);
		}
		div{
			margin-top:vSize(-1);
			@extend .t-links;
			color: color(white,five);
			a{
				line-height:vSize(4);
			}
		}
		max-width: 397px;
	}
	.close {
		padding: 32px 38px;
		position: absolute;
		right:0px;
		top:0px;
		span {
			@include img-svgs-menu-dropdown-close;
		}
	}
}

