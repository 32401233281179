.p-help main,.p-search main {


	section {
		position: relative;
	}
	.third {
		position: absolute;
		top:16px;
		right:8px;
		text-align: right;
		a.g-button {
			padding-top: 0;
		}
	}

	.center-col {
		padding-left: $gutterWidth;
		padding-right: $gutterWidth;

		h1 {
			padding-right: 70px;
		}

	}

}

