.cp-category-summary{
	$lw:  584;
	$rw:  399;
	$h: 464;
	$w: $lw + $rw;
	padding-bottom: #{100% * $h /$w};
	position:relative;
	margin-bottom: vSize(7);
	background-color: color(white, four);

	div.left,div.right{
		position: absolute;
		height:100%;
		top:0;
	}
	div.left{

		padding: vSize(7) vSize(12)  vSize(6) vSize(11);

		position:absolute;
		height:100%;
		display:flex;
		flex-direction:column;
		justify-content:space-between;

		/*.g-button{
			position:absolute;
			bottom:vSize(6);

		}*/
		.g-button{
			margin-top: auto;
		}
	}
	div.right{
		right:0;
		padding-left:vSize(1);
		>div{
			background-color: color(white, seven);
			height:100%;
		}
	}
	.cp-label{
		margin-bottom:vSize(1);
	}
	.t-h2{
		margin-bottom:vSize(2);
	}

	.bar {
		position: absolute;
		z-index: $z2;
		height:8px;
		background-color: color(yellow);
		width:100%;
		left:100%;
		bottom:72px;
		@include fbTransition(transform,0.5s);
		@include transform(translateX(-30%));
	}
	div.item{
		text-align: center;
		img{
			height:auto;
		}
	}

}