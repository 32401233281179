@import "breakpoint";

@mixin fbColors($colors:()){

	@each $name,$subcolors in $colors {
		@each $colorname,$color in $subcolors {
			.g-bg-#{$name}-#{$colorname} {
				background-color: $color;
			}
			.g-c-#{$name}-#{$colorname} {
				color: $color;
			}
		}
	}
}
