@import "../vars";

// mixin to apply a breakpoint to any given content
// usage:
//  @include breakpoint(2){
//      .bp2-gl-2col > .gc1{
//          width:50%;
//      }
//  }

@mixin breakpoint($breakpoint, $_breakpoints: $breakpoints){
	$media: map-get(map-get($_breakpoints, $breakpoint),'query');
	@media #{$media}{
		@content;
	}
}