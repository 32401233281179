header {
	.bar nav {
		display:none;
	}
	.bar .mobile-menu {
		display: block;
	}
	$height : 95px;
	.menu {
		.close {
			top: $height +24px;
		}
		nav {
			.tree-container{
				overflow:hidden;
				height:40px;
			}
			sup{
				color: color(yellow);
			}



			ul.tree {
				margin-bottom: vSize(2);
				>li a {
					line-height: 40px;
					font-size: 16px;

					span{
						position: relative;
						display: inline-block!important;
						margin-left: 3px;
					}
				}
				position: relative;
				margin-left: 40px ;
				&:before {
					border-left:6px solid color(yellow,two);
					content: " ";
					display: block;
					position: absolute;
					left:-40px;
					top:6px;
					bottom:7px;
				}
				ul {
					display: none !important;
					width:100%;
					margin:0;
					&:last-of-type{
						margin-bottom: 0;
					}
					li a{
						font-size: 14px;
					}
					li:first-of-type {
						//margin-top: vSize(2);
					}
					&:last-of-type {
						margin-bottom: vSize(2);
					}
				}
				ul:first-of-type {
					margin-left: 0;
					&:before {
						display: none;
					}
				}
			}
		}
	}
	#menu-drawer nav ul.tree ul[data-depth="1"] > li[data-depth="1"] {
		width: 50%;
	}
	#menu-drawer nav ul.tree ul[data-depth="1"] {
		&:before {
			display: none!important;
		}
		margin-left: 0!important;
	}

	#menu-drawer ul.main-links > li > a{
		font-size:20px;
		line-height:40px;

		sup{
			font-size:12px;
		}
	}

	.search,.mobile-menu {
		width:$height;
		&.mobile-menu {
			right: 0;//$height;
		}
	}
	.search{
		right: $height;
	}

	#menu-drawer nav ul.tree li[data-depth="0"]{
		height:40px;
	}

}

.cp-search-drawer{
	.gl-container{
		> *{
			float:none;

		}
	}
	.mobile-recent-searches{
		padding-top:vSize(0);

		> a{
			display:block;
			text-align: right;
			color: color(white, five);
		}
		.searches{
			color: color(white, five);
			display:none;
			text-align: right;
			a{
				display: inline-block;
				margin-right: vSize(2);
			}
			padding-top:vSize(2);
		}
	}
	.wrapp{
		padding-top:vSize(2.5);
		padding-bottom:vSize(2);
		min-height:0px;
	}
	.close{
		padding: vSize(2);
	}
	strong{
		padding-bottom:vSize(2);
	}
	input{
		line-height:vSize(5);
		min-height: vSize(5);

		@include placeholder{
			line-height: vSize(5) !important;
			min-height: vSize(5) !important;


		}
	}
}
