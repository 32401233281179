header  {
	$height:64px;
	height:$height;
	.bar {
		height:$height;
	}
	.bar nav {
		a {
			overflow: hidden;
			position: relative;
			//@extend .t-h4;
			line-height: $height;
		}
	}
	.logo {
		@include transform(scale(0.65));
		left:16px;
	}
	.bar {
		a {
			line-height: $height;
			height: 100%;
			display: block;
		}
	}
	.search,.mobile-menu {
		width:$height;
		&.mobile-menu {
			right: 0;//$height;
		}
	}
	.search{
		right: $height;
	}

	.menu {
		top:64px;
		nav {
			ul.products {
				margin-bottom: vSize(2);
				> li a {
					line-height: 24px;
					font-size: 15px;
				}
			}
		}
	}

	#menu-drawer .wrapp {
		padding-top: vSize(4);
	}


	#menu-drawer {
		height: calc(100vh - #{$height});
		top: $height;
	}
	.dest {
		height: $height;
	}

}

.cp-search-drawer{
	input{
		@include placeholder{
			color: #242424 !important;
		}
	}
}


