

.cp-text-top {
	a.g-button {
		display: inline-block;
		width: auto;
		&:last-of-type {
			margin-left: 100px;
		}
		&:first-of-type {
			margin-left: 0;
		}
	}
	h1 small{
		display:block;
		margin-top:vSize(2);

		@extend .t-default;
	}
}

