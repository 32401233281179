@mixin franklin(){
	@include typeKitfont(700, "normal", "franklin-gothic-urw-cond" , "sans-serif")
}
@mixin nord-regular(){
	font-family: TVNordEF-Regular, Arial, sans-serif;
	font-style:  normal;
	font-weight: normal;
}
@mixin nord-bold(){
	font-family: TVNordEF-Bold, Arial, sans-serif;
	font-style:  normal;
	font-weight: normal;
}