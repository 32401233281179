/**
handles display of reversed and obfuscated mailto links
**/
.otliam{
	direction: rtl;
	unicode-bidi: bidi-override;
	i,em,b{
		font-style: normal;
	}
	b:before{
		content: '.';
	}
	em:before{
		content: '@';
	}


}