.p-home-page main {
	.categories{
		padding-top:vSize(3.5);
		.t-h2{
			padding-bottom:vSize(2.5);
		}
	}
	.manual{
		padding-top: vSize(9);
		padding-bottom:0;
	}
	.manual img{
		position:relative;
		top:0;
		left:vSize(0);
		max-width:120%;
		margin-top:-35%;
		//@include transform(translateY(-25%));
	}
}