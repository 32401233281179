.country-checker-wrapper{
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    @include transform(translateY(-100%));
    z-index:1000;
    background-color: #eaeae3;

}

.cp-country-checker{
    position:relative;
    height:vSize(7);
    background-color: color(black,two);
    color: color(white, one);

    p{
        @include absolutelyCentred(true, false);
        left:vSize(3);
        right:vSize(8);

        a{
            color: color(yellow, one);
            text-decoration: underline;
            margin-left:1em;
        }
    }
    .close {
        padding: 16px 38px;
        position: absolute;
        right:0px;
        top:0px;
        span {
            @include img-svgs-menu-dropdown-close;
        }
    }
}

body.country-check{
    header{
        top:56px;
        transition-delay:0.5s;

    }
    .scrollable{
        padding-top:56px;
        transition-delay:0.5s;

    }
}