.p-wheretobuy main{

	position: relative;

	.bg {
		width: 100%;
		position: absolute;
		left: 0;
		top:0;
		background-size: cover;
		height:100%;
		background-color: color(black);
		background-position: center;
	}

	.cp-text-top {
		h1 {
			margin-bottom: vSize(12);
		}
	}

	.gl-container {


		input,select,button {
			line-height: 64px;
			font-size: 18px;
			min-height:64px;
			@include placeholder {
				line-height: 64px;
			}
		}
		select {
			height:64px;
		}
		h1 {
			@include fbTransition(transform,0.5s);
		}
		form {
			display: block;
			position: relative;
			&:before {
				@include fbTransition("transform, opacity",0.5s);
				@include transform(translate(-33px,200%));
				opacity: 0;
				content: ' ';
				width: 8px;
				top:0;
				bottom:0;
				position: absolute;
				background-color: color(yellow);
				display: block;
			}
		}

		.line {
			h1 {
				@include transform(translateX(-33px));
			}
			form:before {
				@include transform(translate(-33px,0));
				opacity: 1;
			}
		}

		.buttons {
			a {
				display: inline-block;
				width: auto;
				margin-right: 100px;
				padding-bottom: 18px;
				&:last-of-type {
					margin-right: 0;
				}
			}
		}

	}

	.electrician {
		.filter-type,.cp-filter-list {
			display: none;
		}
	}
	.cp-block {
		&:after {
			display: block;
			content:"";
			clear: both;
		}
		margin-bottom: 18px;
		.cp-label {
			margin-bottom:0;
		}
		h1 {
			margin-bottom: vSize(1);
		}
		.g-button {
			padding-top:30px;
		}
	}
	.filter {
		.gc-3 {
			position: relative;
		}
		.t-h3{
			line-height:vSize(5);
		}
	}

}

.p-wheretobuy footer .wrapp{
	margin-top: 0;
}

