.p-product main{

	.cp-top-section{
		padding-top: 0;
		padding-bottom:vSize(5);
		@include display(flex);
		@include flex-direction(column);
		@include justify-content(space-between);

		.t-h1 p.product-code{
			@extend .t-default;
			color: color(white, five);
		}

		.bar {
			position: absolute;
			z-index: $z2;
			height:8px;
			background-color: color(yellow);
			width:100%;
			left:100%;
			bottom:72px;
			@include fbTransition(transform,0.5s);
			@include transform(translateX(-30%));
		}
		@at-root .no-touch &:hover{


		}

		a.g-button{
			//margin-top:16px;
		}

		.specs.no-body{
			margin-top: vSize(-4);
		}
	}
	.insitue {
		position: relative;
		.images {
			background:color(white,three);
			width:100%;
			overflow: hidden;
			max-height: 575px;
			height:575px;
			margin-bottom: -72px;
			.swiper-wrapper{
				width:10000px;
			}
			div {
				width:100%;
				height:100%;
				background-position: center;
				background-size: cover;
			}
		}
		img {
			display: block;
		}
		.cp-captions {
			position: relative;
		}
	}
	.stockists {
		.right {
			position: relative;
			img {
				position: absolute;
				right:0;
				top:0;
				width:400px;
			}
		}
	}

	.stockists img {
		width: 100%;
		height:auto;
	}

	section>.gl-container .gl-container {
		padding-top: vSize(2);
	}
	.specifications {
		position: absolute;
		top:0px;
		left:0px;
		width:100%;
		height:100%;
		z-index:2;
		visibility: hidden;
		opacity: 0;
		a.close{
			position: absolute;
			right: 0;
			top:0;
			width: 45px;
			height: 45px;
			margin: 0;
			z-index:1;

		}
		.gc-12{
			position: relative;
		}

		ul {
			position: relative;
			&:before {
				position: absolute;
				content: "";
				top: 5px;
				bottom: 13px;
				left:0;
				border-left:4px solid color(yellow);
			}
			padding-left: 18px ;
			li {
				position: relative;
				padding-left: 20px;
				padding-bottom: 8px;
				&:before {
					position: absolute;
					content: "-";
					display: block;
					left:0;
				}
			}
		}
	}

	.video-embed{
		position:relative;
		.img{
			width:100%;
			text-align:center;
			background-color:color(white, seven);

			position:relative;
			&:after{
				position: absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				display: block;
				content:'';
				background-color: color(black,one);
				opacity:0.3;
			}
		}

		iframe{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			display: block;
			visibility:hidden;
			opacity:0;
		}
		cursor:pointer;
		&:hover .video-embed-label{
			opacity:0.7;
		}
	}

	.video-embed-label{
		position:absolute;
		bottom:46px;
		left:54px;
		color: color(white,one);
		font-size:18px;
		line-height:54px;
		vertical-align: middle;
		cursor:pointer;

		@include fbTransition(opacity,0.25s);

		span{
			width:54px;
			height:54px;
			border: 2px solid color(white,one);
			border-radius: 26px;
			display:inline-block;
			vertical-align: middle;
			margin-right:26px;

			position: relative;

			&:before{
				content:'';
				@include absolutelyCentred(true,true);
				display:block;
				margin-left:2px;
				width:10px;
				height:0px;
				border-top: 8px solid transparent;
				border-bottom: 8px solid transparent;
				border-left: 12px solid color(white,one);

			}
		}
	}
}