.cp-category-summary{
	position:relative;
	margin-bottom: vSize(3);
	background-color: color(white, four);
	padding-bottom:initial;
	div.left,div.right{
		position: relative;
		width:100%;
		float:none;

	}
	div.left{

		padding: vSize(2);
		display:flex;
		flex-direction:column;
		justify-content:space-between;


	}
	div.right{
		padding-left:0;
		height:#{392px + 72px};
		max-height: calc(72px + 100vw - #{vSize(4)});

		>div{
		//	height:auto;
			position:absolute;
			height:100%;
			width:100%;


		}
		.cp-swiper-gallery div.swiper-wrapper,.cp-swiper-gallery .swiper-slide{
			//height:auto;

		}
		.cp-swiper-gallery .swiper-slide{
			max-width: calc(100vw - #{vSize(6)});
		}
		.cp-captions{
			//position: relative;
			//bottom:initial;
		}
	}



}