.p-wheretobuy main{
	.gl-container {
		//padding-left: 45px;
		.line {
			h1 {
				@include transform(translateX(-33px));
			}
			form:before {
				@include transform(translate(-33px,0));
				opacity: 1;
			}
		}

	}

	.cp-text-top .g-button{
		float:none;
		width:100%;
		margin-top:inherit;

		&:last-of-type{
			margin-top:16px;
		}
	}
}
