.cp-captions {
	background: color(white);
	position: absolute;
	bottom:0;
	height:72px;
	overflow: hidden;
	z-index: $z1;
	.items {
		width: 60%;
		float: left;
		padding-left: $gutterWidth;
		position: relative;
		.text {
			position: absolute;
			left:$gutterWidth;
			top:0;
			height: 72px;
			background-color: color(white);
			line-height: 72px;
			opacity: 0;
			&:first-of-type {
				opacity: 1;
			}
		}
	}
	.dots {
		width:40%;
		float: right;
		text-align: right;
		padding-right: $gutterWidth;
		a{
			margin: 32px 5px 30px;
			display: inline-block;
			width:10px;
			height:10px;
			background-color: color(white,six);
			border-radius: 10px;
			&.active,&:hover {
				background-color: color(yellow,one);
			}
			position: relative;
		}
	}
}