@charset 'UTF-8';

//@import 'base/math';
//@import 'base/brightness';

//@import "vendor/fontello";
//@import 'vendor/foundation/global';
//@import 'vendor/foundation/util/util';


////////////////
// Variables
////////////////

// ---- Grid ---- //
/*
$flex: true;
$global-width: rem-calc(1340);

$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  tiny: 20px,
  medium: 30px,
);
$column-gutter: 30px;

$breakpoints: (
  tiny: 0,
  small: 376px,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1600px,
  xxxlarge: 1920px,
);
$breakpoint-classes: (tiny small medium large xlarge xxlarge xxxlarge);

// ---- Fonts ---- //
$font-family-sans-serif: 'Open Sans', Arial, sans-serif;
$font-family-serif: Georgia, Cambria, 'Times New Roman', Times, serif;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$font-family-icon: 'Fontello';

$body-font-family: $font-family-sans-serif;

$global-weight-normal: normal;
$global-weight-bold: bold;

$body-bg: $white;
$body-font-color: #333;
$body-font-family: $font-family-sans-serif;
$body-font-weight: $global-weight-normal;
$body-font-style: normal;

// ---- Colours ---- //

// We use these as default colors throughout
$primary-color: #b1a47a;
$secondary-color: #3d3f48;
$tertiary-color: #6B9392;

$alert-color: #e74c3c;
$warning-color: #f1c40f;
$success-color: #7bc143;
$info-color: #e1e3e5;
$alternate-color: #b4d5f0;
$shade-color: #f0f1f2;

$global-color-palette: (
  primary:   $primary-color,
  secondary: $secondary-color,
  tertiary:  $tertiary-color,
  alert:     $alert-color,
  warning:   $warning-color,
  success:   $success-color,
  info:      $info-color,
  alternate: $alternate-color,
  light:     #FFFFFF,
  dark:      #000000
);

$foundation-palette: $global-color-palette;

$social-color-palette: (
  facebook:   #3b5998,
  twitter:    #00aced,
  gplus:      #dd4b39,
  youtube:    #bb0000,
  linkedin:   #007bb6,
  instagram:  #517fa4,
  pinterest:  #cb2027,
  tumblr:     #32506d,
  flikr:      #ff0084,
  vine:       #00bf8f
);

// ---- Header ---- //

$header-font-family: $font-family-sans-serif;
$header-font-weight: 600;
$header-lineheight: 1.2;
$header-sizes: (
  tiny: (
    'h1': 36,
    'h2': 28,
    'h3': 24,
    'h4': 20,
    'h5': 18,
    'h6': 16,
  ),
  small: (
    'h1': 45,
    'h2': 36,
    'h3': 26,
    'h4': 22,
    'h5': 18,
    'h6': 16,
  ),
  medium: (
    'h1': 60,
    'h2': 48,
    'h3': 34,
    'h4': 30,
    'h5': 26,
    'h6': 20,
  ),
);

// ---- Buttons ---- //

$button-font-family: $font-family-sans-serif;
$button-padding: 1.3em 2.5em;
$button-margin: 0;
$button-color-palette: map-merge($global-color-palette, $social-color-palette);
$button-sizes: (
  nano:    0.55rem,
  tiny:    0.7rem,
  small:   0.842rem,
  default: 1rem,
  large:   1.25rem,
);

// ---- Forms ---- //

$form-spacing: rem-calc(23.34);
$select-triangle-color: $secondary-color;

// ---- Breadcrumbs ---- //

$breadcrumbs-item-font-size: rem-calc(16);
$breadcrumbs-item-color: $primary-color;
$breadcrumbs-item-color-current: $primary-color;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-slash: true;

// ---- Table ---- //

$table-border: 0;
$table-padding: rem-calc(15);
$table-striped-background: $shade-color;

// ---- Tabs ---- //

$tab-background-active: $secondary-color;
$tab-item-padding: rem-calc(16) rem-calc(50);

// ---- Accordion ---- //

$accordion-background: transparent;

// ---- Menu ---- //

$menu-link-font-size: rem-calc(18);
$menu-link-color: $white;
$menu-link-sticky-color: $body-font-color;
$menu-link-padding: 0 ($column-gutter/2);
$menu-logo-width: 80px;
$menu-logo-margin: 0 0 0 0;
$menu-mega: true;
$menu-sub-menu-background: $primary-color;
$menu-sub-menu-link-color: $white;
$menu-sub-menu-link-font-size: rem-calc(18);

$mobile-menu-link-color: $white;
$mobile-menu-link-font-size: rem-calc(22);
$mobile-menu-button-color: $white;
$mobile-menu-child-icon: $icon-play-circled;
$mobile-menu-background: $primary-color;


////////////////
// Base
////////////////

@import "base/normalize";
@import "base/spacing";
@import "base/alignment";
@import "base/easing";
@import "base/animation";
@import "base/wordpress";
@import "base/contact-form-7";
@import "base/text-colors";
@import "base/svg-url";
@import "base/tde";

////////////////
// Foundation
////////////////

@import 'vendor/foundation/foundation';

@if $flex {
  $global-flexbox: true !global;
}

@include foundation-global-styles;

@if not $flex {
  @include foundation-grid;
} @else {
  @include foundation-flex-grid;
}

@include foundation-typography;
@include foundation-forms;
@include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
@include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
@include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
@include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

@if $flex {
  @include foundation-flex-classes;
}

// Workaround for inconsistent form sizing
#{text-inputs()},
select,
textarea,
.input-group {
  margin-bottom: rem-calc(20);

  &.input-group-field {
    margin: 0;
  }
}

////////////////
// Vendor
////////////////

@import "vendor/full-page";
@import "vendor/owl-carousel";
@import "vendor/slick";
@import "vendor/lightcase";
@import "vendor/animated/animated";

// Imported Animations
@include animation(fade-in-down);
@include animation(fade-in);
@include animation(fade-out-up);
@include animation(bounce-in);
@include animation(bounce-out);

////////////////
// Elements
////////////////

@import "elements/ul-ol";
@import "elements/headings";
@import "elements/tables";
@import "elements/blockquote";

////////////////
// Components
////////////////

@import "components/buttons";
@import "components/backdrop";
@import "components/forms/fieldset";
@import "components/forms/radio";
@import "components/forms/checkbox";
@import "components/forms/date";
@import "components/forms/ninja/form";
@import "components/card";
@import "components/tile";
@import "components/hero";
@import "components/hero-internal";
@import "components/hero-carousel";
@import "components/share-page";
@import "components/content-sidebar";
@import "components/product-cards";
@import "components/masonry-grid";
@import "components/solutions";
@import "components/footer";
@import "components/pagination";
@import "components/testimonials";
@import "components/overlay";
@import "components/loader";
@import "components/single-navigation";
@import "components/sidebar-block";
@import "components/sidebar-block-list";
@import "components/sidebar-block-search";
@import "components/filtered-listing";

////////////////
// Navigation
////////////////

@import "menus/standard";
@import "menus/minimal";
@import "menus/shared/menu-list";
@import "menus/shared/menu-list-icons";
@import "menus/shared/menu-list-search";
@import "menus/shared/mega-menu";
@import "menus/shared/fullscreen-menu";
@import "menus/helpers";
// @import "components/menus";
// @import "components/desktop-menu";
// @import "components/mobile-menu";

////////////////
// WooCommerce
////////////////

@import "woocommerce/woocommerce";

////////////////
// Regions
////////////////

@import "regions/breadcrumbs";
@import "regions/featured-posts";
@import "regions/page-content";
@import "regions/services-tiles";
@import "regions/slider-block";
@import "regions/gallery-grid";
@import "regions/intro-text";
@import "regions/team-listing";

@import "regions/content-blocks/base";
@import "regions/content-blocks/accordion";
@import "regions/content-blocks/text";
@import "regions/content-blocks/card";
@import "regions/content-blocks/listing";
@import "regions/content-blocks/full-backdrop";
@import "regions/content-blocks/showcase";
@import "regions/content-blocks/basic-call-to-action";
@import "regions/content-blocks/map";
@import "regions/content-blocks/supporters-carousel";
@import "regions/content-blocks/testimonials-carousel";
@import "regions/content-blocks/featured-articles";
@import "regions/content-blocks/smart-grid";


////////////////
// Layouts
////////////////

@import "layouts/template-home";
@import "layouts/template-archive";
@import "layouts/template-contact";
@import "layouts/template-gallery";

////////////////
// Hacks
////////////////

@import "base/hacks";
*/
@import "hpm/styles";
@import "hpm_hacks";