.cp-breadcrumb {
	position: relative;
	padding-top: vSize(6);
	padding-bottom: vSize(8);
	z-index: 1;
	a {
		&:before{
			content: " - ";
			display: inline-block;
			margin-right: 5px;
			margin-left: 1px;
		}
		&:first-of-type {
			&:before {
				display: none;
			}
		}
		&:last-of-type {
			@extend .t-h4;
		}
	}
	margin-left: 21px;
}