@import "breakpoint";


// note: after using this grid approach  I think that breakpoint column approach is not ideal. In our case we having a breakpoint where we
//       have 9 cols which is really not ideal -> its the same as 3. you literally can not create 50% without introducing extra
//       code to correct it.
//       I think the grid system should not worry about different grid sizes  and it should not change its maximum columns. It will maintain proportions just fine.
//       auto fallback , not sure if it super helpful I really don't mind adding the exact classes I need, annoying is to fix a wrong auto
//       fallback, its really not too much effort.
//
@mixin fbGrid($gutterWidth,$columnWidth,$numberColumns,$prefix:g,$breakpoints:()){
	.#{$prefix}l-container {
		padding: 0 $gutterWidth/2;
		margin: 0 auto; //negative top/bottom margin as columns are padding all sides below. this should be optional
		width: 100%;
		max-width: ($columnWidth + ($gutterWidth - 1) ) * $numberColumns + 2*$gutterWidth;

		&.full {
			max-width:100%;
		}
		&:after {
			display: block;
			content: ' ';
			clear: both;
		}
		.#{$prefix}l-container {
			margin-left:-$gutterWidth!important;
			margin-right:-$gutterWidth!important;
			width: auto;
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
		> * {
			padding: 0 $gutterWidth/2; //all sides - should be  horizontal only be default, optionally vertical too
			width: 100%; //sensible default
		}
		> .#{$prefix}c-full {
			width: 100% !important;
		}
		.#{$prefix}c-right {
			float: right;
		}
		.#{$prefix}c-left {
			float: left;
		}
	}
	@for $i from 1 through $numberColumns {
		.#{$prefix}c-#{$i} {
			width: 100% / ($numberColumns / $i);
			float: left;
		}
		.#{$prefix}cp-#{$i}{
			margin-left: 100% / ($numberColumns / $i);
		}
	}
	@each $name,$breakpoint in $breakpoints {
		@include breakpoint($name, $breakpoints){
			$columns : map-get($breakpoint, 'columns');
			@for $i from 1 through $numberColumns {
				.#{$prefix}c-#{$i} {
					width: 100% / ($columns / min($i,$columns));
				}
			}
			// 0 margin left
			.bp#{$name}-#{$prefix}cp-0{
				margin-left: 0;
			}
			// create a 50% case for some grids
			.bp#{$name}-#{$prefix}c-half {
				width: 50%;
			}
			@for $i from 1 through $columns {
				.bp#{$name}-#{$prefix}c-#{$i}-#{$columns} {
					width: 100% / ($columns / $i);
				}
				.bp#{$name}-#{$prefix}cp-#{$i}-#{$numberColumns}{
					margin-left: 100% / ($numberColumns / $i);
				}
			}
		}
	}
}

@function gridColWidth($cols,$maxNumberColumns: $numberColumns){
	@return 100% / ($maxNumberColumns / $cols);
}
