@import url("//hello.myfonts.net/count/2f00d8");
@import "helpers/typekitFont";
@import "helpers/fonts";
@font-face {
  font-family: "TVNordEF-Regular";
  src: url("/app/fonts/2F00D8_0_0.eot");
  src: url("/app/fonts/2F00D8_0_0.eot?#iefix") format("embedded-opentype"),
    url("/app/fonts/2F00D8_0_0.woff2") format("woff2"),
    url("/app/fonts/2F00D8_0_0.woff") format("woff"),
    url("/app/fonts/2F00D8_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "TVNordEF-Bold";
  src: url("/app/fonts/2F00D8_1_0.eot");
  src: url("/app/fonts/2F00D8_1_0.eot?#iefix") format("embedded-opentype"),
    url("/app/fonts/2F00D8_1_0.woff2") format("woff2"),
    url("/app/fonts/2F00D8_1_0.woff") format("woff"),
    url("/app/fonts/2F00D8_1_0.ttf") format("truetype");
}

.t-default {
  @include nord-regular;
  font-size: 15px;
  line-height: 24px;
  color: color(black, two);
  text-decoration: none;
}

.t-h1 {
  margin-bottom: vSize(5);
  font-size: 48px;
  line-height: 60px;
}
.t-h2 {
  @include nord-regular;
  font-size: 30px;
  line-height: 48px;
}
.t-h2-2 {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: vSize(4);
}
.t-h3 {
  font-size: 23px;
  line-height: 1em;
  margin-bottom: vSize(3);
}
.t-h4 {
  line-height: 24px;
  @include nord-bold;
}
.t-h5 {
  @include franklin;
  font-size: 14px;
}
.t-h6 {
}

.t-links {
  font-size: 21px;
}

.t-strong {
}

.t-em {
}

.t-underline {
}

.t-link {
}

.t-button {
}

body {
  @extend .t-default;
}

a {
  text-decoration: none;
  //@extend .t-default;
}

p {
  margin-bottom: vSize(2);
}

.t-right {
  text-align: right;
}
.t-left {
  text-align: left;
}
.t-center {
  text-align: center;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

strong {
  @include nord-bold();
}
