$otBreakpoints:(
        ot4 :(query:'(min-width:1024px)',columns:16),
        ot5 :(query:'(min-width:1400px)',columns:16),
        ot3 :(query:'(max-width:1023px)',columns:8),

    /*ot3 :(query:'(max-width:1023px)',columns:9),
    ot2 :(query:'(max-width:767px)',columns:6),
    ot1 :(query:'(max-width: 479px)',columns:3)**/
);
//$breakpoints:(
//	1 :(query:'(max-width: 479px)',columns:3),
//	2 :(query:'(min-width:480px) and (max-width:767px)',columns:6),
//	3 :(query:'(min-width:767px) and (max-width:1023px)',columns:9),
//	4 :(query:'(min-width:1024px)',columns:12)
//);

$otGutterWidth: 16px;
$otColumnWidth: 58px;
$otNumberColumns: 16;



.order-tracker-multi{
    header, footer{
        display:none;
    }
    .scrollable {
        margin-top: 0px !important;
        overflow:hidden;

    }
    main{
        max-width: initial;
        width:100%;
        height:100vh;
        background: color(ordertracker, grey);
        color: color(ordertracker, black);
    }

    @include fbGrid($otGutterWidth,$otColumnWidth,$otNumberColumns,g,$otBreakpoints);
    @include fbHelper($otBreakpoints);
    &-wrapper{
        height:100%;
    }
    &-screen-1{
        height:100%;
        nav{
            position:absolute;
            top:0;
            bottom:0;
            left:0;
            background: color(ordertracker,white);
            @include display(flex);
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(center);

            > div{
                @include flex-grow(1);
                width:100%;

                text-align: center;
                position:relative;
                &:not(:last-child){
                    border-bottom:1px solid color(ordertracker,grey);
                }
                img{
                    vertical-align: middle;
                }
                a{
                    display:block;
                    width:100%;
                    @include absolutelyCentred(true, false);
                }
            }
            width: 2 * ($otGutterWidth + $otColumnWidth);
           // opacity:1;
           // @include transition(opacity 0.5s ease-out);
        }
        .gl-container{
            max-width:initial;
            height:100%;

            > *{
                position:relative;
                height:100%;
                @include display(flex);
                @include flex-direction(column);
               @include justify-content(center);


            }
        }

        &-img{
            max-width:100%;
            height:auto;
        }
        @include transition(opacity 0.5s ease-out);
        &.loading{
            opacity:0;
            nav{
             //   opacity:0;
            }
        }
    }
    &-screen-2{
        position: absolute;
        top:0;
        left:100%;
        width:100%;
        height:100vh;
        background-color: color(ordertracker,grey);//transparentize(color(ordertracker,grey),0.15);
        @include transition-property(transform, background-color);
        @include transition-duration(1s, 0.5s);
        @include transition-timing-function(ease-out);
        overflow:hidden;
        nav{
            position:absolute;
            top:0px;
            left:0px;
            width:100vh;
            height:40px;
            background: color(ordertracker, black);
            color: color(ordertracker, white);
            line-height:40px;
            text-align:center;
            cursor: pointer;
            z-index:1;

            @include transform(translateY(100vh) rotate(-90deg));
            @include transform-origin(0% 0%);


        }

        .gl-container {
            max-width: initial;
        }

        &.loading{
            @include transform(translateX(-100%));
            &:after{
                content:'Loading...';
                display:block;
                @extend .t-h2;
                font-size:21px;
                @include absolutelyCentred(true, true);
            }
        }
        &.loaded{
            @include transform(translateX(-100%));
            background-color: color(ordertracker,grey);

        }

        .error-message{

        }
    }

    .order-tracker{
        padding-top: vSize(7);
        padding-bottom: vSize(7);
        max-height:100%;
        overflow-x:hidden;
        overflow-y:auto;
        -webkit-overflow-scrolling: touch;
    }

    .order-tracker-line-item{
        padding:vSize(4) 0px vSize(3);
        background:none;
        .cp-label{
            background:none;

        }

        strong.label{
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &-top h2{
            position: relative;
        }
        &-top h2 sup{
            position:absolute;
            top:1em;
            left:-10%;
            width:16px;
            height:16px;
            margin-left:-8px;
            margin-top: -8px;
        }


    }
}

@include breakpoint(ot3,$otBreakpoints){
    .order-tracker-multi{

        .order-tracker{
            padding-left: vSize(6) !important;
            padding-top:vSize(4) !important;
            padding-bottom:vSize(4) !important;

        }

         &-screen-1{
              nav{
                 bottom:initial;
                 right:0;
                 width:100%;
                 height:64px;
                 @include flex-direction(row);
                 > div {
                     @include flex-grow(1);
                     height: 100%;

                     &:not(:last-child){
                         border-bottom:0px;
                         border-right:1px solid color(ordertracker, grey);
                     }
                 }
                 img{
                     max-width:calc(100% - 16px);
                     height:auto;
                 }
             }
         }


        .order-tracker-line-item{
            background: color(ordertracker, white);
            padding: 24px;

            &-top h2 sup{
                position:relative;
                top:0.1em;
                left:0.25em;
                width:16px;
                height:16px;
                margin-left:8px;
                margin-top: 8px;
            }
        }
    }
}