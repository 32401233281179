.t-default {
	@include nord-regular;
	font-size: 13px;
	line-height: 20px;

}

.t-h1 {
	font-size: 32px;
	line-height: 40px;
	margin-bottom:20px;
}
.t-h2 {
	font-size: 19px;
	line-height: 32px;
}
.t-h3 {
	//@include nord-bold;
	font-size: 19px;
}
.t-h4 {
	font-size: 12px;
	line-height: 20px;
}
.t-links {
	font-size: 16px;
}
