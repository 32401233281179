.cp-module-side {
	background-color: color(white,one);
	position: relative;
	overflow: hidden;
	margin-bottom: $gutterWidth;

	img {
		//z-index: $z1;
		display: block;
		width: 100%;
		height: auto;
	}

	.img {
		position: absolute;
		width:31%;
		background-color: color(white,three);
	}



	.t {
		padding: $gutterWidth;
		position: absolute;
		top: 0;
		left: 31%;
		width: 75%;
		z-index: $z2;
		display: block;
		height:100%;
		strong {
			@extend .t-h4;
		}
		span {
			position:absolute;
			bottom: vSize(1);
			color: color(white, five);
		}
		p{
			margin-bottom: 0;
		}
	}
	padding-bottom: 31%;
}
