

.cp-text-top {
	a.g-button {
		display: block;
		&:last-of-type {
			margin-left: 0;
			margin-top: vSize(2);
		}
	}
}

